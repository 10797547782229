import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useAuth from "../hooks/useAuth"
import useLang from '../hooks/useLang'

import axios from 'axios';

// import `ChakraProvider` component
import {
    Grid,
    GridItem,
    Center,
    Flex,
    Box,
    Text,
    Input,
    Button,
    ButtonGroup,
    Stack,
    HStack,
    Divider,
    Highlight,
    useDisclosure
} from '@chakra-ui/react'

import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'

const DashSearch = () => {
    const [searchString, setSearchString] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState(false);
    const location = useLocation();
    const query = new URLSearchParams(location.search).get("query");

    const { username, status } = useAuth()

    const { lang } = useLang()

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

    const searchProduct = async (event) => {
        setSearchString(event.target.value)
        try {
            //            const { data } = await axios.get(`https://randomuser.me/api/search?q=${query}`); setSearchResults(data.products);
            const { data } = await axios.get(`https://randomuser.me/api`); setSearchResults(data.results);
            onOpen()
            this.searchField.focus();
        } catch (error) {
            setError(error.response?.data?.message);
        }
    };

    useEffect(() => {
        //        searchProduct();
    }, []);

    const searchFieldFocus = useRef()

    const content = (
        <>
            <Center
                bg='#333333'
                p='0'
                color='white'
                axis='both'
            >
                <Grid
                    colSpan={12}
                    w='95vw'
                    maxW='1500'
                    gap={5}
                    p='5'
                    templateColumns='repeat(12, 1fr)'
                >
                    <GridItem colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>

                        <Popover
                            initialFocusRef={searchFieldFocus}
                            returnFocusOnClose={false}
                            isOpen={isOpen}
                            onClose={onClose}
                            placement='bottom'
                            closeOnBlur={false}
                        >
                            <PopoverAnchor>
                                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['left']} borderColor='black' bg='white' w="100%">
                                    <HStack><Text color='black' fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={600}>Search</Text>
                                        <Input
                                            autoFocus
                                            autoComplete="off"
                                            ref={searchFieldFocus}
                                            name='searchField'
                                            id='searchField'
                                            //onChange={searchProduct}
                                            onChange={event => searchProduct(event)}
                                            placeholder='artiste name, event name, venue place, ...'
                                            size='lg'
                                        />
                                    </HStack>
                                </Box>
                            </PopoverAnchor>

                            <PopoverContent width="95vw" maxWidth="800px" color='black'>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverBody>
                                    <Text color='black' fontSize={{ base: "16", sm: "16", md: "16", lg: "16", xl: "20" }} fontWeight={700}>EVENTS</Text>
                                    <Divider />
                                    {searchResults.map((searchResult) => (
                                        <Flex color='black' key={searchResult.id} cursor={'pointer'} _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}>
                                            <Box w='30vw' maxWidth="250px">
                                                <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={500}>
                                                    {searchResult.name.last} {searchResult.name.first}
                                                </Text>
                                            </Box>
                                            <Box w='30vw' maxWidth="200px">
                                                <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>2023-06-22</Text>
                                            </Box>
                                            <Box flex='1'>
                                                <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>{searchResult.location.state} ({searchResult.location.city})</Text>
                                            </Box>
                                        </Flex>
                                    ))}
                                    <Flex color='black' cursor={'pointer'} _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}>
                                        <Box w='30vw' maxWidth="250px">
                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={500}>
                                                <Highlight
                                                    query={searchString}
                                                    styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                >
                                                    Coldplay
                                                </Highlight>
                                            </Text>
                                        </Box>
                                        <Box w='30vw' maxWidth="200px">
                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>
                                                2024-06-22
                                            </Text>
                                        </Box>
                                        <Box flex='1'>
                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>
                                                Stade de France (Paris)
                                            </Text>
                                        </Box>
                                    </Flex>
                                    <Flex color='black' cursor={'pointer'} _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}>
                                        <Box w='30vw' maxWidth="250px">
                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={500}>
                                                Madonna
                                            </Text>
                                        </Box>
                                        <Box w='30vw' maxWidth="200px">
                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>
                                                2025-06-22
                                            </Text>
                                        </Box>
                                        <Box flex='1'>
                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>
                                                Accorhotel Arena (Paris)
                                            </Text>
                                        </Box>
                                    </Flex>
                                </PopoverBody>
                                <PopoverFooter display='flex' justifyContent='flex-end'>
                                    <ButtonGroup size='sm'>
                                        <Button variant='outline' colorScheme='gray' onClick={onClose}>Save</Button>
                                        <Button variant='solid' colorScheme='gray' onClick={onClose}>Close</Button>
                                    </ButtonGroup>
                                </PopoverFooter>
                            </PopoverContent>
                        </Popover>
                        {/*
                        {isOpen ? (
                            <Stack as={'nav'} spacing={4}>
                                <Box pb={4}>

                                    Hello
                                </Box>
                            </Stack>
                        ) : null}
*/}
                    </GridItem>
                </Grid>
            </Center>
        </>
    )
    return content
}
export default DashSearch