import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetEventsWaitQuery } from './eventsApiSlice'
import { memo } from 'react'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Button, Badge, Text, Icon, IconButton, Tooltip, Spinner, Image, Tag, TagLabel, Avatar, HStack, VStack } from '@chakra-ui/react'
import { TableContainer, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon, ArrowRightIcon, TimeIcon, CheckIcon, ExternalLinkIcon } from '@chakra-ui/icons'

const Event = ({ eventId, popupNewModal, popupAddModal, popupAddDrawer }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const { event } = useGetEventsWaitQuery("eventsWaitList", {
        selectFromResult: ({ data }) => ({
            event: data?.entities[eventId]
        }),
    })

    const updated = new Date(event.updatedAt).toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', long: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const navigate = useNavigate()

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener noreferrer nofollow').opener = null;
        //    https://www.whatismybrowser.com/detect/what-is-my-referrer
    };

    if (event) {

        const handleEdit = () => navigate(`/dash/events/${eventId}`)
        return (
            <Tr _hover={{
                color: '#333333', border: '2px', borderColor: 'teal.600'
            }
            }>
                <Td>
                    <HStack>
                        <div>
                            {(() => {

                                switch (event.oraclyState) {
                                    case 'new':
                                        return (
                                            <Badge colorScheme='blue'>{event.oraclyState}</Badge>
                                        )
                                    case 'update':
                                        return (
                                            <Badge colorScheme='purple'>{event.oraclyState}</Badge>
                                        )
                                    case 'nop':
                                        return (
                                            <Badge colorScheme='red'>{event.oraclyState}</Badge>
                                        )
                                    case 'scrap':
                                        return (
                                            <>
                                                <Icon as={TimeIcon} color='orange' w={3} h={3} />
                                                <Badge colorScheme='orange'>{event.oraclyState}</Badge>
                                            </>
                                        )
                                    case 'scraping':
                                        return (
                                            <>
                                                <Spinner
                                                    thickness='4px'
                                                    speed='0.65s'
                                                    emptyColor='gray.200'
                                                    color='orange.500'
                                                    size='xs'
                                                />
                                                <Badge colorScheme='orange'>{event.oraclyState}</Badge>
                                            </>)
                                    case 'scraped':
                                        return (
                                            <>
                                                <Icon as={CheckIcon} color='blue' w={3} h={3} />
                                                <Badge colorScheme='blue'>{event.oraclyState}</Badge>
                                            </>
                                        )
                                    case 'addping':
                                        return (
                                            <>
                                                <Spinner
                                                    thickness='4px'
                                                    speed='0.65s'
                                                    emptyColor='gray.200'
                                                    color='orange.500'
                                                    size='xs'
                                                />
                                                <Badge colorScheme='orange'>{event.oraclyState}</Badge>
                                            </>)
                                    case 'added':
                                        return (
                                            <>
                                                <Icon as={CheckIcon} color='green' w={3} h={3} />
                                                <Badge colorScheme='green'>{event.oraclyState}</Badge>
                                            </>
                                        )
                                    default:
                                        return (
                                            <Badge colorScheme='gray'>{event.oraclyState}</Badge>
                                        )
                                }

                            })()}
                            <br />
                            {updated}
                        </div>
                    </HStack>
                </Td>
                <Td>
                    <Tag size='lg' colorScheme='gray' borderRadius='full'>
                        <Avatar
                            src={"/ico/" + event.sourceCode + ".png"}
                            size='xs'
                            name={event.sourceCode}
                            ml={-1}
                            mr={2}
                        />
                        <TagLabel>FR</TagLabel>
                    </Tag>
                </Td>
                <Td onClick={() => popupNewModal(event._id)} cursor={'pointer'}>
                    <HStack>
                        <Image src={event.eventUrlPicture} h='50' />
                        <div>
                            <strong>{event.eventName}</strong><br />
                            {event.locationVenueName}<br />
                            {event.eventDateStart}<br />
                        </div>
                    </HStack>
                </Td>
                <Td>
                    {event.sourceTicketType == 'ticket' &&
                        <Badge colorScheme='green'>{event.sourceTicketType}</Badge>
                        ||
                        <Badge colorScheme='orange'>{event.sourceTicketType}</Badge>
                    }
                    <br />
                    {event.sourceState}
                </Td>
                <Td isNumeric>
                    {(() => {

                        switch (event.oraclyState) {
                            case 'new':
                                return (
                                    <>
                                        <Tooltip hasArrow label='Event is new, you need choose action...' bg='gray.300' closeOnClick={true} color='black' placement='left'>
                                            <IconButton
                                                colorScheme='blue'
                                                aria-label='New'
                                                icon={<ArrowRightIcon />}
                                                onClick={() => popupNewModal(eventId)}
                                            />
                                        </Tooltip>
                                    </>
                                )
                            case 'scrap':
                                return (
                                    <>
                                        <Tooltip hasArrow label='Event is scrape on queue, please wait...' bg='gray.300' closeOnClick={true} color='black' placement='left'>
                                            <IconButton
                                                colorScheme='orange'
                                                aria-label='Scrap'
                                                icon={<TimeIcon />}
                                            />
                                        </Tooltip>
                                    </>
                                )
                            case 'scraping':
                                return (
                                    <>
                                        <Tooltip hasArrow label='Event is scraping, please wait...' bg='gray.300' closeOnClick={true} color='black' placement='left'>
                                            <IconButton
                                                colorScheme='orange'
                                                aria-label='Scraping'
                                                isLoading='true'
                                            />
                                        </Tooltip>
                                    </>
                                )
                            case 'scraped':
                                return (
                                    <>
                                        <Tooltip hasArrow label='Event is scraped, you can add now in DB' bg='gray.300' closeOnClick={true} color='black' placement='left'>
                                            <IconButton
                                                colorScheme='green'
                                                aria-label='Scraped'
                                                icon={<AddIcon />}
                                                onClick={() => popupAddDrawer(eventId)}
                                            />
                                        </Tooltip>
                                    </>
                                )
                            case 'add':
                                return (
                                    <>
                                        <Tooltip hasArrow label='Event is add on queue, please wait...' bg='gray.300' closeOnClick={true} color='black' placement='left'>
                                            <IconButton
                                                colorScheme='blue'
                                                aria-label='Add'
                                                icon={<TimeIcon />}
                                            />
                                        </Tooltip>
                                    </>
                                )

                            default:
                                return (
                                    <>
                                    </>
                                )
                        }

                    })()}
                    <IconButton
                        size='xs'
                        colorScheme='gray'
                        aria-label='Visit'
                        icon={<ExternalLinkIcon />}
                        onClick={() => openInNewTab(`${event.sourceUrl}`)}
                        ml='2'
                    />
                </Td>
            </Tr >
        )

    } else return null
}

const memoizedEvent = memo(Event)

export default memoizedEvent