import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewTicketMutation } from "./ticketsApiSlice"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket } from "@fortawesome/free-solid-svg-icons"

const NewTicketForm = ({ countries }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewTicket, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewTicketMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const [formFieldsTicketName, setFormFields] = useState([
        { lang: 'en', ticketName: '' }, { lang: 'fr', ticketName: '' },
    ])

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        setCanSave(true)
        formFieldsTicketName.map((form) => {
            if (!isLoading && (form.lang === '' || form.ticketName === '')) {
                setCanSave(false)
            }
        })
    }

    const onPageBack = () => navigate('/dash/tickets')

    const onSaveTicketClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            let ticketNameByLang = {}
            formFieldsTicketName.forEach(field => {
                ticketNameByLang[field.lang] = field.ticketName
            })
            output['ticketName'] = ticketNameByLang
            console.log(output)

            await addNewTicket({ ticketName: ticketNameByLang })
        }

    }

    const handleFormChange = (event, index) => {
        let data = [...formFieldsTicketName];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            ticketName: ''
        }
        setFormFields([...formFieldsTicketName, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsTicketName];
        data.splice(index, 1)
        setFormFields(data)
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'TICKET : Add successfully', status: 'success' })
            navigate('/dash/tickets')
        }
        if (isError) {
            toastInfo({ title: 'TICKET : Add error (' + error?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, isError, error, isSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faTicket} /> {T.tickets.NewTicket}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveTicketClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={onSaveTicketClicked}>
                                <FormControl>

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.tickets.TicketName}
                                    </FormLabel>
                                    {formFieldsTicketName.map((form, index) => {
                                        return (
                                            <HStack key={index}>
                                                <Input
                                                    isInvalid={!form.lang}
                                                    name='lang'
                                                    placeholder={T.tickets.IsoCode}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.lang}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    minLength="2"
                                                    maxLength="2"
                                                    bg='yellow.100'
                                                    w='10vw'
                                                />
                                                <Input
                                                    isInvalid={!form.ticketName}
                                                    name='ticketName'
                                                    placeholder={T.tickets.TranslatedName}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.ticketName}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    w='50vw'
                                                />
                                                {index !== 0 &&
                                                    <IconButton
                                                        size='xs'
                                                        name="Remove"
                                                        title={T.common.Delete}
                                                        colorScheme='gray'
                                                        aria-label={T.common.Delete}
                                                        icon={<MinusIcon />}
                                                        onClick={() => removeFields(index)}
                                                    />
                                                }
                                            </HStack>
                                        )
                                    })}
                                    <IconButton
                                        size='xs'
                                        title={T.common.Add}
                                        onClick={addFields}
                                        colorScheme='gray'
                                        aria-label={T.common.Add}
                                        icon={<AddIcon />}
                                    />

                                </FormControl>
                            </form>

                        </Box>
                    </GridItem>
                </Grid>
            </Center>
        </>
    )

    return content
}

export default NewTicketForm