import NewVenueForm from './NewVenueForm'
import { useGetCitiesQuery } from '../cities/citiesApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewVenue = () => {
    useTitle('ORACLY: New Venue')

    const { cities } = useGetCitiesQuery("citiesList", {
        selectFromResult: ({ data }) => ({
            cities: data?.ids.map(id => data?.entities[id], name => data?.entities[name])
        }),
    })

    if (!cities?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewVenueForm cities={cities} />

    return content
}
export default NewVenue