import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewCategoryMutation } from "./categoriesApiSlice"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Select, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderTree } from "@fortawesome/free-solid-svg-icons"

const NewCategoryForm = ({ parents }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewCategory, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCategoryMutation()

    const navigate = useNavigate()

    const [parentId, setParentId] = useState(parents[0]._id)

    let [canSave, setCanSave] = useState(false)

    const [formFieldsCategoryName, setFormFields] = useState([
        { lang: 'en', categoryName: '' }, { lang: 'fr', categoryName: '' },
    ])

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            formFieldsCategoryName.map((form) => {
                if (!isLoading && (form.lang === '' || form.categoryName === '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/categories')

    const onParentIdChanged = e => setParentId(e.target.value)

    const onSaveCategoryClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            let categoryNameByLang = {}
            formFieldsCategoryName.forEach(field => {
                categoryNameByLang[field.lang] = field.categoryName
            })
            output['categoryName'] = categoryNameByLang
            console.log(output)
            console.log('parentId = ' + parentId)

            await addNewCategory({ parentId: parentId, categoryName: categoryNameByLang })
        }

    }

    const options = parents.map(parent => {
        return (
            <option
                key={parent.id}
                value={parent.id}
            > {parent.categoryName[lang]}</option >
        )
    })

    const handleFormChange = (event, index) => {
        let data = [...formFieldsCategoryName];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            categoryName: ''
        }
        setFormFields([...formFieldsCategoryName, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsCategoryName];
        data.splice(index, 1)
        setFormFields(data)
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'CATEGORY : Add successfully', status: 'success' })
            setParentId('')
            navigate('/dash/categories')
        }
        if (isError) {
            toastInfo({ title: 'CATEGORY : Add error (' + error?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, isError, error, isSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faFolderTree} /> {T.categories.NewCategory}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveCategoryClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={onSaveCategoryClicked}>
                                <FormControl>

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.categories.CategoryName}
                                    </FormLabel>
                                    {formFieldsCategoryName.map((form, index) => {
                                        return (
                                            <HStack key={index}>
                                                <Input
                                                    isInvalid={!form.lang}
                                                    name='lang'
                                                    placeholder={T.categories.IsoCode}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.lang}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    minLength="2"
                                                    maxLength="2"
                                                    bg='yellow.100'
                                                    w='10vw'
                                                />
                                                <Input
                                                    isInvalid={!form.categoryName}
                                                    name='categoryName'
                                                    placeholder={T.categories.TranslatedName}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.categoryName}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    w='50vw'
                                                />
                                                {index !== 0 &&
                                                    <IconButton
                                                        size='xs'
                                                        name="Remove"
                                                        title={T.common.Delete}
                                                        colorScheme='gray'
                                                        aria-label={T.common.Delete}
                                                        icon={<MinusIcon />}
                                                        onClick={() => removeFields(index)}
                                                    />
                                                }
                                            </HStack>
                                        )
                                    })}
                                    <IconButton
                                        size='xs'
                                        title={T.common.Add}
                                        onClick={addFields}
                                        colorScheme='gray'
                                        aria-label={T.common.Add}
                                        icon={<AddIcon />}
                                    />

                                    <FormLabel htmlFor='parentId' mt='5' mb='0'>
                                        {T.categories.CategoryParent}
                                    </FormLabel>
                                    <Select
                                        id="parentId"
                                        name="parentId"
                                        value={parentId}
                                        onChange={onParentIdChanged}
                                        bg='yellow.100'
                                    >
                                        {options}
                                    </Select>

                                </FormControl>
                            </form>

                        </Box>
                    </GridItem>
                </Grid>
            </Center>
        </>
    )

    return content
}

export default NewCategoryForm