import { useGetCategoriesQuery } from '../categories/categoriesApiSlice'
import { useGetTicketsQuery } from '../tickets/ticketsApiSlice'
import { useGetSourcesQuery } from '../sources/sourcesApiSlice'
import NewEventForm from './NewEventForm'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewEvent = () => {
    useTitle('ORACLY: New Event')

    const { categories } = useGetCategoriesQuery("categoriesList", {
        selectFromResult: ({ data }) => ({
            categories: data?.ids.map(id => data?.entities[id])
        }),
    })

    const { tickets } = useGetTicketsQuery("ticketsList", {
        selectFromResult: ({ data }) => ({
            tickets: data?.ids.map(id => data?.entities[id])
        }),
    })

    const { sources } = useGetSourcesQuery("sourcesList", {
        selectFromResult: ({ data }) => ({
            sources: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!categories?.length || !sources?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewEventForm categories={categories} tickets={tickets} sources={sources} />

    return content
}
export default NewEvent