import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const sourcesAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = sourcesAdapter.getInitialState()

export const sourcesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSources: builder.query({
            query: () => ({
                url: '/sources',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedSources = responseData.map(Source => {
                    Source.id = Source._id
                    return Source
                });
                return sourcesAdapter.setAll(initialState, loadedSources)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Source', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Source', id }))
                    ]
                } else return [{ type: 'Source', id: 'LIST' }]
            }
        }),
        addNewSource: builder.mutation({
            query: initialSource => ({
                url: '/sources',
                method: 'POST',
                body: {
                    ...initialSource,
                }
            }),
            invalidatesTags: [
                { type: 'Source', id: "LIST" }
            ]
        }),
        updateSource: builder.mutation({
            query: initialSource => ({
                url: '/sources',
                method: 'PATCH',
                body: {
                    ...initialSource,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Source', id: arg.id }
            ]
        }),
        deleteSource: builder.mutation({
            query: ({ id }) => ({
                url: `/sources`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Source', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetSourcesQuery,
    useAddNewSourceMutation,
    useUpdateSourceMutation,
    useDeleteSourceMutation,
} = sourcesApiSlice

// returns the query result object
export const selectSourcesResult = sourcesApiSlice.endpoints.getSources.select()

// creates memoized selector
const selectSourcesData = createSelector(
    selectSourcesResult,
    sourcesResult => sourcesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllSources,
    selectById: selectSourceById,
    selectIds: selectSourceIds
    // Pass in a selector that returns the sources slice of state
} = sourcesAdapter.getSelectors(state => selectSourcesData(state) ?? initialState)