import { useNavigate } from 'react-router-dom'
import { useGetTicketsQuery } from './ticketsApiSlice'
import { memo } from 'react'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Icon, IconButton, Tr, Td, Tooltip } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'

const Ticket = ({ ticketId }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const { Ticket } = useGetTicketsQuery("ticketsList", {
        selectFromResult: ({ data }) => ({
            Ticket: data?.entities[ticketId]
        }),
    })

    const navigate = useNavigate()

    if (Ticket) {

        const handleEdit = () => navigate(`/dash/tickets/${ticketId}`)

        return (
            <Tr _hover={{ color: '#333333', border: '2px', borderColor: 'teal.600' }}>
                <Td>
                    {Ticket.ticketStatus
                        ? <Icon as={CheckIcon} w={4} h={4} color='green.500' title={T.common.Actived} />
                        : <Icon as={CloseIcon} w={4} h={4} color='red.500' title={T.common.Disabled} />
                    }
                </Td>

                <Td>
                    <strong>{Ticket.ticketName[lang]}</strong>
                </Td>

                <Td isNumeric>
                    <Tooltip hasArrow label={T.common.Edit} bg='gray.300' closeOnClick={true} color='black' placement='left'>
                        <IconButton
                            colorScheme='gray'
                            aria-label='New'
                            icon={<EditIcon />}
                            onClick={handleEdit}
                            _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}
                        />
                    </Tooltip>
                </Td>
            </Tr>
        )

    } else return null
}

const memoizedTicket = memo(Ticket)

export default memoizedTicket