import { useState, useEffect } from "react"
import { useUpdateSourceMutation, useDeleteSourceMutation } from "./sourcesApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Select, Switch, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket } from "@fortawesome/free-solid-svg-icons"

const EditSourceForm = ({ Source, countries }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [updateSource, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateSourceMutation()

    const [deleteSource, {
        data: delData,
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delError
    }] = useDeleteSourceMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const [sourceStatus, setSourceStatus] = useState(Source.sourceStatus)

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            formFieldsSources.map((formFieldsSource) => {
                if (!isLoading && (formFieldsSource.sourceType === '' || formFieldsSource.sourceLang === '' || formFieldsSource.sourceWebsite === '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/sources')

    const onSourceStatusChanged = e => setSourceStatus(prev => !prev)

    const onSaveSourceClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let sourceType = ''
            let sourceLang = ''
            let sourceWebsite = ''
            let sourceUrlRegex = ''
            formFieldsSources.forEach((formFieldsSource, index) => {
                sourceType = formFieldsSource.sourceType
                sourceLang = formFieldsSource.sourceLang
                sourceWebsite = formFieldsSource.sourceWebsite
                sourceUrlRegex = formFieldsSource.sourceUrlRegex
            })

            await updateSource({ id: Source.id, sourceType: sourceType, sourceLang: sourceLang, sourceWebsite: sourceWebsite, sourceUrlRegex: sourceUrlRegex, sourceStatus })
        }
    }

    const onDeleteSourceClicked = async () => {
        await deleteSource({ id: Source.id })
    }

    const created = new Date(Source.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(Source.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <Button size='xs' onClick={onDeleteSourceClicked} name="Delete" title={T.common.Delete} leftIcon={<CloseIcon />} colorScheme='red' variant='outline'>{T.sources.DeleteThisSource}</Button>
        )
    }

    const initSourceNamesForm = () => {
        let source = []
        source.push({ sourceName: Source.sourceName, sourceType: Source.sourceType, sourceLang: Source.sourceLang, sourceWebsite: Source.sourceWebsite, sourceUrlRegex: Source.sourceUrlRegex })
        return source
    }

    const [formFieldsSources, setFormFields] = useState(initSourceNamesForm)

    const handleFormChange = (event, index) => {
        let data = [...formFieldsSources];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            sourceName: ''
        }
        setFormFields([...formFieldsSources, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsSources];
        data.splice(index, 1)
        setFormFields(data)
        checkCanSave()
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'SOURCE : ' + data?.message + ' successfully', status: 'success' })
            initSourceNamesForm()
            navigate('/dash/sources')
        }
        if (isError) {
            toastInfo({ title: 'SOURCE : Update error (' + error?.data?.message + ')', status: 'error' })
        }
        if (isDelSuccess) {
            toastInfo({ title: 'SOURCE : ' + delData?.message + ' successfully', status: 'success' })
            initSourceNamesForm()
            navigate('/dash/sources')
        }
        if (isDelError) {
            toastInfo({ title: 'SOURCE : Update error (' + delError?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, initSourceNamesForm, toastInfo, isError, error, isDelError, delError, isSuccess, isDelSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' maxW='1500' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faTicket} /> {T.sources.EditSource}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveSourceClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={e => e.preventDefault()}>

                                <HStack>
                                    <FormControl>
                                        <FormLabel htmlFor='SourceType' mt='5' mb='0'>
                                            {T.sources.SourceType}
                                        </FormLabel>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='SourceLang' mt='5' mb='0'>
                                            {T.sources.SourceLang}
                                        </FormLabel>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel htmlFor='SourceWebsite' mt='5' mb='0'>
                                            {T.sources.SourceWebsite}
                                        </FormLabel>
                                    </FormControl>
                                </HStack>
                                {formFieldsSources.map((formFieldsSource, index) => {
                                    return (
                                        <Box key={index}>
                                            <HStack>
                                                <FormControl>
                                                    <Select
                                                        isInvalid={!formFieldsSource.sourceType ?? checkCanSave()}
                                                        name='sourceType'
                                                        placeholder='Select option'
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsSource.sourceType}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        size='md'
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    >
                                                        <option value='isFirstmarket'>isFirstmarket</option>
                                                        <option value='isSecondmarket'>isSecondmarket</option>
                                                        <option value='isAgregator'>isAgregator</option>
                                                        <option value='isOfficial'>isOfficial</option>
                                                    </Select>
                                                </FormControl>
                                                <FormControl>
                                                    <Input
                                                        isInvalid={!formFieldsSource.sourceLang}
                                                        name='sourceLang'
                                                        placeholder={T.sources.IsoCode}
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsSource.sourceLang}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        minLength="2"
                                                        maxLength="2"
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Input
                                                        isInvalid={!formFieldsSource.sourceWebsite}
                                                        name='sourceWebsite'
                                                        placeholder={T.sources.SourceWebsite}
                                                        onChange={event => handleFormChange(event, index)}
                                                        value={formFieldsSource.sourceWebsite}
                                                        focusBorderColor='blue.400'
                                                        errorBorderColor='red.300'
                                                        bg='yellow.100'
                                                        bg='yellow.100'
                                                    />
                                                </FormControl>
                                            </HStack>

                                            <FormLabel htmlFor='SourceUrlRegex' mt='5' mb='0'>
                                                {T.sources.SourceUrlRegex}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    //isInvalid={!formFieldsSource.sourceUrlRegex}
                                                    name='sourceUrlRegex'
                                                    placeholder={T.sources.SourceUrlRegex}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={formFieldsSource.sourceUrlRegex}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    bg='yellow.100'
                                                />
                                            </FormControl>
                                        </Box>
                                    )
                                })
                                }

                                <FormLabel htmlFor='sourceStatus' mt='5' mb='0'>
                                    {T.sources.Status}
                                </FormLabel>
                                <Switch
                                    id='sourceStatus'
                                    name="status"
                                    colorScheme='green'
                                    isChecked={sourceStatus}
                                    onChange={onSourceStatusChanged}
                                />

                                <br />

                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Created}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{created}</Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Updated}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{updated}</Text>
                                </HStack>
                            </form>
                            <br />
                            <br />
                            {deleteButton}

                        </Box>
                    </GridItem>

                </Grid>
            </Center >

        </>
    )

    return content
}

export default EditSourceForm