import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetCitiesQuery } from './citiesApiSlice'
import { memo } from 'react'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Icon, IconButton, Tr, Td, Tooltip } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'

const City = ({ cityId }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const { city } = useGetCitiesQuery("citiesList", {
        selectFromResult: ({ data }) => ({
            city: data?.entities[cityId]
        }),
    })

    const navigate = useNavigate()

    if (city) {

        const handleEdit = () => navigate(`/dash/cities/${cityId}`)

        return (
            <Tr _hover={{ color: '#333333', border: '2px', borderColor: 'teal.600' }}>
                <Td>
                    {city.cityStatus
                        ? <Icon as={CheckIcon} w={4} h={4} color='green.500' title={T.common.Actived} />
                        : <Icon as={CloseIcon} w={4} h={4} color='red.500' title={T.common.Disabled} />
                    }
                </Td>

                <Td>
                    <strong>{city.cityName[lang]}</strong>
                </Td>

                <Td>
                    {city.cityTimezoneUTC}
                </Td>

                <Td>
                    {city.countryName[lang]}
                </Td>

                <Td>
                    {city.marketName[lang]}
                </Td>

                <Td isNumeric>
                    <Tooltip hasArrow label={T.common.Edit} bg='gray.300' closeOnClick={true} color='black' placement='left'>
                        <IconButton
                            colorScheme='gray'
                            aria-label='New'
                            icon={<EditIcon />}
                            onClick={handleEdit}
                            _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}
                        />
                    </Tooltip>
                </Td>
            </Tr>
        )

    } else return null
}

const memoizedCity = memo(City)

export default memoizedCity