import NewSourceForm from './NewSourceForm'
import useTitle from '../../hooks/useTitle'

const NewSource = () => {
    useTitle('ORACLY: New Source')

    const content = <NewSourceForm />

    return content
}
export default NewSource