import NewCountryForm from './NewCountryForm'
import { useGetMarketsQuery } from '../markets/marketsApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewCountry = () => {
    useTitle('ORACLY: New Country')

    const { markets } = useGetMarketsQuery("marketsList", {
        selectFromResult: ({ data }) => ({
            markets: data?.ids.map(id => data?.entities[id], name => data?.entities[name])
        }),
    })

    if (!markets?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewCountryForm markets={markets} />

    return content
}
export default NewCountry