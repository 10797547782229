import React, { useState } from "react";
import { uploadFile } from "./../../useFileUploadService";

// import `ChakraProvider` component
import {
	Box,
	SimpleGrid,
	Image,
	Text,
	Button,
	AspectRatio,
	FormControl,
	HStack,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import { DeleteIcon } from "@chakra-ui/icons";

import { FaUpload } from "react-icons/fa";

const VenueUploadForm = ({ updateVenuePics, deleteVenuePics, venuePics }) => {
	console.log("venuePics = " + JSON.stringify(venuePics));

	const [file, setFile] = useState([]);
	const [prevfile, setPrevFile] = useState([]);

	function uploadSingleFile(e) {
		const selectedFiles = e.target.files;

		// Vérifier si des fichiers sont sélectionnés
		if (selectedFiles.length > 0) {
			const imagesArray = Array.from(selectedFiles);

			// Vérifier si le tableau d'images est non vide avant d'ajouter au state
			if (imagesArray.length > 0) {
				console.log("ImagesArray:", imagesArray);

				const imagesPreview = imagesArray.map((file) =>
					URL.createObjectURL(file)
				);
				console.log("imagesPreview:", imagesPreview);

				// Utiliser une fonction de mise à jour du state pour garantir la dernière valeur de "file"
				setFile((file) => {
					console.log("file:", file);
					return [...file, ...imagesArray];
				});

				// Utiliser une fonction de mise à jour du state pour garantir la dernière valeur de "file"
				setPrevFile((prevFile) => {
					console.log("prevFile:", prevFile);
					return [...prevFile, ...imagesPreview];
				});
			} else {
				console.log("Aucune image à ajouter au state.");
			}
		} else {
			console.log("Aucun fichier sélectionné.");
		}
	}

	const upload = async () => {
		try {
			const formData = new FormData();
			console.log("file:", JSON.stringify(file));
			file.forEach((filee) => {
				console.log("filee:", JSON.stringify(filee));
				formData.append("files", filee);
				console.log("formData:", JSON.stringify(formData));
			});

			console.log("FormData avant envoi :", formData);

			const responseUp = await uploadFile(formData, "picVenue");

			console.log("File uploaded successfully:", responseUp.data.fileIds);

			try {
				updateVenuePics(responseUp.data.fileIds);
			} catch (error) {
				console.error("Erreur lors de la mise a jour :", error);
				// Gérer l'erreur ici
			}

			console.log("responseUp = " + JSON.stringify(responseUp));
			console.log("Téléchargement réussi.");

			// Vous pouvez faire quelque chose après le téléchargement, par exemple vider la liste des fichiers
			setFile([]);
			setPrevFile([]);
		} catch (error) {
			console.error("Erreur lors du téléchargement des fichiers:", error);
			// Gérer l'erreur ici
		}
	};

	function deletePrevFile(e) {
		const s = file.filter((item, index) => index !== e);
		setFile(s);
		const t = prevfile.filter((item, index) => index !== e);
		setPrevFile(t);
	}

	function deleteFile(e) {
		deleteVenuePics(e);
	}

	return (
		<form method="post" encType="multipart/form-data">
			<Box p="3" backgroundColor={"yellow.100"}>
				<HStack mb="5">
					<FormControl>
						<input
							type="file"
							name="files"
							disabled={file.length === 5}
							onChange={uploadSingleFile}
							multiple
							size="xs"
						/>
					</FormControl>
					{file.length > 0 && (
						<Button
							type="button"
							leftIcon={<FaUpload />}
							colorScheme="teal"
							className="btn btn-primary btn-block"
							onClick={upload}
							size="lg"
						>
							Upload
						</Button>
					)}
				</HStack>

				<SimpleGrid columns={5} spacing={2}>
					{prevfile.map((item, index) => {
						return (
							<>
								<Box
									key={index}
									maxW="200"
									m="2"
									borderWidth="1px"
									borderRadius="lg"
									overflow="hidden"
								>
									<AspectRatio maxW="200px" ratio={1}>
										<Image src={item} />
									</AspectRatio>

									<Box p="1" bgColor="gray.100">
										<Box
											mt="0"
											fontWeight="semibold"
											as="h4"
											lineHeight="tight"
											noOfLines={1}
										></Box>
										<Box display="flex" alignItems="baseline">
											<Button
												size="xs"
												leftIcon={<DeleteIcon />}
												colorScheme="red"
												variant="solid"
												onClick={() => deletePrevFile(index)}
											>
												Delete
											</Button>
										</Box>
									</Box>
								</Box>
							</>
						);
					})}
				</SimpleGrid>
			</Box>

			<Box p="3" backgroundColor={"gray.100"}>
				<SimpleGrid columns={5} spacing={2}>
					{venuePics.map((item, index) => {
						return (
							<>
								<Box
									key={index}
									maxW="200"
									m="2"
									borderWidth="1px"
									borderRadius="lg"
									overflow="hidden"
								>
									<AspectRatio maxW="200px" ratio={1}>
										<Image
											src={`https://api.oracly.svppb.com/images/${item}/display`}
										/>
									</AspectRatio>

									<Box p="1" bgColor="gray.100">
										<Box
											mt="0"
											fontWeight="semibold"
											as="h4"
											lineHeight="tight"
											noOfLines={1}
										>
											<Text fontSize={12} fontWeight={600}>
												{item}
											</Text>
										</Box>
										<Box display="flex" alignItems="baseline">
											<Button
												size="xs"
												leftIcon={<DeleteIcon />}
												colorScheme="red"
												variant="solid"
												onClick={() => deleteFile(index)}
											>
												Delete
											</Button>
										</Box>
									</Box>
								</Box>
							</>
						);
					})}
				</SimpleGrid>
			</Box>
		</form>
	);
};

export default VenueUploadForm;
