import { useState, useEffect } from 'react';
import { useGetEventsQuery, useGetInfoEventsQuery } from "./eventsApiSlice"
//import { useGetTicketsQuery } from '../tickets/ticketsApiSlice'
import Event from "./Event"
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

// Load screen
import { LoadingScreen } from "./../../screens";

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, VStack, Container, Stack, Tag, Spacer, Select, Alert, AlertIcon, Badge, useToast, toastInfo, Icon, Image, Button, Text, useDisclosure } from '@chakra-ui/react'
import { TableContainer, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSnowman } from "@fortawesome/free-solid-svg-icons"
import { faDownload, faCaretLeft, faBackward, faBackwardFast, faCaretRight, faForward, faForwardFast } from "@fortawesome/free-solid-svg-icons"

const EventsList = () => {
    useTitle('ORACLY: Events List')

    const [loading, setLoading] = useState(true);

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const { username, isEmployee, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)


    const [dataNav, setDataNav] = useState([])
    const [Page, setPage] = useState(1)
    const [Pagination, setPagination] = useState(10)
    const [nbPaginations, setNbPaginations] = useState([10, 20, 50, 100]);

    const [nbPages, setNbPages] = useState([1])
    const [nbEvents, setNbEvents] = useState(null)


    const {
        data: events,
        isLoading: isLoadingEvents,
        isSuccess: isSuccessEvents,
        isError: isErrorEvents,
        error: errorEvents,
        refetch: refetchEvents,
    } = useGetEventsQuery({
        Pagination: Pagination,
        Page: Page,
        //        pollingInterval: 5000,
        //        refetchOnFocus: true,
        //        refetchOnMountOrArgChange: true
    })

    const {
        data: infoEvents,
        refetch: refetchInfoEvents,
    } = useGetInfoEventsQuery({
        //        pollingInterval: 15000,
        //        refetchOnFocus: true,
        //        refetchOnMountOrArgChange: true
    })


    useEffect(() => {
        //if (localStorage.hasOwnProperty("Page")) {
        //    setPage(JSON.parse(localStorage.getItem('Page')))
        //}
        const storedPage = localStorage.getItem('Page');
        if (storedPage) {
            // Utilisez JSON.parse pour convertir la chaîne JSON en valeur JavaScript
            setPage(JSON.parse(storedPage));
        }

        //if (localStorage.hasOwnProperty("Pagination")) {
        //    setPagination(JSON.parse(localStorage.getItem('Pagination')))
        //}
        const storedPagination = localStorage.getItem('Pagination');
        if (storedPagination) {
            // Utilisez JSON.parse pour convertir la chaîne JSON en valeur JavaScript
            setPagination(JSON.parse(storedPagination));
        }


    }, []);

    const setLocalNav = (dataNav) => {
        let { Page, Pagination } = dataNav;
        localStorage.setItem('Page', Page);
        localStorage.setItem('Pagination', Pagination);
    }

    const onPageChanged = async (e) => {
        await refetchInfoEvents();
        await refetchEvents();
        setPage(eval(e.target.value))

        dataNav.Page = eval(e.target.value)
        dataNav.Pagination = Pagination
        setLocalNav(dataNav)
    }

    const onPaginationChanged = async (e) => {
        await refetchInfoEvents();
        await refetchEvents();
        setPagination(e.target.value)
        dataNav.Pagination = e.target.value
        var tmp = Math.ceil(nbEvents / e.target.value)
        setNbPages(Array.from({ length: tmp }, (_, i) => i + 1));
        if (Page > tmp) {
            setPage(tmp)
            dataNav.Page = tmp
        }

        dataNav.Page = Page
        dataNav.Pagination = eval(e.target.value)
        setLocalNav(dataNav)
    }

    const onPageNav = (e) => {
        switch (true) {
            case Page + e > nbPages.length:
                setPage(nbPages.length)
                dataNav.Page = nbPages.length
                break;
            case Page + e <= 1:
                setPage(1)
                dataNav.Page = 1
                break;
            default:
                setPage(eval(Page + e))
                dataNav.Page = Page + e
        }

        dataNav.Pagination = Pagination
        setLocalNav(dataNav)
    }


    /*
    const { tickets } = useGetTicketsQuery("ticketsList", {
        selectFromResult: ({ data }) => ({
            tickets: data?.ids.map(id => data?.entities[id])
        }),
    })
    console.log("tickets = " + JSON.stringify(tickets))
    */

    let menuContent
    if (isManager || isAdmin) {
        menuContent = (
            <>
                <Link to="/dash/events/new">
                    <Button
                        size='sm'
                        leftIcon={<AddIcon />}
                        colorScheme='teal'
                        variant='solid'>
                        <Text
                            color='white'
                            display={{ base: 'none', sm: 'flex' }}
                            fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
                            fontWeight={600}
                        >
                            {T.common.Add}
                        </Text>
                    </Button>
                </Link>
            </>
        )
    }
    if (isEmployee) {
    }

    let content
    if (isLoadingEvents) {
        content = (
            <>
                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                    <Stack spacing={3}>
                        <PulseLoader color={"#FFF"} />
                    </Stack>
                </Box>
            </>
        )
    }

    if (isErrorEvents) {
        if (isManager || isAdmin) {
            toastInfo({ title: 'LOAD : ' + errorEvents?.data?.message, status: 'info' })
            content = (
                <>
                    <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                        <Stack spacing={3}>
                            <Alert status='error'>
                                <AlertIcon />
                                {errorEvents?.data?.message}
                            </Alert>
                        </Stack>
                    </Box>
                </>
            )
        } else {
            toastInfo({ title: 'LOAD : ' + errorEvents?.data?.message, status: 'info' })
            content = (
                <>
                    <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                        <Stack spacing={3}>
                            <Alert status='error'>
                                <AlertIcon />
                                {errorEvents?.data?.message}
                            </Alert>
                        </Stack>
                    </Box>
                </>
            )
        }
    }

    if (infoEvents && nbEvents == null) {
        setNbEvents(infoEvents.nbEvents)
        var tmp = Math.ceil(infoEvents.nbEvents / Pagination)
        setNbPages(Array.from({ length: tmp }, (_, i) => i + 1));
    }

    if (isSuccessEvents) {
        const { ids, entities } = events

        //console.log("events = " + JSON.stringify(events))

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(eventId => entities[eventId].username === username)
        }

        //const tableContent = ids?.length && filteredIds.map(eventId => <Event key={eventId} eventId={eventId} />)

        const tableContent = ids?.length && filteredIds.map(eventId => <Event eventId={eventId} event={entities[eventId]} />)
        //const tableContent = ids?.length && filteredIds.map(eventId => console.log("ids = " + entities[eventId].event.eventName))


        const options_nbPage = nbPages.map((nbPage, i) => {
            return (
                <option
                    key={i}
                    value={nbPage}
                >
                    {nbPage}
                </option>
            )
        })

        let paginationContent
        paginationContent = (
            <>

                {(function () {
                    if (Page > 1) {
                        return <Button m='1'
                            title="first"
                            onClick={() => onPageNav(-99999)}
                        >
                            <FontAwesomeIcon icon={faBackwardFast} />
                        </Button>;
                    }
                })()}

                {(function () {
                    if (Page > 10) {
                        return <Button m='1'
                            title="-10"
                            onClick={() => onPageNav(-10)}
                        >
                            <FontAwesomeIcon icon={faBackward} />
                        </Button>;
                    }
                })()}

                {(function () {
                    if (Page > 1) {
                        return <Button m='1'
                            title="-1"
                            onClick={() => onPageNav(-1)}
                        >
                            <FontAwesomeIcon icon={faCaretLeft} />
                        </Button>;
                    }
                })()}

                <Select
                    variant="brandPrimary"
                    id="Page"
                    name="Page"
                    value={Page}
                    onChange={onPageChanged}
                >
                    {options_nbPage}
                </Select>

                {(function () {
                    if (Page + 1 <= nbPages.length) {
                        return <Button
                            title="+1"
                            onClick={() => onPageNav(1)}
                        >
                            <FontAwesomeIcon icon={faCaretRight} />
                        </Button>;
                    }
                })()}

                {(function () {
                    if (Page + 10 <= nbPages.length) {
                        return <Button m='1'
                            title="+10"
                            onClick={() => onPageNav(10)}
                        >
                            <FontAwesomeIcon icon={faForward} />
                        </Button>;
                    }
                })()}

                {(function () {
                    if (Page + 1 <= nbPages.length) {
                        return <Button m='1'
                            title="last"
                            onClick={() => onPageNav(99999)}
                        >
                            <FontAwesomeIcon icon={faForwardFast} />
                        </Button>;
                    }
                })()}

            </>
        )


        content = (
            <>
                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                    <TableContainer>
                        <Table
                            variant='striped'
                            size='md'
                        >
                            <Thead>
                                <Tr>
                                    <Th w='30px'>{T.events.Status}</Th>
                                    <Th>{T.events.Edit}</Th>
                                    <Th>{T.events.Event}</Th>
                                    <Th>{T.events.Venue}</Th>
                                    <Th>{T.events.Ticket}</Th>
                                    <Th>{T.events.Map}</Th>
                                    <Th>{T.events.Source}</Th>
                                    <Th>{T.events.Cal}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tableContent}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>

                <HStack>
                    <Box p='2'>
                        <Select
                            variant="brandPrimary"
                            id="Pagination"
                            name="Pagination"
                            value={Pagination}
                            onChange={onPaginationChanged}
                        >
                            {
                                nbPaginations && nbPaginations.length > 0 && nbPaginations.map((nbPagination, i) => {
                                    return (
                                        <option
                                            key={i}
                                            value={nbPagination}
                                        > {nbPagination}</option >
                                    )
                                })
                            }
                        </Select>
                    </Box>
                    <Spacer />
                    <Box p='2'>
                        <HStack>
                            {paginationContent}
                        </HStack>
                    </Box>
                </HStack>

            </>
        )
    }

    let body = (
        <>
            {!loading ? (
                <Center bg='#45413C' p='0' color='white' axis='both'>
                    <Grid w='95vw' maxW='1500' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                            <HStack color='white' pb='2'>
                                <Box p='2' color='262626' textAlign={['left']} >
                                    <HStack>
                                        <Text fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={700}><FontAwesomeIcon icon={faSnowman} /> {T.events.EventsList}</Text>
                                        <Tag ml='5' p='2'>
                                            <VStack>
                                                <Text fontSize={17} fontWeight={600}>
                                                    <strong>{nbEvents}</strong> EVENTS
                                                </Text>
                                            </VStack>
                                        </Tag>
                                    </HStack>
                                </Box>
                                <Spacer />
                                <Box p='2' color='262626' textAlign={['right']}>
                                    {menuContent}
                                </Box>
                            </HStack>
                            {content}
                        </GridItem>
                    </Grid>
                </Center>
            ) : (
                <LoadingScreen section='EVENTS' />
            )}
        </>
    )

    return body
    return content
}
export default EventsList