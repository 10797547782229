import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const venuesAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = venuesAdapter.getInitialState()

export const venuesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getVenues: builder.query({
            query: () => ({
                url: '/venues',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedVenues = responseData.map(venue => {
                    venue.id = venue._id
                    return venue
                });
                return venuesAdapter.setAll(initialState, loadedVenues)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Venue', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Venue', id }))
                    ]
                } else return [{ type: 'Venue', id: 'LIST' }]
            }
        }),
        addNewVenue: builder.mutation({
            query: initialVenue => ({
                url: '/venues',
                method: 'POST',
                body: {
                    ...initialVenue,
                }
            }),
            invalidatesTags: [
                { type: 'Venue', id: "LIST" }
            ]
        }),
        updateVenue: builder.mutation({
            query: initialVenue => ({
                url: '/venues',
                method: 'PATCH',
                body: {
                    ...initialVenue,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Venue', id: arg.id }
            ]
        }),
        deleteVenue: builder.mutation({
            query: ({ id }) => ({
                url: `/venues`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Venue', id: arg.id }
            ]
        }),
        searchVenues: builder.query({
            query: ({ searchStringVenue }) => ({
                url: `/venues/search`,
                method: 'POST',
                body: { searchStringVenue },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),

        }),
        /*
                searchVenues: builder.query({
                    query: (searchStringVenue) => {
                        return {
                            // Returns url with multiple args
                            url: `/addresses/country/${country}/zipcode/${zipcode}`
                            method: 'POST',
                            body: { searchStringVenue },
                            }
                    }
                }),
        */
    }),
})

export const {
    useGetVenuesQuery,
    useAddNewVenueMutation,
    useUpdateVenueMutation,
    useDeleteVenueMutation,
    useSearchVenuesQuery,
} = venuesApiSlice

// returns the query result object
export const selectVenuesResult = venuesApiSlice.endpoints.getVenues.select()

// creates memoized selector
const selectVenuesData = createSelector(
    selectVenuesResult,
    venuesResult => venuesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllVenues,
    selectById: selectVenueById,
    selectIds: selectVenueIds
    // Pass in a selector that returns the venues slice of state
} = venuesAdapter.getSelectors(state => selectVenuesData(state) ?? initialState)