import { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAddNewEventMutation } from "./eventsApiSlice"
import { useSearchVenuesQuery } from "../venues/venuesApiSlice"
import { useSearchTicketsQuery } from "../tickets/ticketsApiSlice"
import { useSearchCustomersQuery } from "../customers/customersApiSlice"
//import EventUploadReponse from "./EventUploadReponse";
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

import axios from 'axios';

// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Center, Flex, HStack, VStack, Container, Divider, Highlight, Spacer, FormControl, FormLabel, FormErrorMessage, Tag, TagLabel, TagCloseButton, Input, InputGroup, InputLeftElement, Select, Switch, Checkbox, Badge, Icon, Image, Button, ButtonGroup, IconButton, Text, useDisclosure } from '@chakra-ui/react'
import { TableContainer, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, AddIcon, MinusIcon, SearchIcon, ChevronDownIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faList, faSave, faCircleXmark, faSquarePlus, faSquareMinus } from "@fortawesome/free-solid-svg-icons"





const NewEventForm = ({ categories, tickets, sources }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewEvent, {
        isLoading: isAddLoading,
        isSuccess: isAddSuccess,
        isError: isAddError,
        error: isError,
    }] = useAddNewEventMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)



    const options_categories = categories.map(categorie => {
        return (
            <option
                key={categorie.id}
                value={categorie.id}
            >
                {categorie.categoryName[lang]}
            </option>
        )
    })

    const options_tickets = tickets.map(ticket => {
        return (
            <option
                key={ticket.id}
                value={ticket.id}
            >
                {ticket.ticketName[lang]}
            </option>
        )
    })

    const options_sources = sources.map(source => {
        return (
            <option
                key={source.id}
                value={source.id}
            >
                {source.sourceName}
            </option>
        )
    })


    // Tab : Event
    const [formFieldsEvent, setFormFieldsEvent] = useState([{ "eventName": "", "eventDateStart": "", "eventHourOpen": "18:00", "eventHourStart": "20:00", "eventCategoryId": "", "eventTourName": "Tour 2024" }])


    // Tab : Pic



    // Tab : Venue
    const [formFieldsVenue, setFormFieldsVenue] = useState([{}])
    const [searchVenues, setSearchVenues] = useState([]);
    const [searchVenuesHighlight, setSearchVenuesHighlight] = useState([]);
    const [searchVenuesResults, setSearchVenuesResult] = useState([]);
    const [searchVenuesCounterKey, setSearchVenuesCounterKey] = useState(0)
    const searchBoxVenues = useDisclosure()

    const {
        data: srcVenuesData,
        isLoading: isSrcVenueLoading,
        isSuccess: isSrcVenueSuccess,
        isError: isSrcVenueError,
        error: SrcVenueError
    } = useSearchVenuesQuery({
        searchStringVenue: searchVenues,
        searchVenuesCounterKey: searchVenuesCounterKey
    })

    const onSearchVenueChanged = (event) => {
        setSearchVenuesCounterKey(searchVenuesCounterKey + 1)
        if (event.target.value.length > 3) {
            setSearchVenuesResult([])
            setSearchVenues(event.target.value)
            setSearchVenuesHighlight(event.target.value.split(' '))
            searchBoxVenues.onOpen()
        } else {
            searchBoxVenues.onClose()
        }
    }

    const selectVenue = (venueId, venueName, venueType, cityName, countryName, marketName) => {
        let data = [...formFieldsVenue];
        data[0].venueId = venueId;
        data[0].venueName = venueName.en;
        data[0].venueType = venueType;
        data[0].cityName = cityName.en;
        data[0].countryName = countryName.en;
        data[0].marketName = marketName.en;
        setFormFieldsVenue(data)
        checkCanSave()
        searchBoxVenues.onClose()
    }

    const searchVenueFocus = useRef()
    //--


    // Tab : Customer
    const [formFieldsCustomers, setFormFieldsCustomers] = useState([])
    const [searchCustomers, setSearchCustomers] = useState([]);
    const [searchCustomersHighlight, setSearchCustomersHighlight] = useState([]);
    const [searchCustomersResults, setSearchCustomersResult] = useState([]);
    const [searchCustomersCounterKey, setSearchCustomersCounterKey] = useState(0)
    const searchBoxCustomers = useDisclosure()

    const {
        data: srcCustomersData,
        isLoading: isSrcCustomerLoading,
        isSuccess: isSrcCustomerSuccess,
        isError: isSrcCustomerError,
        error: SrcCustomerError
    } = useSearchCustomersQuery({
        searchStringCustomer: searchCustomers,
        searchCustomersCounterKey: searchCustomersCounterKey
    })

    const onSearchCustomerChanged = (event) => {
        setSearchCustomersCounterKey(searchCustomersCounterKey + 1)
        if (event.target.value.length > 3) {
            setSearchCustomersResult([])
            setSearchCustomers(event.target.value)
            setSearchCustomersHighlight(event.target.value.split(' '))
            searchBoxCustomers.onOpen()
        } else {
            searchBoxCustomers.onClose()
        }
    }

    const selectCustomer = (customerId, customerName, index) => {
        let object = {
            customerId: customerId,
            customerName: customerName.en
        }
        setFormFieldsCustomers([...formFieldsCustomers, object])

        /*
                let data = [...formFieldsCustomers];
                data[index].customerId = customerId;
                data[index].customerName = customerName.en;
                setFormFieldsCustomers(data)
        */
        checkCanSave()
        searchBoxCustomers.onClose()
    }

    const unselectCustomer = (index) => {
        let data = [...formFieldsCustomers];
        data.splice(index, 1)
        setFormFieldsCustomers(data)
        checkCanSave()
    }

    const searchCustomerFocus = useRef()
    //--


    // Tab : Ticket
    const [formFieldsTickets, setFormFieldsTickets] = useState([{ 'ticketCategory': '', 'ticketPricefacial': '', 'ticketPricefee1': '0', 'ticketPricefee2': '0', 'ticketDevice': 'EUR', 'ticketType': '', 'ticketFeaturesPremium': [], 'ticketFeaturesAvantage': [], 'ticketFeaturesRestriction': [] }])

    //--


    // Tab : Map
    const [formFieldsMaps, setFormFieldsMaps] = useState([{ staticMap: '', dynamicMap: '', heatMap: '' }])

    //--


    // Tab : Source
    const [formFieldsSources, setFormFieldsSources] = useState([{}])

    //--


    // Tab : Cal
    const [formFieldsCals, setFormFieldsCals] = useState([{}])

    //--




    useEffect(() => {
        checkCanSave()
        if (isAddSuccess) {
            navigate('/dash/events')
        }

        if (isSrcVenueSuccess) {
            setSearchVenuesResult([])
        }
        if (isSrcVenueSuccess && srcVenuesData != []) {
            setSearchVenuesResult(srcVenuesData)
        } else {
            setSearchVenuesResult([])
        }

        if (isSrcCustomerSuccess) {
            setSearchCustomersResult([])
        }
        if (isSrcCustomerSuccess && srcCustomersData != []) {
            setSearchCustomersResult(srcCustomersData)
        } else {
            setSearchCustomersResult([])
        }

    }, [isAddSuccess, isSrcVenueSuccess, isSrcCustomerSuccess, navigate])

    const onPageBack = () => navigate('/dash/events')

    const checkCanSave = () => {
        {
            setCanSave(true)
            formFieldsEvent.map((form) => {
                if (!isAddLoading && (form.eventName === '' || form.eventDateStart === '' || form.hourOpen === '' || form.hourStart === '' || form.eventCategoryId === '' || form.tourName === '')) {
                    setCanSave(false)
                }
            })
            formFieldsVenue.map((form) => {
                if (!isAddLoading && (form.venueId === '')) {
                    setCanSave(false)
                }
            })
            formFieldsCustomers.map((form) => {
                if (!isAddLoading && (form.customerId === '')) {
                    setCanSave(false)
                }
            })

        }
    }

    const onSaveEventClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            //            let eventNameByLang = {}
            //            formFieldsEvent.forEach(field => {
            //                eventNameByLang[field.lang] = field.eventName
            //            })
            output['event'] = formFieldsEvent[0]
            output['venue'] = formFieldsVenue[0]
            output['customer'] = formFieldsCustomers
            output['ticket'] = formFieldsTickets
            output['map'] = formFieldsMaps[0]
            output['source'] = formFieldsSources
            output['cal'] = formFieldsCals

            console.log(output)

            await addNewEvent(output)
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"

    const handleFormChange = (event, index) => {
        let data = [...formFieldsEvent];
        data[index][event.target.name] = event.target.value;
        setFormFieldsEvent(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            eventName: ''
        }
        setFormFieldsEvent([...formFieldsEvent, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsEvent];
        data.splice(index, 1)
        setFormFieldsEvent(data)
        checkCanSave()
    }


    const ticketFormChange = (event, index) => {
        let data = [...formFieldsTickets];
        data[index][event.target.name] = event.target.value;
        setFormFieldsTickets(data);
        checkCanSave()
    }
    const addFieldsTicket = () => {
        let object = { 'ticketCategory': '', 'ticketPricefacial': '', 'ticketPricefee1': '0', 'ticketPricefee2': '0', 'ticketDevice': 'EUR', 'ticketType': '', 'ticketFeaturesPremium': [], 'ticketFeaturesAvantage': [], 'ticketFeaturesRestriction': [] }
        setFormFieldsTickets([...formFieldsTickets, object])
        setCanSave(false)
    }
    const removeFieldsTicket = (index) => {
        let data = [...formFieldsTickets];
        data.splice(index, 1)
        setFormFieldsTickets(data)
        checkCanSave()
    }


    const mapFormChange = (event) => {
        let data = [...formFieldsMaps];
        data[0][event.target.name] = event.target.value;
        setFormFieldsMaps(data);
        checkCanSave()
    }


    const sourceFormChange = (event, index) => {
        let data = [...formFieldsSources];
        data[index][event.target.name] = event.target.value;
        setFormFieldsSources(data);
        checkCanSave()
    }
    const addFieldsSource = () => {
        let object = {}
        setFormFieldsSources([...formFieldsSources, object])
        setCanSave(false)
    }
    const removeFieldsSource = (index) => {
        let data = [...formFieldsSources];
        data.splice(index, 1)
        setFormFieldsSources(data)
        checkCanSave()
    }


    const calFormChange = (event, index) => {
        let data = [...formFieldsCals];
        data[index][event.target.name] = event.target.value;
        setFormFieldsCals(data);
        checkCanSave()
    }
    const addFieldsCal = () => {
        let object = {}
        setFormFieldsCals([...formFieldsCals, object])
        setCanSave(false)
    }
    const removeFieldsCal = (index) => {
        let data = [...formFieldsCals];
        data.splice(index, 1)
        setFormFieldsCals(data)
        checkCanSave()
    }

    const [selectedOptions, setSelectedOptions] = useState([]);

    const ticketFeaturePremiumSelect = (event, index, selected) => {

        /*
                alert('selected = ' + selected)
                alert('event.target.name = ' + event)
                alert('index = ' + index)
                const list = Array.isArray(selected)
                    ? selected
                    : [...selectedOptions, selected];
        
                setSelectedOptions(list);
        */
        let data = [...formFieldsTickets];
        data[index]['ticketFeaturesPremium'] = event;
        setFormFieldsTickets(data);


        //        onChange(list);
    };

    const ticketFeatureAvantageSelect = (event, index) => {
        let data = [...formFieldsTickets];
        data[index]['ticketFeaturesAvantage'] = event;
        setFormFieldsTickets(data);
    };

    const ticketFeatureRestrictionSelect = (event, index) => {
        let data = [...formFieldsTickets];
        data[index]['ticketFeaturesRestriction'] = event;
        setFormFieldsTickets(data);
    };

    const handleClear = () => {
        setSelectedOptions([]);
        //        onChange([]);
    };

    const content = (
        <>
            <Center
                bg='#45413C'
                p='0'
                color='white'
                axis='both'
            >
                <Grid
                    w='95vw'
                    h='100vh'
                    maxW='1500'
                    gap={5}
                    p='5'
                    templateColumns='repeat(12, 1fr)'
                >
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white' pb='2'>
                            <Box color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={700}><FontAwesomeIcon icon={faLocationCrosshairs} /> {T.events.NewEvent}</Text>
                            </Box>
                            <Spacer />
                            <Box color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', md: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveEventClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', md: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Box>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit=''>

                                <Tabs variant='soft-rounded' colorScheme='teal' isFitted>
                                    <TabList
                                        overflowX="auto"
                                        css={css({
                                            scrollbarWidth: 'none',
                                            '::-webkit-scrollbar': { display: 'none' },
                                            '-webkit-overflow-scrolling': 'touch',
                                            boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                            border: '0 none',
                                        })}
                                    >
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Event</Tab>
                                        <Tab isDisabled _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Picture</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Venue</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Customer</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Ticket</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Map</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Source</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Cal</Tab>
                                    </TabList>

                                    <TabPanels>

                                        {/* Tab EVENT */}
                                        <TabPanel minH={400}>
                                            {formFieldsEvent.map((form, index) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            templateRows='repeat(1, 1fr)'
                                                            templateColumns='repeat(5, 1fr)'
                                                            gap={5}
                                                        >
                                                            <GridItem colSpan={5}>
                                                                <Box>
                                                                    <HStack>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='EventName' mt='5' mb='0'>
                                                                                {T.events.EventName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.eventName ?? checkCanSave()}
                                                                                name='eventName'
                                                                                //placeholder='txt'
                                                                                onChange={event => handleFormChange(event, index)}
                                                                                value={form.eventName}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='lg'
                                                                                fontWeight={700}
                                                                                fontSize='20'
                                                                                bg='yellow.100'
                                                                            />
                                                                        </FormControl>
                                                                    </HStack>

                                                                    <HStack>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='DateStart' mt='5' mb='0'>
                                                                                {T.events.DateStart}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.eventDateStart ?? checkCanSave()}
                                                                                name='eventDateStart'
                                                                                id='eventDateStart'
                                                                                onChange={event => handleFormChange(event, index)}
                                                                                value={form.eventDateStart}
                                                                                type="date"
                                                                                min="1997-01-01"
                                                                                max="2030-12-31"
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                bg='yellow.100'
                                                                            />
                                                                        </FormControl>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='HourOpen' mt='5' mb='0'>
                                                                                {T.events.HourOpen}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.eventHourOpen ?? checkCanSave()}
                                                                                name='eventHourOpen'
                                                                                onChange={event => handleFormChange(event, index)}
                                                                                value={form.eventHourOpen}
                                                                                type="time"
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                bg='yellow.100'
                                                                            />
                                                                        </FormControl>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='HourStart' mt='5' mb='0'>
                                                                                {T.events.HourStart}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.eventHourStart ?? checkCanSave()}
                                                                                name='eventHourStart'
                                                                                onChange={event => handleFormChange(event, index)}
                                                                                value={form.eventHourStart}
                                                                                type="time"
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                bg='yellow.100'
                                                                            />
                                                                        </FormControl>
                                                                    </HStack>

                                                                    <HStack>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='CategoryId' mt='5' mb='0'>
                                                                                {T.events.Category}
                                                                            </FormLabel>
                                                                            <Select
                                                                                isInvalid={!form.eventCategoryId ?? checkCanSave()}
                                                                                name='eventCategoryId'
                                                                                placeholder=' '
                                                                                onChange={event => handleFormChange(event, index)}
                                                                                value={form.eventCategoryId}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                bg='yellow.100'
                                                                            >
                                                                                {options_categories}
                                                                            </Select>
                                                                        </FormControl>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='TourName' mt='5' mb='0'>
                                                                                {T.events.TourName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.eventTourName ?? checkCanSave()}
                                                                                name='eventTourName'                                                                                //placeholder='txt'
                                                                                onChange={event => handleFormChange(event, index)}
                                                                                value={form.eventTourName}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size="md"
                                                                                bg='yellow.100'
                                                                            />
                                                                        </FormControl>
                                                                    </HStack>
                                                                </Box>
                                                            </GridItem>
                                                        </Grid>

                                                    </>
                                                )
                                            })}
                                        </TabPanel>


                                        {/* Tab PIC */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>

                                                        <Tabs variant='soft-rounded' colorScheme='teal'>
                                                        </Tabs>

                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>


                                        {/* Tab VENUE */}
                                        <TabPanel minH={400}>
                                            {formFieldsVenue.map((form, index) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            templateRows='repeat(1, 1fr)'
                                                            templateColumns='repeat(5, 1fr)'
                                                            gap={5}
                                                        >
                                                            <GridItem colSpan={5}>
                                                                <Box>
                                                                    <HStack>
                                                                        <FormControl>

                                                                            <Popover
                                                                                initialFocusRef={searchVenueFocus}
                                                                                returnFocusOnClose={false}
                                                                                isOpen={searchBoxVenues.isOpen}
                                                                                onClose={searchBoxVenues.onClose}
                                                                                placement='bottom'
                                                                                closeOnBlur={false}
                                                                            >
                                                                                <HStack>
                                                                                    <PopoverAnchor>
                                                                                        <InputGroup>
                                                                                            <InputLeftElement pointerEvents='none'>
                                                                                                <SearchIcon color='gray.300' />
                                                                                            </InputLeftElement>
                                                                                            <Input
                                                                                                //isInvalid={!form.searchVenueFocus ?? checkCanSave()}
                                                                                                autoFocus
                                                                                                autoComplete="off"
                                                                                                ref={searchVenueFocus}
                                                                                                name='searchVenue'
                                                                                                //value=''
                                                                                                onChange={(event) => onSearchVenueChanged(event)}
                                                                                                placeholder='search venue...'
                                                                                                focusBorderColor='blue.400'
                                                                                                errorBorderColor='red.300'
                                                                                                size='md'
                                                                                                fontWeight={500}
                                                                                                fontSize='20'
                                                                                                bg='yellow.100'
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </PopoverAnchor>
                                                                                </HStack>

                                                                                <PopoverContent width="95vw" maxWidth="700px" color='black'>
                                                                                    <PopoverArrow />
                                                                                    <PopoverCloseButton />
                                                                                    <PopoverBody>
                                                                                        <Text color='black' fontSize={{ base: "16", sm: "16", md: "16", lg: "16", xl: "20" }} fontWeight={700}>Results</Text>
                                                                                        <Divider />
                                                                                        <>
                                                                                            {searchVenuesResults.map((searchVenuesResult) =>
                                                                                                searchVenuesResults === [] ? (
                                                                                                    <Flex>
                                                                                                        <Box w='50vw' maxWidth='350px'>
                                                                                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={300}>
                                                                                                                No result
                                                                                                            </Text>
                                                                                                        </Box>
                                                                                                    </Flex>
                                                                                                ) : (
                                                                                                    <Flex color='black' key={searchVenuesResult._id} onClick={() => selectVenue(searchVenuesResult._id, searchVenuesResult.venueName, searchVenuesResult.venueType, searchVenuesResult.cityName, searchVenuesResult.countryName, searchVenuesResult.marketName)} cursor={'pointer'} _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}>
                                                                                                        <Box w='50vw' maxWidth='350px'>
                                                                                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={500}>
                                                                                                                <Highlight
                                                                                                                    key={searchVenuesResult._id}
                                                                                                                    query={searchVenuesHighlight}
                                                                                                                    styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                                                                                >
                                                                                                                    {searchVenuesResult.venueName.en}
                                                                                                                </Highlight>
                                                                                                            </Text>
                                                                                                        </Box>
                                                                                                        <Box w='50vw' maxWidth='350px'>
                                                                                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>
                                                                                                                <Highlight
                                                                                                                    key={searchVenuesResult._id}
                                                                                                                    query={searchVenuesHighlight}
                                                                                                                    styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                                                                                >
                                                                                                                    {searchVenuesResult.cityName.en}
                                                                                                                </Highlight>
                                                                                                            </Text>
                                                                                                        </Box>
                                                                                                        <Box w='50vw' maxWidth='350px'>
                                                                                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>
                                                                                                                <Highlight
                                                                                                                    key={searchVenuesResult._id}
                                                                                                                    query={searchVenuesHighlight}
                                                                                                                    styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                                                                                >
                                                                                                                    {searchVenuesResult.countryName.en}
                                                                                                                </Highlight>
                                                                                                            </Text>
                                                                                                        </Box>
                                                                                                    </Flex>
                                                                                                ))}
                                                                                        </>
                                                                                    </PopoverBody>
                                                                                </PopoverContent>
                                                                            </Popover>

                                                                        </FormControl>
                                                                    </HStack>

                                                                    <HStack>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='VenueName' mt='5' mb='0'>
                                                                                {T.events.VenueName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.venueName ?? checkCanSave()}
                                                                                name='venueName'
                                                                                value={form.venueName}
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='lg'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='VenueType' mt='5' mb='0'>
                                                                                {T.events.VenueType}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.venueType ?? checkCanSave()}
                                                                                name='venueType'
                                                                                value={form.venueType}
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='lg'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>
                                                                    </HStack>

                                                                    <HStack>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='CityName' mt='5' mb='0'>
                                                                                {T.events.CityName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.cityName ?? checkCanSave()}
                                                                                name='cityName'
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                value={form.cityName}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>

                                                                        <FormControl>
                                                                            <FormLabel htmlFor='CountryName' mt='5' mb='0'>
                                                                                {T.events.CountryName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.countryName ?? checkCanSave()}
                                                                                name='countryName'
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                value={form.countryName}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>

                                                                        <FormControl>
                                                                            <FormLabel htmlFor='MarketName' mt='5' mb='0'>
                                                                                {T.events.MarketName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!form.marketName ?? checkCanSave()}
                                                                                name='marketName'
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                value={form.marketName}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>
                                                                    </HStack>

                                                                </Box>
                                                            </GridItem>
                                                        </Grid>

                                                    </>
                                                )
                                            })}
                                        </TabPanel>


                                        {/* Tab CUSTOMER */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>
                                                        <HStack>
                                                            <FormControl>

                                                                <Popover
                                                                    initialFocusRef={searchCustomerFocus}
                                                                    returnFocusOnClose={false}
                                                                    isOpen={searchBoxCustomers.isOpen}
                                                                    onClose={searchBoxCustomers.onClose}
                                                                    placement='bottom'
                                                                    closeOnBlur={false}
                                                                >
                                                                    <HStack>
                                                                        <PopoverAnchor>
                                                                            <InputGroup>
                                                                                <InputLeftElement pointerEvents='none'>
                                                                                    <SearchIcon color='gray.300' />
                                                                                </InputLeftElement>
                                                                                <Input
                                                                                    //isInvalid={!formFieldsCustomer.searchCustomerFocus ?? checkCanSave()}
                                                                                    autoFocus
                                                                                    autoComplete="off"
                                                                                    ref={searchCustomerFocus}
                                                                                    name='searchCustomer'
                                                                                    //value=''
                                                                                    onChange={(event) => onSearchCustomerChanged(event)}
                                                                                    placeholder='search customer...'
                                                                                    focusBorderColor='blue.400'
                                                                                    errorBorderColor='red.300'
                                                                                    size='md'
                                                                                    fontWeight={500}
                                                                                    fontSize='20'
                                                                                    bg='yellow.100'
                                                                                />
                                                                            </InputGroup>
                                                                        </PopoverAnchor>
                                                                    </HStack>

                                                                    <PopoverContent width="95vw" maxWidth="700px" color='black'>
                                                                        <PopoverArrow />
                                                                        <PopoverCloseButton />
                                                                        <PopoverBody>
                                                                            <Text color='black' fontSize={{ base: "16", sm: "16", md: "16", lg: "16", xl: "20" }} fontWeight={700}>Results</Text>
                                                                            <Divider />
                                                                            <>
                                                                                {searchCustomersResults.map((searchCustomersResult, index) =>
                                                                                    searchCustomersResults === [] ? (
                                                                                        <Flex>
                                                                                            <Box w='50vw' maxWidth='350px'>
                                                                                                <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={300}>
                                                                                                    No result
                                                                                                </Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    ) : (
                                                                                        <Flex color='black' key={searchCustomersResult._id} onClick={() => selectCustomer(searchCustomersResult._id, searchCustomersResult.customerName, index)} cursor={'pointer'} _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}>
                                                                                            <Box w='50vw' maxWidth='350px'>
                                                                                                <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={500}>
                                                                                                    <Highlight
                                                                                                        key={searchCustomersResult._id}
                                                                                                        query={searchCustomersHighlight}
                                                                                                        styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                                                                    >
                                                                                                        {searchCustomersResult.customerName.en}
                                                                                                    </Highlight>
                                                                                                </Text>
                                                                                            </Box>
                                                                                            <Box w='50vw' maxWidth='350px'>
                                                                                                <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={500}>
                                                                                                    <Highlight
                                                                                                        key={searchCustomersResult._id}
                                                                                                        query={searchCustomersHighlight}
                                                                                                        styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                                                                    >
                                                                                                        {searchCustomersResult.customerType}
                                                                                                    </Highlight>
                                                                                                </Text>
                                                                                            </Box>
                                                                                        </Flex>
                                                                                    )
                                                                                )}
                                                                            </>
                                                                        </PopoverBody>
                                                                    </PopoverContent>
                                                                </Popover>

                                                            </FormControl>
                                                        </HStack>

                                                        <HStack>
                                                            <FormControl>
                                                                <FormLabel htmlFor='CustomerName' mt='5' mb='0'>
                                                                    {T.events.CustomerName}
                                                                </FormLabel>
                                                                <>
                                                                    {formFieldsCustomers.map((formFieldsCustomer, index) => {
                                                                        return (
                                                                            <Tag
                                                                                key={formFieldsCustomer.customerId}
                                                                                onClick={() => unselectCustomer(index)}
                                                                                cursor={'pointer'}
                                                                                _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}
                                                                                size='lg'
                                                                                borderRadius='full'
                                                                                variant='solid'
                                                                                colorScheme='gray'
                                                                                m='1'
                                                                            >
                                                                                <TagLabel>{formFieldsCustomer.customerName}</TagLabel>
                                                                                <TagCloseButton />
                                                                            </Tag>
                                                                        )
                                                                    })}
                                                                </>
                                                            </FormControl>
                                                        </HStack>

                                                    </Box>
                                                </GridItem>

                                            </Grid>


                                        </TabPanel>


                                        {/* Tab TICKET */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>
                                                        <HStack>
                                                            <FormControl>
                                                                <FormLabel htmlFor='ticketCategory' mt='5' mb='0'>
                                                                    {T.events.TicketCategory}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='ticketPriceFacial' mt='5' mb='0'>
                                                                    {T.events.TicketPriceFacial}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='ticketPriceFee1' mt='5' mb='0'>
                                                                    {T.events.TicketPriceFee1}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='ticketPriceFee2' mt='5' mb='0'>
                                                                    {T.events.TicketPriceFee2}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='ticketDevice' mt='5' mb='0'>
                                                                    {T.events.TicketDevice}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='ticketType' mt='5' mb='0'>
                                                                    {T.events.TicketType}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='ticketFeature' mt='5' mb='0'>
                                                                    {T.events.TicketFeature}
                                                                </FormLabel>
                                                            </FormControl>
                                                        </HStack>

                                                        {formFieldsTickets.map((formFieldsTicket, index) => {
                                                            return (
                                                                <HStack mt='2' mb='2'>
                                                                    <FormControl>
                                                                        <Select
                                                                            isInvalid={!formFieldsTicket.ticketCategory ?? checkCanSave()}
                                                                            name='ticketCategory'
                                                                            placeholder=' '
                                                                            onChange={event => ticketFormChange(event, index)}
                                                                            value={formFieldsTicket.ticketCategory}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        >
                                                                            {options_tickets}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <Input
                                                                            isInvalid={!formFieldsTicket.ticketPricefacial ?? checkCanSave()}
                                                                            name='ticketPricefacial'
                                                                            onChange={event => ticketFormChange(event, index)}
                                                                            value={formFieldsTicket.ticketPricefacial}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <Input
                                                                            isInvalid={!formFieldsTicket.ticketPricefee1 ?? checkCanSave()}
                                                                            name='ticketPricefee1'
                                                                            onChange={event => ticketFormChange(event, index)}
                                                                            value={formFieldsTicket.ticketPricefee1}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <Input
                                                                            isInvalid={!formFieldsTicket.ticketPricefee2 ?? checkCanSave()}
                                                                            name='ticketPricefee2'
                                                                            onChange={event => ticketFormChange(event, index)}
                                                                            value={formFieldsTicket.ticketPricefee2}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <Select
                                                                            isInvalid={!formFieldsTicket.ticketDevice ?? checkCanSave()}
                                                                            name='ticketDevice'
                                                                            placeholder=' '
                                                                            onChange={event => ticketFormChange(event, index)}
                                                                            value={formFieldsTicket.ticketDevice}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        >
                                                                            <option value='EUR'>EUR</option>
                                                                            <option value='USD'>USD</option>
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <Select
                                                                            isInvalid={!formFieldsTicket.ticketType ?? checkCanSave()}
                                                                            name='ticketType'
                                                                            placeholder=' '
                                                                            onChange={event => ticketFormChange(event, index)}
                                                                            value={formFieldsTicket.ticketType}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        >
                                                                            <option value='isSeating'>isSeating</option>
                                                                            <option value='isSeatingNumbered'>isSeatingNumbered</option>
                                                                            <option value='isSeatingUnumbered'>isSeatingUnumbered</option>
                                                                            <option value='isStanding'>isStanding</option>
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <HStack>
                                                                            <Input
                                                                                //isInvalid={!formFieldsTicket.ticketOptions ?? checkCanSave()}
                                                                                name='ticketOptions'
                                                                                onChange={event => ticketFormChange(event, index)}
                                                                                value={formFieldsTicket.ticketOptions}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                //size='md'
                                                                                bg='yellow.100'
                                                                                type='hidden'
                                                                            />
                                                                            <Menu
                                                                                closeOnSelect={false}
                                                                                name='ticketOptions2'
                                                                                onChange={event => ticketFormChange(event, index)}
                                                                                fontWeight={400}
                                                                            >
                                                                                <MenuButton
                                                                                    as={Button}
                                                                                    bg='yellow.100'
                                                                                    variant={"solid"}
                                                                                    rightIcon={<Icon as={ChevronDownIcon} />}
                                                                                    //maxW={"3md"}
                                                                                    key={index}
                                                                                >
                                                                                    <Flex align="center" gap="2">
                                                                                        <Text noOfLines={1}></Text>
                                                                                        <Badge bg="gray.600" color="white" key={index}>
                                                                                            {
                                                                                                (formFieldsTicket.ticketFeaturesPremium.length != undefined ? formFieldsTicket.ticketFeaturesPremium.length : 0) + (formFieldsTicket.ticketFeaturesAvantage.length != undefined ? formFieldsTicket.ticketFeaturesAvantage.length : 0) + (formFieldsTicket.ticketFeaturesRestriction.length != undefined ? formFieldsTicket.ticketFeaturesRestriction.length : 0)
                                                                                            }
                                                                                        </Badge>
                                                                                    </Flex>
                                                                                </MenuButton>
                                                                                <MenuList minWidth='240px'>
                                                                                    <MenuOptionGroup
                                                                                        title='PREMIUM'
                                                                                        type='checkbox'
                                                                                        onChange={event => ticketFeaturePremiumSelect(event, index)}
                                                                                        value={formFieldsTicket.ticketFeaturesPremium}
                                                                                    >
                                                                                        <MenuItemOption value='isVip' fontSize='15'>VIP</MenuItemOption>
                                                                                        <MenuItemOption value='isPackage' fontSize='15'>Package</MenuItemOption>
                                                                                    </MenuOptionGroup>
                                                                                    <MenuOptionGroup
                                                                                        title='AVANTAGE'
                                                                                        type='checkbox'
                                                                                        onChange={event => ticketFeatureAvantageSelect(event, index)}
                                                                                    >
                                                                                        <MenuItemOption value='isAvantageEarlyAccess' fontSize='15'>Early access</MenuItemOption>
                                                                                    </MenuOptionGroup>
                                                                                    <MenuOptionGroup
                                                                                        title='RESTRICTION'
                                                                                        type='checkbox'
                                                                                        onChange={event => ticketFeatureRestrictionSelect(event, index)}
                                                                                    >
                                                                                        <MenuItemOption value='isRestrisction18YearsOnly' fontSize='15'>+18 years only</MenuItemOption>
                                                                                        <MenuItemOption value='isRestrisctionChildrenOnly' fontSize='15'>children only</MenuItemOption>
                                                                                    </MenuOptionGroup>
                                                                                </MenuList>
                                                                            </Menu>
                                                                            <IconButton
                                                                                size='xs'
                                                                                name="Remove"
                                                                                title={T.common.Delete}
                                                                                colorScheme='gray'
                                                                                aria-label={T.common.Delete}
                                                                                icon={<MinusIcon />}
                                                                                onClick={() => removeFieldsTicket(index)}
                                                                            />
                                                                        </HStack>
                                                                    </FormControl>
                                                                </HStack>
                                                            )
                                                        })}
                                                        <IconButton
                                                            size='xs'
                                                            title={T.common.Add}
                                                            onClick={addFieldsTicket}
                                                            colorScheme='gray'
                                                            aria-label={T.common.Add}
                                                            icon={<AddIcon />}
                                                        />
                                                    </Box>
                                                </GridItem>
                                            </Grid>

                                        </TabPanel>


                                        {/* Tab MAP */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>



                                                        <Tabs variant='soft-rounded' colorScheme='teal'>
                                                            <TabList
                                                                overflowX="auto"
                                                                css={css({
                                                                    scrollbarWidth: 'none',
                                                                    '::-webkit-scrollbar': { display: 'none' },
                                                                    '-webkit-overflow-scrolling': 'touch',
                                                                    boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                                    border: '0 none',
                                                                })}
                                                            >
                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={600}>Static Map</Tab>
                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={600}>Dynamic Map</Tab>
                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={600}>Heat Map</Tab>
                                                            </TabList>

                                                            <TabPanels>

                                                                // TAB : Static Map
                                                                <TabPanel minH={400}>
                                                                    <FormControl>
                                                                        <Select
                                                                            isInvalid={!formFieldsMaps.staticMap ?? checkCanSave()}
                                                                            name='staticMap'
                                                                            placeholder=' '
                                                                            onChange={event => mapFormChange(event)}
                                                                            value={formFieldsMaps.staticMap}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        >
                                                                            <option value='noMap'>noMap</option>
                                                                        </Select>
                                                                    </FormControl>
                                                                </TabPanel>

                                                                // TAB : Dynamic Map
                                                                <TabPanel minH={400}>
                                                                    <FormControl>
                                                                        <Select
                                                                            isInvalid={!formFieldsMaps.dynamicMap ?? checkCanSave()}
                                                                            name='dynamicMap'
                                                                            placeholder=' '
                                                                            onChange={event => mapFormChange(event)}
                                                                            value={formFieldsMaps.dynamicMap}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        >
                                                                            <option value='noMap'>noMap</option>
                                                                        </Select>
                                                                    </FormControl>
                                                                </TabPanel>

                                                                // TAB : Heat Map
                                                                <TabPanel minH={400}>
                                                                    <FormControl>
                                                                        <Select
                                                                            isInvalid={!formFieldsMaps.heatMap ?? checkCanSave()}
                                                                            name='heatMap'
                                                                            placeholder=' '
                                                                            onChange={event => mapFormChange(event)}
                                                                            value={formFieldsMaps.heatMap}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        >
                                                                            <option value='noMap'>noMap</option>
                                                                        </Select>
                                                                    </FormControl>
                                                                </TabPanel>

                                                            </TabPanels>
                                                        </Tabs>


                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>


                                        {/* Tab SOURCE */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>
                                                        <HStack>
                                                            <FormControl w='500px' maxW='50%'>
                                                                <FormLabel htmlFor='sourceType' mt='5' mb='0'>
                                                                    {T.events.SourceType}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='sourceUrl' mt='5' mb='0'>
                                                                    {T.events.SourceUrl}
                                                                </FormLabel>
                                                            </FormControl>
                                                        </HStack>

                                                        {formFieldsSources.map((formFieldsSource, index) => {
                                                            return (
                                                                <HStack mt='2' mb='2'>
                                                                    <FormControl w='500px' maxW='50%'>
                                                                        <Select
                                                                            isInvalid={!formFieldsSource.sourceTypeId ?? checkCanSave()}
                                                                            name='sourceTypeId'
                                                                            placeholder=' '
                                                                            onChange={event => sourceFormChange(event, index)}
                                                                            value={formFieldsSource.sourceTypeId}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        >
                                                                            {options_sources}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <HStack>
                                                                            <Input
                                                                                isInvalid={!formFieldsSource.sourceUrl ?? checkCanSave()}
                                                                                name='sourceUrl'
                                                                                onChange={event => sourceFormChange(event, index)}
                                                                                value={formFieldsSource.sourceUrl}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                bg='yellow.100'
                                                                            />
                                                                            <IconButton
                                                                                size='xs'
                                                                                name="Remove"
                                                                                title={T.common.Delete}
                                                                                colorScheme='gray'
                                                                                aria-label={T.common.Delete}
                                                                                icon={<MinusIcon />}
                                                                                onClick={() => removeFieldsSource(index)}
                                                                            />
                                                                        </HStack>
                                                                    </FormControl>
                                                                </HStack>
                                                            )
                                                        })}
                                                        <IconButton
                                                            size='xs'
                                                            title={T.common.Add}
                                                            onClick={addFieldsSource}
                                                            colorScheme='gray'
                                                            aria-label={T.common.Add}
                                                            icon={<AddIcon />}
                                                        />
                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>


                                        {/* Tab CAL */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>
                                                        <HStack>
                                                            <FormControl w='500px' maxW='50%'>
                                                                <FormLabel htmlFor='calType' mt='5' mb='0'>
                                                                    {T.events.CalType}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='calDateOpen' mt='5' mb='0'>
                                                                    {T.events.CalDateOpen}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='calDateClose' mt='5' mb='0'>
                                                                    {T.events.CalDateClose}
                                                                </FormLabel>
                                                            </FormControl>
                                                        </HStack>

                                                        {formFieldsCals.map((formFieldsCal, index) => {
                                                            return (
                                                                <HStack mt='2' mb='2'>
                                                                    <FormControl w='500px' maxW='50%'>
                                                                        <Select
                                                                            isInvalid={!formFieldsCal.calType ?? checkCanSave()}
                                                                            name='calType'
                                                                            placeholder=' '
                                                                            onChange={event => calFormChange(event, index)}
                                                                            value={formFieldsCal.calType}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        >
                                                                            <option value='isSale'>isSale</option>
                                                                            <option value='isPreSale'>isPreSale</option>
                                                                            <option value='isReSale'>isReSale</option>
                                                                            <option value='isEvent'>isEvent</option>
                                                                        </Select>
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <Input
                                                                            isInvalid={!formFieldsCal.calDateOpen ?? checkCanSave()}
                                                                            name='calDateOpen'
                                                                            onChange={event => calFormChange(event, index)}
                                                                            value={formFieldsCal.calDateOpen}
                                                                            type="datetime-local"
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='md'
                                                                            bg='yellow.100'
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl>
                                                                        <HStack>
                                                                            <Input
                                                                                //isInvalid={!formFieldsCal.calDateClose ?? checkCanSave()}
                                                                                name='calDateClose'
                                                                                onChange={event => calFormChange(event, index)}
                                                                                value={formFieldsCal.calDateClose}
                                                                                type="datetime-local"
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                bg='yellow.100'
                                                                            />
                                                                            <IconButton
                                                                                size='xs'
                                                                                name="Remove"
                                                                                title={T.common.Delete}
                                                                                colorScheme='gray'
                                                                                aria-label={T.common.Delete}
                                                                                icon={<MinusIcon />}
                                                                                onClick={() => removeFieldsCal(index)}
                                                                            />
                                                                        </HStack>
                                                                    </FormControl>
                                                                </HStack>
                                                            )
                                                        })}
                                                        <IconButton
                                                            size='xs'
                                                            title={T.common.Add}
                                                            onClick={addFieldsCal}
                                                            colorScheme='gray'
                                                            aria-label={T.common.Add}
                                                            icon={<AddIcon />}
                                                        />
                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>


                                    </TabPanels>
                                </Tabs >


                            </form >
                        </Box >

                    </GridItem >

                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">
                            formFieldsEvent = {JSON.stringify(formFieldsEvent)}<br /><br />
                            formFieldsVenue = {JSON.stringify(formFieldsVenue)}<br /><br />
                            formFieldsCustomers = {JSON.stringify(formFieldsCustomers)}<br /><br />
                            formFieldsTickets = {JSON.stringify(formFieldsTickets)}<br /><br />
                            formFieldsMaps = {JSON.stringify(formFieldsMaps)}<br /><br />
                            formFieldsSources = {JSON.stringify(formFieldsSources)}<br /><br />
                            formFieldsCals = {JSON.stringify(formFieldsCals)}<br /><br />
                            selectedOptions = {JSON.stringify(selectedOptions)}<br /><br />
                        </Box>
                    </GridItem>

                </Grid >
            </Center >

        </>
    )

    return content
}

export default NewEventForm