import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetMapsQuery } from './mapsApiSlice'
import { memo } from 'react'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Icon, IconButton, Tr, Td, Tooltip } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons'

const Map = ({ mapId }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const { map } = useGetMapsQuery("mapsList", {
        selectFromResult: ({ data }) => ({
            map: data?.entities[mapId]
        }),
    })

    const navigate = useNavigate()

    if (map) {

        const handleEdit = () => navigate(`/dash/maps/${mapId}`)

        return (
            <Tr _hover={{ color: '#333333', border: '2px', borderColor: 'teal.600' }}>
                <Td>
                    {map.mapStatus
                        ? <Icon as={CheckIcon} w={4} h={4} color='green.500' title={T.common.Actived} />
                        : <Icon as={CloseIcon} w={4} h={4} color='red.500' title={T.common.Disabled} />
                    }
                </Td>

                <Td>
                    <strong>{map.mapName}</strong>
                </Td>

                <Td>
                    {map.venueName[lang]}
                </Td>

                <Td isNumeric>
                    <Tooltip hasArrow label={T.common.Edit} bg='gray.300' closeOnClick={true} color='black' placement='left'>
                        <IconButton
                            colorScheme='gray'
                            aria-label='New'
                            icon={<EditIcon />}
                            onClick={handleEdit}
                            _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}
                        />
                    </Tooltip>
                </Td>
            </Tr>
        )

    } else return null
}

const memoizedMap = memo(Map)

export default memoizedMap