import { useParams } from 'react-router-dom'
import EditMapForm from './EditMapForm'
import { useGetMapsQuery } from './mapsApiSlice'
import { useGetVenuesQuery } from '../venues/venuesApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditMap = () => {
    useTitle('ORACLY: Edit Map')

    const { id } = useParams()

    const { marketname, isManager, isAdmin } = useAuth()

    const { map } = useGetMapsQuery("mapsList", {
        selectFromResult: ({ data }) => ({
            map: data?.entities[id]
        }),
    })

    const { venue } = useGetVenuesQuery("venuesList", {
        selectFromResult: ({ data }) => ({
            venue: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!map || !venue?.length) return <PulseLoader color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (map.marketname !== marketname) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditMapForm map={map} venue={venue} />

    return content
}
export default EditMap