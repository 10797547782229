import { useParams } from 'react-router-dom'
import EditTicketForm from './EditTicketForm'
import { useGetTicketsQuery } from './ticketsApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditTicket = () => {
    useTitle('ORACLY: Edit Ticket')

    const { id } = useParams()

    const { countryname, isManager, isAdmin } = useAuth()

    const { Ticket } = useGetTicketsQuery("ticketsList", {
        selectFromResult: ({ data }) => ({
            Ticket: data?.entities[id]
        }),
    })

    if (!Ticket) return <PulseLoader color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (Ticket.countryname !== countryname) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditTicketForm Ticket={Ticket} />

    return content
}
export default EditTicket