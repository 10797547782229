import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetEventsQuery } from "./eventsApiSlice";
import { memo } from "react";
import useLang from "../../hooks/useLang";
import useTranslate from "../../hooks/useTranslate";

// import `ChakraProvider` component
import {
	Icon,
	IconButton,
	Tr,
	Td,
	Text,
	Button,
	Tooltip,
	HStack,
	Image,
} from "@chakra-ui/react";
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	PopoverAnchor,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";

const Event = ({ eventId, event }) => {
	const { lang } = useLang();
	const { T } = useTranslate(lang);

	//    const { event } = useGetEventsQuery("eventsList", {
	//        selectFromResult: ({ data }) => ({
	//            event: data?.entities[eventId]
	//        }),
	//    })

	const navigate = useNavigate();

	if (event) {
		const handleEdit = () => navigate(`/dash/events/${eventId}`);

		var tableauInitial = [
			{ key: "a", value: 1 },
			{ key: "b", value: 2 },
			{ key: "c", value: 3 },
		];

		// Transformer le tableau en objet
		var objetResultant = tableauInitial.reduce(function (obj, item) {
			obj[item.key] = item.value;
			return obj;
		}, {});

		return (
			<Tr _hover={{ color: "#333333", border: "2px", borderColor: "teal.600" }}>
				<Td>
					{event.eventStatus ? (
						<Icon
							as={CheckIcon}
							w={4}
							h={4}
							color="green.500"
							title={T.common.Actived}
						/>
					) : (
						<Icon
							as={CloseIcon}
							w={4}
							h={4}
							color="red.500"
							title={T.common.Disabled}
						/>
					)}
				</Td>

				<Td isNumeric>
					<Tooltip
						hasArrow
						label={T.common.Edit}
						bg="gray.300"
						closeOnClick={true}
						color="black"
						placement="left"
					>
						<IconButton
							colorScheme="gray"
							aria-label="New"
							icon={<EditIcon />}
							onClick={handleEdit}
							_hover={{
								bg: "teal.600",
								color: "white",
								borderColor: "teal.600",
							}}
						/>
					</Tooltip>
				</Td>

				<Td>
					<HStack>
						{event.pic ? (
							<Image
								src={`https://api.oracly.svppb.com/images/${event.pic}/display`}
								maxW="60px"
							/>
						) : (
							<Image src="" h="50" />
						)}
						<div>
							<Text fontSize={22} fontWeight={700}>
								{event.event.eventName}
							</Text>
							<Text fontSize={14} fontWeight={400}>
								{event.event.eventTourName}
							</Text>
							<Text fontSize={16} fontWeight={500}>
								{event.event.eventDateStart}, {event.event.eventHourStart}
							</Text>
						</div>
					</HStack>
				</Td>

				<Td>
					<Text fontSize={18} fontWeight={700}>
						{event.venue.venueName}
					</Text>
					<Text fontSize={14} fontWeight={400}>
						{event.venue.cityName}
					</Text>
					<Text fontSize={14} fontWeight={400}>
						{event.venue.countryName}
					</Text>
				</Td>

				<Td>
					<Popover>
						<PopoverTrigger>
							<Button m="0" p="0">
								{event.ticket.length}
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Tickets</PopoverHeader>
							<PopoverBody>
								{event.ticket.map((ticket, index) => (
									<li key={index}>
										{ticket.ticketPricefacial}
										{ticket.ticketDevice}
									</li>
								))}
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</Td>

				<Td>
					<Popover>
						<PopoverTrigger>
							<Button m="0" p="0">
								...
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Maps</PopoverHeader>
							<PopoverBody>
								<li>
									<strong>staticMap :</strong> {event.map.staticMap}
								</li>
								<li>
									<strong>dynamicMap :</strong> {event.map.dynamicMap}
								</li>
								<li>
									<strong>heatMap :</strong> {event.map.heatMap}
								</li>
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</Td>

				<Td>
					<Popover>
						<PopoverTrigger>
							<Button m="0" p="0">
								{event.source.length}
							</Button>
						</PopoverTrigger>
						<PopoverContent>
							<PopoverArrow />
							<PopoverCloseButton />
							<PopoverHeader>Sources</PopoverHeader>
							<PopoverBody>
								{event.source.map((source, index) => (
									<li key={index}>{source.sourceTypeId.sourceName}</li>
								))}
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</Td>

				<Td>{event.cal.length}</Td>
			</Tr>
		);
	} else return null;
};

const memoizedEvent = memo(Event);

export default memoizedEvent;
