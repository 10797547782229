import { useParams } from 'react-router-dom'
import EditCountryForm from './EditCountryForm'
import { useGetCountriesQuery } from './countriesApiSlice'
import { useGetMarketsQuery } from '../markets/marketsApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditCountry = () => {
    useTitle('ORACLY: Edit Country')

    const { id } = useParams()

    const { marketname, isManager, isAdmin } = useAuth()

    const { country } = useGetCountriesQuery("countriesList", {
        selectFromResult: ({ data }) => ({
            country: data?.entities[id]
        }),
    })

    const { markets } = useGetMarketsQuery("marketsList", {
        selectFromResult: ({ data }) => ({
            markets: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!country || !markets?.length) return <PulseLoader color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (country.marketName !== marketname) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditCountryForm country={country} markets={markets} />

    return content
}
export default EditCountry