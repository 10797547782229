import { useState, useEffect } from "react"
import { useUpdateMarketMutation, useDeleteMarketMutation } from "./marketsApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Tag, Input, Switch, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from "@fortawesome/free-solid-svg-icons"

const EditMarketForm = ({ market, users }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [updateMarket, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateMarketMutation()

    const [deleteMarket, {
        data: delData,
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delError
    }] = useDeleteMarketMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const [marketStatus, setMarketStatus] = useState(market.marketStatus)

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            formFieldsMarketNames.map((form) => {
                if (!isLoading && (form.lang === '' || form.marketName === '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/markets')

    const onStatusChanged = e => setMarketStatus(prev => !prev)

    const onSaveMarketClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            let marketNameByLang = {}
            formFieldsMarketNames.forEach(field => {
                marketNameByLang[field.lang] = field.marketName
            })
            output['marketName'] = marketNameByLang
            console.log(output)

            await updateMarket({ id: market.id, marketName: marketNameByLang, marketStatus: marketStatus })
        }
    }

    const onDeleteMarketClicked = async () => {
        await deleteMarket({ id: market.id })
    }

    const created = new Date(market.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(market.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <Button size='xs' onClick={onDeleteMarketClicked} name="Delete" title={T.common.Delete} leftIcon={<CloseIcon />} colorScheme='red' variant='outline'>{T.markets.DeleteThisMarket}</Button>
        )
    }

    const initMarketNamesForm = () => {
        let marketNameByLang = []
        Object.entries(market.marketName).map(item => {
            marketNameByLang.push({ lang: item[0], marketName: item[1] })
        })
        return marketNameByLang
    }

    const [formFieldsMarketNames, setFormFields] = useState(initMarketNamesForm)

    const handleFormChange = (event, index) => {
        let data = [...formFieldsMarketNames];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            marketName: ''
        }
        setFormFields([...formFieldsMarketNames, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsMarketNames];
        data.splice(index, 1)
        setFormFields(data)
        checkCanSave()
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'MARKET : ' + data?.message + ' successfully', status: 'success' })
            initMarketNamesForm()
            navigate('/dash/markets')
        }
        if (isError) {
            toastInfo({ title: 'MARKET : Update error (' + error?.data?.message + ')', status: 'error' })
        }
        if (isDelSuccess) {
            toastInfo({ title: 'MARKET : ' + delData?.message + ' successfully', status: 'success' })
            initMarketNamesForm()
            navigate('/dash/markets')
        }
        if (isDelError) {
            toastInfo({ title: 'MARKET : Update error (' + delError?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, initMarketNamesForm, toastInfo, isError, error, isDelError, delError, isSuccess, isDelSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' maxW='1500' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white' mb='5'>
                            <Container color='262626' textAlign={['left']} >
                                <HStack>
                                    <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faGlobe} /> {T.markets.EditMarket} : </Text>
                                    <Tag fontSize={{ base: "19", sm: "19", md: "19", lg: "19", xl: "23" }} fontWeight={600}>{formFieldsMarketNames[0].marketName}</Tag>
                                </HStack>
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveMarketClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={e => e.preventDefault()}>
                                <FormControl>

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.markets.MarketName}
                                    </FormLabel>
                                    {formFieldsMarketNames.map((form, index) => {
                                        return (
                                            <HStack key={index}>
                                                <Input
                                                    isInvalid={!form.lang}
                                                    name='lang'
                                                    placeholder={T.markets.IsoCode}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.lang}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    minLength="2"
                                                    maxLength="2"
                                                    bg='yellow.100'
                                                    w='10vw'
                                                />
                                                <Input
                                                    isInvalid={!form.marketName}
                                                    name='marketName'
                                                    placeholder={T.markets.TranslatedName}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.marketName}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    w='50vw'
                                                />
                                                {index !== 0 &&
                                                    <IconButton
                                                        size='xs'
                                                        name="Remove"
                                                        title={T.common.Delete}
                                                        colorScheme='gray'
                                                        aria-label={T.common.Delete}
                                                        icon={<MinusIcon />}
                                                        onClick={() => removeFields(index)}
                                                    />
                                                }
                                            </HStack>
                                        )
                                    })
                                    }
                                    <IconButton
                                        size='xs'
                                        title={T.common.Add}
                                        onClick={addFields}
                                        colorScheme='gray'
                                        aria-label={T.common.Add}
                                        icon={<AddIcon />}
                                    />

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.markets.Status}
                                    </FormLabel>
                                    <Switch
                                        id='market-status'
                                        name="marketStatus"
                                        colorScheme='green'
                                        isChecked={marketStatus}
                                        onChange={onStatusChanged}
                                    />

                                </FormControl>
                                <br />

                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Created}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{created}</Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Updated}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{updated}</Text>
                                </HStack>

                            </form>

                            <br />
                            <br />
                            {deleteButton}

                        </Box>
                    </GridItem>

                </Grid>
            </Center>
        </>
    )

    return content
}

export default EditMarketForm