import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewCustomerMutation } from "./customersApiSlice"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Select, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from "@fortawesome/free-solid-svg-icons"

const NewCustomerForm = () => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewCustomer, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCustomerMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const customerTypes = [{ name: 'isSinger' }, { name: 'isGroupMusic' }, { name: 'isArtist' }, { name: 'isTeamSport' }]
    const options_customerType = customerTypes.map(type => {
        return (
            <option
                key={type.name}
                value={type.name}
            > {type.name}</option >
        )
    })

    const customerGenres = [{ name: 'music_Pop' }, { name: 'music_Rap' }, { name: 'sport_Football' }, { name: 'sport_Basketball' }]
    const options_customerGenre = customerGenres.map(genre => {
        return (
            <option
                key={genre.name}
                value={genre.name}
            > {genre.name}</option >
        )
    })

    const [customerType, setCustomerType] = useState(customerTypes[0].name)
    const [customerGenre, setCustomerGenre] = useState(customerGenres[0].name)

    const [formFieldsCustomerName, setFormFields] = useState([
        { lang: 'en', customerName: '' }, { lang: 'fr', customerName: '' },
    ])

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            formFieldsCustomerName.map((form) => {
                if (!isLoading && (form.lang === '' || form.customerName === '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/customers')

    const onCustomerTypeChanged = e => setCustomerType(e.target.value)
    const onCustomerGenreChanged = e => setCustomerGenre(e.target.value)

    const onSaveCustomerClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            let customerNameByLang = {}
            formFieldsCustomerName.forEach(field => {
                customerNameByLang[field.lang] = field.customerName
            })
            output['customerName'] = customerNameByLang
            console.log(output)

            await addNewCustomer({ customerName: customerNameByLang, customerType, customerGenre })
        }

    }

    const handleFormChange = (event, index) => {
        let data = [...formFieldsCustomerName];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            customerName: ''
        }
        setFormFields([...formFieldsCustomerName, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsCustomerName];
        data.splice(index, 1)
        setFormFields(data)
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'CUSTOMER : Add successfully', status: 'success' })
            navigate('/dash/customers')
        }
        if (isError) {
            toastInfo({ title: 'CUSTOMER : Add error (' + error?.data?.message + ')', status: 'error' })
        }
    }, [isError, error, isSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faUsers} /> {T.customers.NewCustomer}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveCustomerClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={onSaveCustomerClicked}>
                                <FormControl>

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.customers.CustomerName}
                                    </FormLabel>
                                    {formFieldsCustomerName.map((form, index) => {
                                        return (
                                            <HStack key={index}>
                                                <Input
                                                    isInvalid={!form.lang}
                                                    name='lang'
                                                    placeholder={T.customers.IsoCode}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.lang}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    minLength="2"
                                                    maxLength="2"
                                                    bg='yellow.100'
                                                    w='10vw'
                                                />
                                                <Input
                                                    isInvalid={!form.customerName}
                                                    name='customerName'
                                                    placeholder={T.customers.TranslatedName}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.customerName}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    w='50vw'
                                                />
                                                {index !== 0 &&
                                                    <IconButton
                                                        size='xs'
                                                        name="Remove"
                                                        title={T.common.Delete}
                                                        colorScheme='gray'
                                                        aria-label={T.common.Delete}
                                                        icon={<MinusIcon />}
                                                        onClick={() => removeFields(index)}
                                                    />
                                                }
                                            </HStack>
                                        )
                                    })}
                                    <IconButton
                                        size='xs'
                                        title={T.common.Add}
                                        onClick={addFields}
                                        colorScheme='gray'
                                        aria-label={T.common.Add}
                                        icon={<AddIcon />}
                                    />

                                    <FormLabel htmlFor='customerType' mt='5' mb='0'>
                                        {T.customers.CustomerType}
                                    </FormLabel>
                                    <Select
                                        isInvalid={!customerType}
                                        id="customerType"
                                        name="customerType"
                                        value={customerType}
                                        onChange={onCustomerTypeChanged}
                                        bg='yellow.100'
                                    >
                                        {options_customerType}
                                    </Select>

                                    <FormLabel htmlFor='customerGenre' mt='5' mb='0'>
                                        {T.customers.CustomerGenre}
                                    </FormLabel>
                                    <Select
                                        isInvalid={!customerGenre}
                                        id="customerGenre"
                                        name="customerGenre"
                                        value={customerGenre}
                                        onChange={onCustomerGenreChanged}
                                        bg='yellow.100'
                                    >
                                        {options_customerGenre}
                                    </Select>

                                </FormControl>
                            </form>

                        </Box>
                    </GridItem>
                </Grid>
            </Center>
        </>
    )

    return content
}

export default NewCustomerForm