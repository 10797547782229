import { useParams } from 'react-router-dom'
import EditCategoryForm from './EditCategoryForm'
import { useGetCategoriesQuery } from './categoriesApiSlice'
import { useGetCountriesQuery } from '../countries/countriesApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditCategory = () => {
    useTitle('ORACLY: Edit Category')

    const { id } = useParams()

    const { countryname, isManager, isAdmin } = useAuth()

    const { category } = useGetCategoriesQuery("categoriesList", {
        selectFromResult: ({ data }) => ({
            category: data?.entities[id]
        }),
    })

    const { categories } = useGetCategoriesQuery("categoriesList", {
        selectFromResult: ({ data }) => ({
            categories: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!category || !categories?.length) return <PulseLoader color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (category.countryname !== countryname) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditCategoryForm category={category} parents={categories} />

    return content
}
export default EditCategory