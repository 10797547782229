import { useEffect } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'

import useAuth from '../hooks/useAuth'
import useLang from '../hooks/useLang'
import useTranslate from '../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Flex, Avatar, HStack, Text, IconButton, Button, Menu, MenuGroup, MenuButton, MenuList, MenuItem, MenuDivider, useDisclosure, useColorModeValue, Stack } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { HamburgerIcon, CloseIcon, SettingsIcon } from '@chakra-ui/icons'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faClipboard, faFilePen, faGlobe, faEarthAmericas, faCity, faRing, faDungeon, faUsers, faLink, faTicket, faFolderTree, faToolbox } from "@fortawesome/free-solid-svg-icons"


import { useSendLogoutMutation } from '../features/auth/authApiSlice'

interface Props {
    children: React.ReactNode
}

const Links = ['Mapy', 'Sourcy', 'Crony']

const NavLink = (props: Props) => {
    const { children } = props
    return (
        <Box
            as="a"
            px={2}
            py={1}
            rounded={'md'}
            _hover={{
                textDecoration: 'none',
                bg: useColorModeValue('gray.700', 'gray.200'),
            }}
            href={'#'}
        >
            {children}
        </Box>
    )
}

/*
const logoutButton = (
    <button
        className="icon-button"
        title={T.dash.Logout}
        onClick={sendLogout}
    >
        <FontAwesomeIcon icon={faRightFromBracket} />
    </button>
)
*/




const DashHeader = () => {
    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    useEffect(() => {
        if (isSuccess) navigate('/')
        window.scrollTo(0, 0);
    }, [isSuccess, navigate])

    const errClass = isError ? "errmsg" : "offscreen"


    const content = (
        <>
            <Box bg='#1a202c' px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={4} alignItems={'center'}>
                        <Box>
                            <Link to="/dash">
                                <Text fontSize={{ base: "28", sm: "28", md: "25", lg: "25", xl: "25" }} fontWeight={600}>ORACLY.</Text>
                            </Link>
                        </Box>

                        <Flex display={{ base: "none", md: "block" }}>

                            <Menu>
                                <MenuButton m='1' as={Button} colorScheme='teal' size={'sm'}>
                                    CALY
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='CALENDAR' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/cals/manager">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faClipboard} /> Manage</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                    <MenuDivider />
                                </MenuList>
                            </Menu>

                            <Menu>
                                <MenuButton m='1' as={Button} colorScheme='teal' size={'sm'}>
                                    EVENTY
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='EVENTS' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/events/manager">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faClipboard} /> Manage</MenuItem>
                                        </Link>
                                        <Link to="/dash/events/">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faClipboard} /> List</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                    <MenuDivider />
                                    <MenuGroup title='TOOLS' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/events/finder">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faToolbox} /> Event finder</MenuItem>
                                        </Link>
                                        <Link to="/dash">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faToolbox} /> Tour creator</MenuItem>
                                        </Link>
                                        <Link to="/dash">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faToolbox} /> Compet. creator</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                    <MenuDivider />
                                    <MenuGroup title='ATTRIBUTS' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/markets">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command='0'><FontAwesomeIcon fontSize={20} icon={faGlobe} /> Markets</MenuItem>
                                        </Link>
                                        <Link to="/dash/countries">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command='0'><FontAwesomeIcon fontSize={20} icon={faEarthAmericas} /> Countries</MenuItem>
                                        </Link>
                                        <Link to="/dash/cities">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command='0'><FontAwesomeIcon fontSize={20} icon={faCity} /> Cities</MenuItem>
                                        </Link>
                                        <Link to="/dash/venues">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command='0'><FontAwesomeIcon fontSize={20} icon={faRing} /> Venues</MenuItem>
                                        </Link>
                                        <Link to="/dash/customers">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command='0'><FontAwesomeIcon fontSize={20} icon={faUsers} /> Customers</MenuItem>
                                        </Link>
                                        <Link to="/dash/tickets">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command='0'><FontAwesomeIcon fontSize={20} icon={faTicket} /> Tickets</MenuItem>
                                        </Link>
                                        <Link to="/dash/categories">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command='0'><FontAwesomeIcon fontSize={20} icon={faFolderTree} /> Categories</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                </MenuList>
                            </Menu>

                            <Menu>
                                <MenuButton m='1' as={Button} colorScheme='teal' size={'sm'}>
                                    MAPY
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='MAPS' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/maps/manager">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faClipboard} /> Manage</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                    <MenuDivider />
                                    <MenuGroup title='TOOLS' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/maps/svgdefine">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faToolbox} /> SVG define</MenuItem>
                                        </Link>
                                        <Link to="/dash/maps/heatmap">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faToolbox} /> Heat map</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                    <MenuDivider />
                                    <MenuGroup title='ATTRIBUTS' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/maps">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faDungeon} /> Maps</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                </MenuList>
                            </Menu>

                            <Menu>
                                <MenuButton m='1' as={Button} colorScheme='teal' size={'sm'}>
                                    SOURCY
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='SOURCES' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/sources/manager">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faClipboard} /> Manage</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                    <MenuDivider />
                                    <MenuGroup title='ATTRIBUTS' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/sources">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faLink} /> Sources</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                </MenuList>
                            </Menu>

                            <Menu>
                                <MenuButton m='1' as={Button} colorScheme='teal' size={'sm'}>
                                    CRONY
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='TASKS' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faClipboard} /> Manage</MenuItem>
                                    </MenuGroup>
                                    <MenuDivider />
                                </MenuList>
                            </Menu>

                            <Menu>
                                <MenuButton m='1' as={Button} colorScheme='teal' size={'sm'}>
                                    APY
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='API' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faClipboard} /> Manage</MenuItem>
                                    </MenuGroup>
                                    <MenuDivider />
                                </MenuList>
                            </Menu>

                        </Flex>

                    </HStack>
                    <Flex alignItems={'center'}>

                        {(isManager || isAdmin) &&
                            <Menu>
                                <MenuButton as={Button} variant={'outline'} leftIcon={<SettingsIcon />} mr='2' colorScheme='teal' size={'sm'}>
                                    Admin
                                </MenuButton>
                                <MenuList bg='#DDDDDD'>
                                    <MenuGroup title='SETUP' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '>Section</MenuItem>
                                    </MenuGroup>
                                    <MenuDivider />
                                    <MenuGroup title='MANAGE' fontWeight={700} bg='teal' color='white' m='2' p='1'>
                                        <Link to="/dash/notes">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faFilePen} /> {T.dash.Notes}</MenuItem>
                                        </Link>
                                        <Link to="/dash/users">
                                            <MenuItem _hover={{ bg: '#EEEEEE', fontWeight: '700' }} bg='#DDDDDD' color="black" command=' '><FontAwesomeIcon fontSize={20} icon={faUsers} /> {T.dash.Users}</MenuItem>
                                        </Link>
                                    </MenuGroup>
                                </MenuList>
                            </Menu>
                        }

                        <Menu>
                            <MenuButton
                                as={Button}
                                rounded={'full'}
                                variant={'link'}
                                cursor={'pointer'}
                                minW={0}>
                                <Avatar
                                    size={'sm'}
                                    src={
                                        'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                    }
                                />
                            </MenuButton>
                            <MenuList>
                                <MenuItem>Profile</MenuItem>
                                <MenuItem>Options</MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={sendLogout}>Logout</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <NavLink key={link}>{link}</NavLink>
                            ))}
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </>
    )

    return content
}
export default DashHeader