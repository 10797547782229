import NewCityForm from './NewCityForm'
import { useGetCountriesQuery } from '../countries/countriesApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewCity = () => {
    useTitle('ORACLY: New City')

    const { countries } = useGetCountriesQuery("countriesList", {
        selectFromResult: ({ data }) => ({
            countries: data?.ids.map(id => data?.entities[id], name => data?.entities[name])
        }),
    })

    if (!countries?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewCityForm countries={countries} />

    return content
}
export default NewCity