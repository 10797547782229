import { useParams } from 'react-router-dom'
import EditMarketForm from './EditMarketForm'
import { useGetMarketsQuery } from './marketsApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditMarket = () => {
    useTitle('ORACLY: Edit Market')

    const { id } = useParams()

    const { username, isManager, isAdmin } = useAuth()

    const { market } = useGetMarketsQuery("marketsList", {
        selectFromResult: ({ data }) => ({
            market: data?.entities[id]
        }),
    })

    if (!market) return <PulseLoader color={"#FFF"} />

    if (!isManager && !isAdmin) {
        if (market.username !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditMarketForm market={market} />

    return content
}
export default EditMarket