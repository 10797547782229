import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

// import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'


// Load screen
import { LoadingScreen } from "./screens";


// NAV
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login';
import DashLayout from './components/DashLayout'
import Welcome from './features/auth/Welcome'

// NOTES
import NotesList from './features/notes/NotesList'
import EditNote from './features/notes/EditNote'
import NewNote from './features/notes/NewNote'

// USERS
import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'

// MARKETS
import MarketsList from './features/markets/MarketsList'
import EditMarket from './features/markets/EditMarket'
import NewMarket from './features/markets/NewMarket'

// COUNTRIES
import CountriesList from './features/countries/CountriesList'
import EditCountry from './features/countries/EditCountry'
import NewCountry from './features/countries/NewCountry'

// CITIES
import CitiesList from './features/cities/CitiesList'
import EditCity from './features/cities/EditCity'
import NewCity from './features/cities/NewCity'

// VENUES
import VenuesList from './features/venues/VenuesList'
import EditVenue from './features/venues/EditVenue'
import NewVenue from './features/venues/NewVenue'

// MAPS
import MapsManager from './features/maps/MapsManager'
import MapsList from './features/maps/MapsList'
import EditMap from './features/maps/EditMap'
import NewMap from './features/maps/NewMap'
import MapSvg from './features/maps/MapSvg'

// CUSTOMERS
import CustomersList from './features/customers/CustomersList'
import EditCustomer from './features/customers/EditCustomer'
import NewCustomer from './features/customers/NewCustomer'

// TICKETS
import TicketsList from './features/tickets/TicketsList'
import EditTicket from './features/tickets/EditTicket'
import NewTicket from './features/tickets/NewTicket'

// CATEGORIES
import CategoriesList from './features/categories/CategoriesList'
import EditCategory from './features/categories/EditCategory'
import NewCategory from './features/categories/NewCategory'

// SOURCES
import SourcesManager from './features/sources/SourcesManager'
import SourcesList from './features/sources/SourcesList'
import EditSource from './features/sources/EditSource'
import NewSource from './features/sources/NewSource'

// EVENTS
import EventsManager from './features/events/EventsManager'
import EventsFinder from './features/events/EventsFinder'
import EventsList from './features/events/EventsList'
import EditEvent from './features/events/EditEvent'
import NewEvent from './features/events/NewEvent'

// CALS
import CalsManager from './features/cals/CalsManager'


/*
import CustomersList from './features/customers/CitiesList'
import EditCustomer from './features/customers/EditCustomer'
import NewCustomer from './features/customers/NewCustomer'
*/
import Prefetch from './features/auth/Prefetch'

import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'

import RequireLang from './features/lang/RequireLang'
import { LANGS } from './config/langs'

import useTitle from './hooks/useTitle';

//const lang = "en"

function App() {
    useTitle('ORACLY')

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    return (
        <>
            <ChakraProvider>
                {!loading ? (
                    <Routes>
                        <Route path="/" element={<Layout />}>

                            {/* public routes */}
                            <Route index element={<Public />} />
                            <Route path="login" element={<Login />} />

                            {/* Protected Routes */}
                            <Route element={<PersistLogin />}>
                                <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
                                    <Route element={<RequireLang allowedLangs={[...Object.values(LANGS)]} />}>
                                        <Route element={<Prefetch />}>
                                            <Route path="dash" element={<DashLayout />}>

                                                <Route index element={<Welcome />} />

                                                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                                                    <Route path="users">
                                                        <Route index element={<UsersList />} />
                                                        <Route path=":id" element={<EditUser />} />
                                                        <Route path="new" element={<NewUserForm />} />
                                                    </Route>
                                                </Route>

                                                <Route path="notes">
                                                    <Route index element={<NotesList />} />
                                                    <Route path=":id" element={<EditNote />} />
                                                    <Route path="new" element={<NewNote />} />
                                                </Route>

                                                <Route path="markets">
                                                    <Route index element={<MarketsList />} />
                                                    <Route path=":id" element={<EditMarket />} />
                                                    <Route path="new" element={<NewMarket />} />
                                                </Route>

                                                <Route path="countries">
                                                    <Route index element={<CountriesList />} />
                                                    <Route path=":id" element={<EditCountry />} />
                                                    <Route path="new" element={<NewCountry />} />
                                                </Route>

                                                <Route path="cities">
                                                    <Route index element={<CitiesList />} />
                                                    <Route path=":id" element={<EditCity />} />
                                                    <Route path="new" element={<NewCity />} />
                                                </Route>

                                                <Route path="venues">
                                                    <Route index element={<VenuesList />} />
                                                    <Route path=":id" element={<EditVenue />} />
                                                    <Route path="new" element={<NewVenue />} />
                                                </Route>

                                                <Route path="customers">
                                                    <Route index element={<CustomersList />} />
                                                    <Route path=":id" element={<EditCustomer />} />
                                                    <Route path="new" element={<NewCustomer />} />
                                                </Route>

                                                <Route path="maps">
                                                    <Route index element={<MapsList />} />
                                                    <Route path=":id" element={<EditMap />} />
                                                    <Route path="new" element={<NewMap />} />
                                                    <Route path="manager" element={<MapsManager />} />
                                                    <Route path="mapSvg" element={<MapSvg />} />
                                                </Route>

                                                <Route path="tickets">
                                                    <Route index element={<TicketsList />} />
                                                    <Route path=":id" element={<EditTicket />} />
                                                    <Route path="new" element={<NewTicket />} />
                                                </Route>

                                                <Route path="categories">
                                                    <Route index element={<CategoriesList />} />
                                                    <Route path=":id" element={<EditCategory />} />
                                                    <Route path="new" element={<NewCategory />} />
                                                </Route>

                                                <Route path="sources">
                                                    <Route index element={<SourcesList />} />
                                                    <Route path=":id" element={<EditSource />} />
                                                    <Route path="new" element={<NewSource />} />
                                                    <Route path="manager" element={<SourcesManager />} />
                                                </Route>

                                                <Route path="events">
                                                    <Route index element={<EventsList />} />
                                                    <Route path=":id" element={<EditEvent />} />
                                                    <Route path="new" element={<NewEvent />} />
                                                    <Route path="manager" element={<EventsManager />} />
                                                    <Route path="finder" element={<EventsFinder />} />
                                                </Route>

                                                <Route path="cals">
                                                    <Route path="manager" element={<CalsManager />} />
                                                </Route>

                                            </Route>{/* End Dash */}
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>{/* End Protected Routes */}

                        </Route>
                    </Routes>
                ) : (
                    <LoadingScreen />
                )}
            </ChakraProvider>
        </>
    );
}

export default App;