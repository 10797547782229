import { useEffect } from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Center, HStack, VStack, Container, Badge, Icon, Image, Button, Text, useDisclosure } from '@chakra-ui/react'
import { TableContainer, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faEarthAmericas, faCity, faRing, faDungeon, faUsers, faLink, faTicket } from "@fortawesome/free-solid-svg-icons"


import PulseLoader from 'react-spinners/PulseLoader'


//const DASH_REGEX = /^\/dash(\/)?$/
const DASH_REGEX = /^\/dash(\/)?$/
const USERS_REGEX = /^\/dash\/users.*?$/
const NOTES_REGEX = /^\/dash\/notes.*?$/
const MARKETS_REGEX = /^\/dash\/markets.*?$/
const COUNTRIES_REGEX = /^\/dash\/countries.*?$/
const CITIES_REGEX = /^\/dash\/cities.*?$/
const VENUES_REGEX = /^\/dash\/venues.*?$/
const MAPS_REGEX = /^\/dash\/maps.*?$/
const CUSTOMERS_REGEX = /^\/dash\/customers.*?$/


const Welcome = () => {

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const date = new Date()
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date)




    const goEventyFindlist = () => navigate('/dash/events/finder')

    const goSources = () => navigate('/dash/sources')
    const goCustomers = () => navigate('/dash/customers')
    const goTickets = () => navigate('/dash/tickets')
    const goMaps = () => navigate('/dash/maps')
    const goVenues = () => navigate('/dash/venues')
    const goCities = () => navigate('/dash/cities')
    const goCountries = () => navigate('/dash/countries')
    const goMarkets = () => navigate('/dash/markets')
    const goNotes = () => navigate('/dash/notes')
    const goUsers = () => navigate('/dash/users')

    let dashClass = null
    if (!DASH_REGEX.test(pathname) && !USERS_REGEX.test(pathname) && !NOTES_REGEX.test(pathname) && !MARKETS_REGEX.test(pathname) && !COUNTRIES_REGEX.test(pathname) && !CITIES_REGEX.test(pathname) && !VENUES_REGEX.test(pathname) && !MAPS_REGEX.test(pathname) && !CUSTOMERS_REGEX.test(pathname)) {
        dashClass = "dash-header__container--small"
    }

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid colSpan={12} w='95vw' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>

                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <Grid templateColumns='repeat(12, 1fr)' gap={2}>
                            <GridItem colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                                    <HStack>
                                        <Container color='262626' textAlign={['left']} >
                                            <Text color='black' fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={600}>Eventy</Text><Text color='black' fontSize={{ base: "12", sm: "12", md: "15", lg: "15", xl: "15" }} fontWeight={400}>LAST ADDED</Text><br />
                                        </Container>
                                        <Container color='262626' textAlign={['right']}>
                                            <Button size='sm' onClick={goEventyFindlist} colorScheme='red' variant='solid'>
                                                <Text display={{ base: 'none', sm: 'flex' }} color='white' fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>Wait</Text><Badge ml='2' fontSize='1em'>8</Badge>
                                            </Button>&nbsp;
                                            <Button size='sm' leftIcon={<AddIcon />} colorScheme='teal' variant='solid'>
                                                <Text display={{ base: 'none', sm: 'flex' }} color='white' fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>Add</Text>
                                            </Button>
                                        </Container>
                                    </HStack>

                                    <Tabs>
                                        <TabList
                                            overflowX="auto"
                                            css={css({
                                                scrollbarWidth: 'none',
                                                '::-webkit-scrollbar': { display: 'none' },
                                                '-webkit-overflow-scrolling': 'touch',
                                                boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                border: '0 none',
                                            })}
                                        >
                                            <Tab>FR</Tab>
                                            <Tab>ES</Tab>
                                            <Tab>PT</Tab>
                                            <Tab>IT</Tab>
                                            <Tab>UK</Tab>
                                            <Tab>DE</Tab>
                                            <Tab>CH</Tab>
                                            <Tab>BE</Tab>
                                        </TabList>

                                        <TabPanels>

                                            <TabPanel>
                                                <TableContainer>
                                                    <Table
                                                        variant='striped'
                                                        size='sm'
                                                    >
                                                        <Thead>
                                                            <Tr>
                                                                <Th>NAME</Th>
                                                                <Th>DATE</Th>
                                                                <Th>VENUE</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td><strong>Lady Gaga</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Coldplay</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Mylène Farmer</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Lady Gaga</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Coldplay</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Mylène Farmer</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Lady Gaga</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Coldplay</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Mylène Farmer</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Mylène Farmer</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Lady Gaga</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Coldplay</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Mylène Farmer</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Lady Gaga</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Coldplay</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Mylène Farmer</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Lady Gaga</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Coldplay</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Mylène Farmer</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Mylène Farmer</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stade de France (FR)</Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </TabPanel>

                                            <TabPanel>
                                                <TableContainer>
                                                    <Table
                                                        variant='striped'
                                                        size='sm'
                                                    >
                                                        <Thead>
                                                            <Tr>
                                                                <Th>NAME</Th>
                                                                <Th>DATE</Th>
                                                                <Th>VENUE</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td><strong>Pablo Sanchez</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stadium Madrid (ES)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Pablo Sanchez</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stadium Madrid (ES)</Td>
                                                            </Tr>
                                                            <Tr>
                                                                <Td><strong>Pablo Sanchez</strong></Td>
                                                                <Td>2024-07-10</Td>
                                                                <Td>Stadium Madrid (ES)</Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </TabPanel>

                                            <TabPanel>
                                                <p>No found !</p>
                                            </TabPanel>

                                        </TabPanels>
                                    </Tabs>

                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "12", sm: "12", md: "6", lg: "6", xl: "6" }}>
                                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                                    <HStack>
                                        <Container color='262626' textAlign={['left']} >
                                            <Text color='black' fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={600}>Mapy</Text><Text color='black' fontSize={{ base: "12", sm: "12", md: "15", lg: "15", xl: "15" }} fontWeight={400}>LAST ADDED</Text><br />
                                        </Container>
                                        <Container color='262626' textAlign={['right']}>
                                            <Button size='sm' leftIcon={<AddIcon />} colorScheme='teal' variant='solid'>
                                                Add
                                            </Button>
                                        </Container>
                                    </HStack>
                                    <TableContainer>
                                        <Table
                                            size='sm'
                                            variant='striped'
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>VENUE</Th>
                                                    <Th>EVENT</Th>
                                                    <Th>DATE</Th>
                                                    <Th isNumeric>FILEMAP</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td><strong>Stade de France</strong></Td>
                                                    <Td>Coldplay</Td>
                                                    <Td>2023-07-12</Td>
                                                    <Td isNumeric>fr_sdf_coldplay_2023</Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "12", sm: "12", md: "6", lg: "6", xl: "6" }}>
                                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                                    <HStack>
                                        <Container color='262626' textAlign={['left']} >
                                            <Text color='black' fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={600}>Sourcy</Text><Text color='black' fontSize={{ base: "12", sm: "12", md: "15", lg: "15", xl: "15" }} fontWeight={400}>LAST ADDED</Text><br />
                                        </Container>
                                        <Container color='262626' textAlign={['right']}>
                                            <Button size='sm' leftIcon={<AddIcon />} colorScheme='teal' variant='solid'>
                                                Add
                                            </Button>
                                        </Container>
                                    </HStack>
                                    <TableContainer>
                                        <Table
                                            size='sm'
                                            variant='striped'
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>To convert</Th>
                                                    <Th>into</Th>
                                                    <Th isNumeric>multiply by</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr>
                                                    <Td>inches</Td>
                                                    <Td>millimetres (mm)</Td>
                                                    <Td isNumeric>25.4</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>feet</Td>
                                                    <Td>centimetres (cm)</Td>
                                                    <Td isNumeric>30.48</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>yards</Td>
                                                    <Td>metres (m)</Td>
                                                    <Td isNumeric>0.91444</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>yards</Td>
                                                    <Td>metres (m)</Td>
                                                    <Td isNumeric>0.91444</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>yards</Td>
                                                    <Td>metres (m)</Td>
                                                    <Td isNumeric>0.91444</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>yards</Td>
                                                    <Td>metres (m)</Td>
                                                    <Td isNumeric>0.91444</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>yards</Td>
                                                    <Td>metres (m)</Td>
                                                    <Td isNumeric>0.91444</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>yards</Td>
                                                    <Td>metres (m)</Td>
                                                    <Td isNumeric>0.91444</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>yards</Td>
                                                    <Td>metres (m)</Td>
                                                    <Td isNumeric>0.91444</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>yards</Td>
                                                    <Td>metres (m)</Td>
                                                    <Td isNumeric>0.91444</Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </GridItem>
                        </Grid>
                        <br />
                        <Grid templateColumns='repeat(10, 1fr)' gap={2}>
                            <GridItem colSpan={{ base: "5", sm: "5", md: "2", lg: "2", xl: "2" }}>
                                <Box onClick={goMarkets} cursor={'pointer'} borderRadius='xl' p={2} fontSize={20} fontWeight={400} textAlign={['center']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} w="100%">
                                    <FontAwesomeIcon color='white' fontSize={30} icon={faGlobe} />
                                    <Text color='white' fontSize={{ base: "18", sm: "18", md: "12", lg: "18", xl: "20" }} fontWeight={600}><Badge mr='1'>2</Badge>{T.dash.Markets}</Text>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "5", sm: "5", md: "2", lg: "2", xl: "2" }}>
                                <Box onClick={goCountries} cursor={'pointer'} borderRadius='xl' p={2} fontSize={20} fontWeight={400} textAlign={['center']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} w="100%">
                                    <FontAwesomeIcon color='white' fontSize={30} icon={faEarthAmericas} />
                                    <Text color='white' fontSize={{ base: "18", sm: "18", md: "12", lg: "18", xl: "20" }} fontWeight={600}><Badge mr='1'>21</Badge>{T.dash.Countries}</Text>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "5", sm: "5", md: "2", lg: "2", xl: "2" }}>
                                <Box onClick={goCities} cursor={'pointer'} borderRadius='xl' p={2} fontSize={20} fontWeight={400} textAlign={['center']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} w="100%">
                                    <FontAwesomeIcon color='white' fontSize={30} icon={faCity} />
                                    <Text color='white' fontSize={{ base: "18", sm: "18", md: "12", lg: "18", xl: "20" }} fontWeight={600}><Badge mr='1'>73</Badge>{T.dash.Cities}</Text>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "5", sm: "5", md: "2", lg: "2", xl: "2" }}>
                                <Box onClick={goVenues} cursor={'pointer'} borderRadius='xl' p={2} fontSize={20} fontWeight={400} textAlign={['center']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} w="100%">
                                    <FontAwesomeIcon color='white' fontSize={30} icon={faRing} />
                                    <Text color='white' fontSize={{ base: "18", sm: "18", md: "12", lg: "18", xl: "20" }} fontWeight={600}><Badge mr='1'>258</Badge>{T.dash.Venues}</Text>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "5", sm: "5", md: "2", lg: "2", xl: "2" }}>
                                <Box onClick={goMaps} cursor={'pointer'} borderRadius='xl' p={2} fontSize={20} fontWeight={400} textAlign={['center']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} w="100%">
                                    <FontAwesomeIcon color='white' fontSize={30} icon={faDungeon} />
                                    <Text color='white' fontSize={{ base: "18", sm: "18", md: "12", lg: "18", xl: "20" }} fontWeight={600}><Badge mr='1'>165</Badge>{T.dash.Maps}</Text>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "5", sm: "5", md: "2", lg: "2", xl: "2" }}>
                                <Box onClick={goTickets} cursor={'pointer'} borderRadius='xl' p={2} fontSize={20} fontWeight={400} textAlign={['center']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} w="100%">
                                    <FontAwesomeIcon color='white' fontSize={30} icon={faTicket} />
                                    <Text color='white' fontSize={{ base: "18", sm: "18", md: "12", lg: "18", xl: "20" }} fontWeight={600}><Badge mr='1'>741</Badge>{T.dash.Tickets}</Text>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "5", sm: "5", md: "2", lg: "2", xl: "2" }}>
                                <Box onClick={goCustomers} cursor={'pointer'} borderRadius='xl' p={2} fontSize={20} fontWeight={400} textAlign={['center']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} w="100%">
                                    <FontAwesomeIcon color='white' fontSize={30} icon={faUsers} />
                                    <Text color='white' fontSize={{ base: "18", sm: "18", md: "12", lg: "18", xl: "20" }} fontWeight={600}><Badge mr='1'>840</Badge>{T.dash.Customers}</Text>
                                </Box>
                            </GridItem>
                            <GridItem colSpan={{ base: "5", sm: "5", md: "2", lg: "2", xl: "2" }}>
                                <Box onClick={goSources} cursor={'pointer'} borderRadius='xl' p={2} fontSize={20} fontWeight={400} textAlign={['center']} color='black' border='10px' borderColor='black' bg='#333333' _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} w="100%">
                                    <FontAwesomeIcon color='white' fontSize={30} icon={faLink} />
                                    <Text color='white' fontSize={{ base: "18", sm: "18", md: "12", lg: "18", xl: "20" }} fontWeight={600}><Badge mr='1'>261</Badge>{T.dash.Sources}</Text>
                                </Box>
                            </GridItem>
                        </Grid>
                    </GridItem>

                </Grid>

            </Center>

            <br />
            <h1>Test</h1>
            <p><Link to="/dash/maps/mapSvg">MapSvg</Link></p>
        </>

    )

    return content
}
export default Welcome