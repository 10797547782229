import { useState, useEffect } from "react"
import { useUpdateCountryMutation, useDeleteCountryMutation } from "./countriesApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Select, Switch, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons"

const EditCountryForm = ({ country, markets }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [updateCountry, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCountryMutation()

    const [deleteCountry, {
        data: delData,
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delError
    }] = useDeleteCountryMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const [countryIso, setCountryIso] = useState(country.countryIso)
    const [marketId, setMarketId] = useState(country.marketId)
    const [countryStatus, setCountryStatus] = useState(country.countryStatus)

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            if ((countryIso == '')) {
                setCanSave(false)
            }
            formFieldsCountryName.map((form) => {
                if (!isLoading && (form.lang == '' || form.countryName == '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/countries')

    const onCountryIsoChanged = e => setCountryIso(e.target.value)
    const onMarketIdChanged = e => setMarketId(e.target.value)
    const onStatusChanged = e => setCountryStatus(prev => !prev)

    const onSaveCountryClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            let countryNameByLang = {}
            formFieldsCountryName.forEach(field => {
                countryNameByLang[field.lang] = field.countryName
            })
            output['countryName'] = countryNameByLang
            console.log(output)

            await updateCountry({ id: country.id, marketId: marketId, countryName: countryNameByLang, countryIso, countryStatus })
        }
    }

    const onDeleteCountryClicked = async () => {
        await deleteCountry({ id: country.id })
    }

    const created = new Date(country.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(country.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = markets.map(market => {
        return (
            <option
                key={market.id}
                value={market.id}
            >{market.marketName[lang]}</option >
        )
    })

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <Button size='xs' onClick={onDeleteCountryClicked} name="Delete" title={T.common.Delete} leftIcon={<CloseIcon />} colorScheme='red' variant='outline'>{T.countries.DeleteThisCountry}</Button>
        )
    }

    const initCountryNamesForm = () => {
        let countryNameByLang = []
        Object.entries(country.countryName).map(item => {
            countryNameByLang.push({ lang: item[0], countryName: item[1] })
        })
        return countryNameByLang
    }

    const [formFieldsCountryName, setFormFields] = useState(initCountryNamesForm)

    const handleFormChange = (event, index) => {
        let data = [...formFieldsCountryName];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            countryName: ''
        }
        setFormFields([...formFieldsCountryName, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsCountryName];
        data.splice(index, 1)
        setFormFields(data)
        checkCanSave()
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'COUNTRY : ' + data?.message + ' successfully', status: 'success' })
            initCountryNamesForm()
            setCountryIso('')
            setMarketId('')
            navigate('/dash/countries')
        }
        if (isError) {
            toastInfo({ title: 'COUNTRY : Update error (' + error?.data?.message + ')', status: 'error' })
        }
        if (isDelSuccess) {
            toastInfo({ title: 'COUNTRY : ' + delData?.message + ' successfully', status: 'success' })
            initCountryNamesForm()
            navigate('/dash/countries')
        }
        if (isDelError) {
            toastInfo({ title: 'COUNTRY : Update error (' + delError?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, initCountryNamesForm, toastInfo, isError, error, isDelError, delError, isSuccess, isDelSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' maxW='1500' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faEarthAmericas} /> {T.countries.EditCountry}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveCountryClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={e => e.preventDefault()}>
                                <FormControl>

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.countries.CountryName}
                                    </FormLabel>
                                    {formFieldsCountryName.map((form, index) => {
                                        return (
                                            <HStack key={index}>
                                                <Input
                                                    isInvalid={!form.lang}
                                                    name='lang'
                                                    placeholder={T.countries.IsoCode}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.lang}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    minLength="2"
                                                    maxLength="2"
                                                    bg='yellow.100'
                                                    w='10vw'
                                                />
                                                <Input
                                                    isInvalid={!form.countryName}
                                                    name='countryName'
                                                    placeholder={T.countries.TranslatedName}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.countryName}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    w='50vw'
                                                />
                                                {index !== 0 &&
                                                    <IconButton
                                                        size='xs'
                                                        name="Remove"
                                                        title={T.common.Delete}
                                                        colorScheme='gray'
                                                        aria-label={T.common.Delete}
                                                        icon={<MinusIcon />}
                                                        onClick={() => removeFields(index)}
                                                    />
                                                }
                                            </HStack>
                                        )
                                    })
                                    }
                                    <IconButton
                                        size='xs'
                                        title={T.common.Add}
                                        onClick={addFields}
                                        colorScheme='gray'
                                        aria-label={T.common.Add}
                                        icon={<AddIcon />}
                                    />

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.countries.CountryISO}
                                    </FormLabel>
                                    <Input
                                        isInvalid={!countryIso}
                                        id="countryIso"
                                        name="countryIso"
                                        placeholder={T.countries.IsoCode}
                                        onChange={onCountryIsoChanged}
                                        value={countryIso}
                                        focusBorderColor='blue.400'
                                        errorBorderColor='red.300'
                                        bg='yellow.100'
                                    />

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.countries.MarketArea}
                                    </FormLabel>
                                    <Select
                                        id="marketId"
                                        name="marketId"
                                        className="form__select"
                                        value={marketId}
                                        onChange={onMarketIdChanged}
                                        bg='yellow.100'
                                    >
                                        {options}
                                    </Select>

                                    <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                        {T.countries.Status}
                                    </FormLabel>
                                    <Switch
                                        id='country-status'
                                        name="status"
                                        colorScheme='green'
                                        isChecked={countryStatus}
                                        onChange={onStatusChanged}
                                    />

                                </FormControl>
                                <br />

                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Created}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{created}</Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Updated}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{updated}</Text>
                                </HStack>
                            </form>

                            <br />
                            <br />
                            {deleteButton}

                        </Box>
                    </GridItem>

                </Grid>
            </Center>
        </>
    )

    return content
}

export default EditCountryForm