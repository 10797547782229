import { useParams } from 'react-router-dom'
import EditCustomerForm from './EditCustomerForm'
import { useGetCustomersQuery } from './customersApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditCustomer = () => {
    useTitle('ORACLY: Edit Customer')

    const { id } = useParams()

    const { username, isManager, isAdmin } = useAuth()

    const { customer } = useGetCustomersQuery("customersList", {
        selectFromResult: ({ data }) => ({
            customer: data?.entities[id]
        }),
    })

    if (!customer) return <PulseLoader color={"#FFF"} />

    if (!isManager && !isAdmin) {
        if (customer.username !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditCustomerForm customer={customer} />

    return content
}
export default EditCustomer