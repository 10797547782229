import NewCategoryForm from './NewCategoryForm'
import { useGetCategoriesQuery } from '../categories/categoriesApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewCategory = () => {
    useTitle('ORACLY: New Category')

    const { categories } = useGetCategoriesQuery("categoriesList", {
        selectFromResult: ({ data }) => ({
            categories: data?.ids.map(id => data?.entities[id], name => data?.entities[name])
        }),
    })

    if (!categories?.length) return <PulseLoader color={"#FFF"} />

    const content = <NewCategoryForm parents={categories} />

    return content
}
export default NewCategory