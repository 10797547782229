import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import EditEventForm from './EditEventForm'
import { useGetEventQuery } from './eventsApiSlice'
import { useGetCategoriesQuery } from '../categories/categoriesApiSlice'
import { useGetTicketsQuery } from '../tickets/ticketsApiSlice'
import { useGetSourcesQuery } from '../sources/sourcesApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'


const EditEvent = () => {
    useTitle('ORACLY: Edit Event')


    // Utilisez useParams pour extraire la valeur des paramètres d'URL
    const { id } = useParams();

    const { username, isManager, isAdmin } = useAuth()




    console.log("id = " + id)
    const {
        data: event,
        refetch
    } = useGetEventQuery({
        id: id
    })
    console.log("event2 = " + event)

    const { categories } = useGetCategoriesQuery("categoriesList", {
        selectFromResult: ({ data }) => ({
            categories: data?.ids.map(id => data?.entities[id])
        }),
    })

    const { tickets } = useGetTicketsQuery("ticketsList", {
        selectFromResult: ({ data }) => ({
            tickets: data?.ids.map(id => data?.entities[id])
        }),
    })

    const { sources } = useGetSourcesQuery("sourcesList", {
        selectFromResult: ({ data }) => ({
            sources: data?.ids.map(id => data?.entities[id])
        }),
    })

    useEffect(() => {
        refetch()
    }, []);


    if (!event) return <PulseLoader color={"#FFF"} />

    if (!isManager && !isAdmin) {
        if (event.username !== username) {
            return <p className="errmsg">No access</p>
        }
    }



    const content = <EditEventForm key={id} event={event} categories={categories} tickets={tickets} sources={sources} />

    return content
}
export default EditEvent