import { useParams } from 'react-router-dom'
import EditCityForm from './EditCityForm'
import { useGetCitiesQuery } from './citiesApiSlice'
import { useGetCountriesQuery } from '../countries/countriesApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditCity = () => {
    useTitle('ORACLY: Edit City')

    const { id } = useParams()

    const { countryname, isManager, isAdmin } = useAuth()

    const { city } = useGetCitiesQuery("citiesList", {
        selectFromResult: ({ data }) => ({
            city: data?.entities[id]
        }),
    })

    const { countries } = useGetCountriesQuery("countriesList", {
        selectFromResult: ({ data }) => ({
            countries: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!city || !countries?.length) return <PulseLoader color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (city.countryname !== countryname) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditCityForm city={city} countries={countries} />

    return content
}
export default EditCity