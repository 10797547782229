import NewCustomerForm from './NewCustomerForm'
import { useGetCountriesQuery } from '../countries/countriesApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const NewCustomer = () => {
    useTitle('ORACLY: New Customer')

    const content = <NewCustomerForm />

    return content
}
export default NewCustomer