import { TRANSLATIONS_EN } from '../features/translate/en/translations'
import { TRANSLATIONS_FR } from '../features/translate/fr/translations'

const useTranslate = (lang) => {

    let T = TRANSLATIONS_EN

    if (lang === 'en') {
        T = TRANSLATIONS_EN
    }

    if (lang === 'fr') {
        T = TRANSLATIONS_FR
    }

    return { T }

}

export default useTranslate