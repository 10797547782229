import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const countriesAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = countriesAdapter.getInitialState()

export const countriesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCountries: builder.query({
            query: () => ({
                url: '/countries',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedCountries = responseData.map(country => {
                    country.id = country._id
                    return country
                });
                return countriesAdapter.setAll(initialState, loadedCountries)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Country', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Country', id }))
                    ]
                } else return [{ type: 'Country', id: 'LIST' }]
            }
        }),
        addNewCountry: builder.mutation({
            query: initialCountry => ({
                url: '/countries',
                method: 'POST',
                body: {
                    ...initialCountry,
                }
            }),
            invalidatesTags: [
                { type: 'Country', id: "LIST" }
            ]
        }),
        updateCountry: builder.mutation({
            query: initialCountry => ({
                url: '/countries',
                method: 'PATCH',
                body: {
                    ...initialCountry,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Country', id: arg.id }
            ]
        }),
        deleteCountry: builder.mutation({
            query: ({ id }) => ({
                url: `/countries`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Country', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetCountriesQuery,
    useAddNewCountryMutation,
    useUpdateCountryMutation,
    useDeleteCountryMutation,
} = countriesApiSlice

// returns the query result object
export const selectCountriesResult = countriesApiSlice.endpoints.getCountries.select()

// creates memoized selector
const selectCountriesData = createSelector(
    selectCountriesResult,
    countriesResult => countriesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCountries,
    selectById: selectCountryById,
    selectIds: selectCountryIds
    // Pass in a selector that returns the countries slice of state
} = countriesAdapter.getSelectors(state => selectCountriesData(state) ?? initialState)