import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const mapsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = mapsAdapter.getInitialState()

export const mapsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getMaps: builder.query({
            query: () => ({
                url: '/maps',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedMaps = responseData.map(map => {
                    map.id = map._id
                    return map
                });
                return mapsAdapter.setAll(initialState, loadedMaps)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Map', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Map', id }))
                    ]
                } else return [{ type: 'Map', id: 'LIST' }]
            }
        }),
        addNewMap: builder.mutation({
            query: initialMap => ({
                url: '/maps',
                method: 'POST',
                body: {
                    ...initialMap,
                }
            }),
            invalidatesTags: [
                { type: 'Map', id: "LIST" }
            ]
        }),
        updateMap: builder.mutation({
            query: initialMap => ({
                url: '/maps',
                method: 'PATCH',
                body: {
                    ...initialMap,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Map', id: arg.id }
            ]
        }),
        deleteMap: builder.mutation({
            query: ({ id }) => ({
                url: `/maps`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Map', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetMapsQuery,
    useAddNewMapMutation,
    useUpdateMapMutation,
    useDeleteMapMutation,
} = mapsApiSlice

// returns the query result object
export const selectMapsResult = mapsApiSlice.endpoints.getMaps.select()

// creates memoized selector
const selectMapsData = createSelector(
    selectMapsResult,
    mapsResult => mapsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllMaps,
    selectById: selectMapById,
    selectIds: selectMapIds
    // Pass in a selector that returns the maps slice of state
} = mapsAdapter.getSelectors(state => selectMapsData(state) ?? initialState)