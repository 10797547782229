import { useParams } from 'react-router-dom'
import EditVenueForm from './EditVenueForm'
import { useGetVenuesQuery } from './venuesApiSlice'
import { useGetCitiesQuery } from '../cities/citiesApiSlice'
import { useGetSourcesQuery } from '../sources/sourcesApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'

const EditVenue = () => {
    useTitle('ORACLY: Edit Venue')

    const { id } = useParams()

    const { cityname, isManager, isAdmin } = useAuth()

    const { venue } = useGetVenuesQuery("venuesList", {
        selectFromResult: ({ data }) => ({
            venue: data?.entities[id]
        }),
    })

    const { cities } = useGetCitiesQuery("citiesList", {
        selectFromResult: ({ data }) => ({
            cities: data?.ids.map(id => data?.entities[id])
        }),
    })

    const { sources } = useGetSourcesQuery("sourcesList", {
        selectFromResult: ({ data }) => ({
            sources: data?.ids.map(id => data?.entities[id])
        }),
    })

    if (!venue || !cities?.length || !sources?.length) return <PulseLoader color={"#FFF"} />


    if (!isManager && !isAdmin) {
        if (venue.cityname !== cityname) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditVenueForm venue={venue} cities={cities} sources={sources} />

    return content
}
export default EditVenue