import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const calsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = calsAdapter.getInitialState()

export const calsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCals: builder.query({
            query: () => ({
                url: '/cals',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedCals = responseData.map(Cal => {
                    Cal.id = Cal._id
                    return Cal
                });
                return calsAdapter.setAll(initialState, loadedCals)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Cal', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Cal', id }))
                    ]
                } else return [{ type: 'Cal', id: 'LIST' }]
            }
        }),
        addNewCal: builder.mutation({
            query: initialCal => ({
                url: '/cals',
                method: 'POST',
                body: {
                    ...initialCal,
                }
            }),
            invalidatesTags: [
                { type: 'Cal', id: "LIST" }
            ]
        }),
        updateCal: builder.mutation({
            query: initialCal => ({
                url: '/cals',
                method: 'PATCH',
                body: {
                    ...initialCal,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Cal', id: arg.id }
            ]
        }),
        deleteCal: builder.mutation({
            query: ({ id }) => ({
                url: `/cals`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Cal', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetCalsQuery,
    useAddNewCalMutation,
    useUpdateCalMutation,
    useDeleteCalMutation,
} = calsApiSlice

// returns the query result object
export const selectCalsResult = calsApiSlice.endpoints.getCals.select()

// creates memoized selector
const selectCalsData = createSelector(
    selectCalsResult,
    calsResult => calsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCals,
    selectById: selectCalById,
    selectIds: selectCalIds
    // Pass in a selector that returns the cals slice of state
} = calsAdapter.getSelectors(state => selectCalsData(state) ?? initialState)