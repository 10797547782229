import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAddNewVenueMutation } from "./venuesApiSlice"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Select, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRing } from "@fortawesome/free-solid-svg-icons"

const NewVenueForm = ({ cities }) => {

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewVenue, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewVenueMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const venueLevels = [{ name: '1 level', value: '1' }, { name: '2 levels', value: '2' }, { name: '3 levels', value: '3' }, { name: '4 levels', value: '4' }, { name: '5 levels', value: '5' }]
    const options_venueLevel = venueLevels.map(venueLevel => {
        return (
            <option
                key={venueLevel.value}
                value={venueLevel.value}
            >
                {venueLevel.name}
            </option >
        )
    })

    const venueTypes = [{ name: 'stadium' }, { name: 'arena' }, { name: 'theater' }, { name: 'area' }]
    const options_venueType = venueTypes.map(type => {
        return (
            <option
                key={type.name}
                value={type.name}
            > {type.name}</option >
        )
    })

    const options_cityArea = cities.map(city => {
        return (
            <option
                key={city.id}
                value={city.id}
            > {city.cityName[lang]}</option >
        )
    })

    const [venueCapacity, setVenueCapacity] = useState('')
    const [venueLevel, setVenueLevel] = useState()
    const [venueType, setVenueType] = useState()
    const [cityId, setCityId] = useState()

    const [formFieldsVenueName, setFormFields] = useState([
        { lang: 'en', venueName: '' }, { lang: 'fr', venueName: '' },
    ])

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const onPageBack = () => navigate('/dash/venues')

    const onVenueCapacityChanged = e => setVenueCapacity(e.target.value)
    const onVenueLevelChanged = e => setVenueLevel(e.target.value)
    const onVenueTypeChanged = e => setVenueType(e.target.value)
    const onCityIdChanged = e => setCityId(e.target.value)

    const checkCanSave = () => {
        setCanSave(true)
        if ((venueCapacity === '' || venueLevel === '' || venueType === '' || cityId === '')) {
            setCanSave(false)
        }
        formFieldsVenueName.map((form) => {
            if (!isLoading && (form.lang === '' || form.venueName === '')) {
                setCanSave(false)
            }
        })
    }

    const onSaveVenueClicked = async (e) => {
        e.preventDefault()
        //        if (canSave) {
        //            await addNewVenue({ cityId: cityId, venueName, venueIso })
        //        }
        if (canSave) {
            let output = []
            let venueNameByLang = {}
            let venueNameAlias = {}
            formFieldsVenueName.forEach((field, index) => {
                venueNameByLang[field.lang] = field.venueName
                venueNameAlias[index] = field.venueName
            })
            output['name'] = venueNameByLang
            output['aliasName'] = venueNameAlias
            console.log(output)

            await addNewVenue({ cityId: cityId, venueName: venueNameByLang, venueNameAlias: venueNameAlias, venueLevel, venueType, venueCapacity, venueBlocs: {}, venueBlocsAlias: {} })
        }

    }

    const handleFormChange = (event, index) => {
        let data = [...formFieldsVenueName];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            venueName: ''
        }
        setFormFields([...formFieldsVenueName, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsVenueName];
        data.splice(index, 1)
        setFormFields(data)
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'VENUE : Add successfully', status: 'success' })
            setVenueType('')
            setVenueCapacity('')
            setCityId('')
            navigate('/dash/venues')
        }
        if (isError) {
            toastInfo({ title: 'VENUE : Add error (' + error?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, isError, error, toastInfo, isSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faRing} /> {T.venues.NewVenue}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveVenueClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form id="form" name="form" onSubmit={onSaveVenueClicked}>

                                <FormLabel htmlFor='country-status' mt='5' mb='0'>
                                    {T.venues.VenueName}
                                </FormLabel>
                                {formFieldsVenueName.map((form, index) => {
                                    return (
                                        <HStack key={index}>
                                            <Input
                                                isInvalid={!form.lang}
                                                name="lang"
                                                placeholder={T.venues.IsoCode}
                                                onChange={event => handleFormChange(event, index)}
                                                value={form.lang}
                                                focusBorderColor='blue.400'
                                                errorBorderColor='red.300'
                                                minLength="2"
                                                maxLength="2"
                                                bg='yellow.100'
                                                w='10vw'
                                            />
                                            <Input
                                                isInvalid={!form.venueName}
                                                name='venueName'
                                                placeholder={T.venues.TranslatedName}
                                                onChange={event => handleFormChange(event, index)}
                                                value={form.venueName}
                                                focusBorderColor='blue.400'
                                                errorBorderColor='red.300'
                                                bg='yellow.100'
                                                w='50vw'
                                            />
                                            {index !== 0 &&
                                                <IconButton
                                                    size='xs'
                                                    name="Remove"
                                                    title={T.common.Delete}
                                                    colorScheme='gray'
                                                    aria-label={T.common.Delete}
                                                    icon={<MinusIcon />}
                                                    onClick={() => removeFields(index)}
                                                />
                                            }

                                        </HStack>
                                    )
                                })}
                                <IconButton
                                    size='xs'
                                    title={T.common.Add}
                                    onClick={addFields}
                                    colorScheme='gray'
                                    aria-label={T.common.Add}
                                    icon={<AddIcon />}
                                />

                                <HStack>

                                    <FormControl>
                                        <FormLabel htmlFor='venueCapacity' mt='5' mb='0'>
                                            {T.venues.VenueCapacity}
                                        </FormLabel>
                                        <Input
                                            isInvalid={!venueCapacity}
                                            id="venueCapacity"
                                            name="venueCapacity"
                                            placeholder={T.venues.Capacity}
                                            onChange={onVenueCapacityChanged}
                                            value={venueCapacity}
                                            focusBorderColor='blue.400'
                                            errorBorderColor='red.300'
                                            bg='yellow.100'
                                        />
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel htmlFor='venueLevel' mt='5' mb='0'>
                                            {T.venues.VenueLevel}
                                        </FormLabel>
                                        <Select
                                            isInvalid={!venueLevel}
                                            id="venueLevel"
                                            name="venueLevel"
                                            placeholder=" "
                                            value={venueLevel}
                                            onChange={onVenueLevelChanged}
                                            bg='yellow.100'
                                        >
                                            {options_venueLevel}
                                        </Select>
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel htmlFor='venueType' mt='5' mb='0'>
                                            {T.venues.VenueType}
                                        </FormLabel>
                                        <Select
                                            isInvalid={!venueType}
                                            id="venueType"
                                            name="venueType"
                                            placeholder=" "
                                            value={venueType}
                                            onChange={onVenueTypeChanged}
                                            bg='yellow.100'
                                        >
                                            {options_venueType}
                                        </Select>
                                    </FormControl>

                                    <FormControl>
                                        <FormLabel htmlFor='cityId' mt='5' mb='0'>
                                            {T.venues.CityArea}
                                        </FormLabel>
                                        <Select
                                            isInvalid={!cityId}
                                            id="cityId"
                                            name="cityId"
                                            placeholder=" "
                                            value={cityId}
                                            onChange={onCityIdChanged}
                                            bg='yellow.100'
                                        >
                                            {options_cityArea}
                                        </Select>
                                    </FormControl>

                                </HStack>

                            </form>

                        </Box>
                    </GridItem>
                </Grid>
            </Center>
        </>
    )

    return content
}

export default NewVenueForm