import { useState, useEffect } from "react"
import { useUpdateCustomerMutation, useDeleteCustomerMutation } from "./customersApiSlice"
import { useNavigate } from "react-router-dom"
import CustomerUploadForm from "./CustomerUploadForm";
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Center, HStack, Container, Input, Select, Switch, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faUsers, faList, faSave, faCircleXmark, faSquarePlus, faSquareMinus, faGear } from "@fortawesome/free-solid-svg-icons"

const EditCustomerForm = ({ customer, countries }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [updateCustomer, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCustomerMutation()

    const [deleteCustomer, {
        data: delData,
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delError
    }] = useDeleteCustomerMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const [customerType, setCustomerType] = useState(customer.customerType)
    const [customerGenre, setCustomerGenre] = useState(customer.customerGenre)
    const [customerPics, setCustomerPics] = useState(customer.customerPics || []);

    const [customerStatus, setCustomerStatus] = useState(customer.customerStatus)

    const updateCustomerPics = (newValue) => {
        setCustomerPics([...customerPics, ...newValue]);
    };

    const deleteCustomerPics = (e) => {
        const t = customerPics.filter((item, index) => index !== e);
        setCustomerPics(t);
    };

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            if ((customerType == '' || customerGenre == '')) {
                setCanSave(false)
            }
            formFieldsCustomerName.map((form) => {
                if (!isLoading && (form.lang == '' || form.customerName == '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/customers')

    const onCustomerTypeChanged = e => setCustomerType(e.target.value)
    const onCustomerGenreChanged = e => setCustomerGenre(e.target.value)
    const onCustomerStatusChanged = e => setCustomerStatus(prev => !prev)

    const onSaveCustomerClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            let customerNameByLang = {}
            formFieldsCustomerName.forEach(field => {
                customerNameByLang[field.lang] = field.customerName
            })
            output['customerName'] = customerNameByLang
            console.log(output)

            await updateCustomer({ id: customer.id, customerName: customerNameByLang, customerStatus, customerType: customerType, customerGenre: customerGenre, customerPics: customerPics })
        }
    }

    const onDeleteCustomerClicked = async () => {
        await deleteCustomer({ id: customer.id })
    }

    const created = new Date(customer.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(customer.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const customerTypes = [{ name: 'isSinger' }, { name: 'isGroupMusic' }, { name: 'isArtist' }, { name: 'isTeamSport' }]
    const options_customerType = customerTypes.map(type => {
        return (
            <option
                key={type.name}
                value={type.name}
            > {type.name}</option >
        )
    })

    const customerGenres = [{ name: 'music_Pop' }, { name: 'music_Rap' }, { name: 'sport_Football' }, { name: 'sport_Basketball' }]
    const options_customerGenre = customerGenres.map(genre => {
        return (
            <option
                key={genre.name}
                value={genre.name}
            > {genre.name}</option >
        )
    })

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <Button size='xs' onClick={onDeleteCustomerClicked} name="Delete" title={T.common.Delete} leftIcon={<CloseIcon />} colorScheme='red' variant='outline'>{T.customers.DeleteThisCustomer}</Button>
        )
    }

    const initCustomerNamesForm = () => {
        let customerNameByLang = []
        Object.entries(customer.customerName).map(item => {
            customerNameByLang.push({ lang: item[0], customerName: item[1] })
        })
        return customerNameByLang
    }

    const [formFieldsCustomerName, setFormFields] = useState(initCustomerNamesForm)

    const handleFormChange = (event, index) => {
        let data = [...formFieldsCustomerName];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            customerName: ''
        }
        setFormFields([...formFieldsCustomerName, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsCustomerName];
        data.splice(index, 1)
        setFormFields(data)
        checkCanSave()
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'CUSTOMER : ' + data?.message + ' successfully', status: 'success' })
            initCustomerNamesForm()
            navigate('/dash/customers')
        }
        if (isError) {
            toastInfo({ title: 'CUSTOMER : Update error (' + error?.data?.message + ')', status: 'error' })
        }
        if (isDelSuccess) {
            toastInfo({ title: 'CUSTOMER : ' + delData?.message + ' successfully', status: 'success' })
            initCustomerNamesForm()
            navigate('/dash/customers')
        }
        if (isDelError) {
            toastInfo({ title: 'CUSTOMER : Update error (' + delError?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, initCustomerNamesForm, toastInfo, isError, error, isDelError, delError, isSuccess, isDelSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' maxW='1500' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faUsers} /> {T.customers.EditCustomer}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveCustomerClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={e => e.preventDefault()}>

                                <Tabs variant='soft-rounded' colorScheme='teal' isFitted>
                                    <TabList
                                        overflowX="auto"
                                        css={css({
                                            scrollbarWidth: 'none',
                                            '::-webkit-scrollbar': { display: 'none' },
                                            '-webkit-overflow-scrolling': 'touch',
                                            boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                            border: '0 none',
                                        })}
                                    >
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Customer</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Picture</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}><FontAwesomeIcon icon={faGear} /></Tab>
                                    </TabList>

                                    <TabPanels>

                                        {/* Tab CUSTOMER */}
                                        <TabPanel minH={400}>
                                            <FormControl>

                                                <FormLabel htmlFor='countryTranslate' mt='5' mb='0'>
                                                    {T.customers.CustomerName}
                                                </FormLabel>
                                                {formFieldsCustomerName.map((form, index) => {
                                                    return (
                                                        <HStack key={index}>
                                                            <Input
                                                                isInvalid={!form.lang}
                                                                name='lang'
                                                                placeholder={T.customers.IsoCode}
                                                                onChange={event => handleFormChange(event, index)}
                                                                value={form.lang}
                                                                focusBorderColor='blue.400'
                                                                errorBorderColor='red.300'
                                                                minLength="2"
                                                                maxLength="2"
                                                                bg='yellow.100'
                                                                w='10vw'
                                                            />
                                                            <Input
                                                                isInvalid={!form.customerName}
                                                                name='customerName'
                                                                placeholder={T.customers.TranslatedName}
                                                                onChange={event => handleFormChange(event, index)}
                                                                value={form.customerName}
                                                                focusBorderColor='blue.400'
                                                                errorBorderColor='red.300'
                                                                bg='yellow.100'
                                                                w='50vw'
                                                            />
                                                            {index !== 0 &&
                                                                <IconButton
                                                                    size='xs'
                                                                    name="Remove"
                                                                    title={T.common.Delete}
                                                                    colorScheme='gray'
                                                                    aria-label={T.common.Delete}
                                                                    icon={<MinusIcon />}
                                                                    onClick={() => removeFields(index)}
                                                                />
                                                            }
                                                        </HStack>
                                                    )
                                                })
                                                }
                                                <IconButton
                                                    size='xs'
                                                    title={T.common.Add}
                                                    onClick={addFields}
                                                    colorScheme='gray'
                                                    aria-label={T.common.Add}
                                                    icon={<AddIcon />}
                                                />

                                                <FormLabel htmlFor='customerType' mt='5' mb='0'>
                                                    {T.customers.CustomerType}
                                                </FormLabel>
                                                <Select
                                                    isInvalid={!customerType}
                                                    id="customerType"
                                                    name="customerType"
                                                    value={customerType}
                                                    onChange={onCustomerTypeChanged}
                                                    bg='yellow.100'
                                                >
                                                    {options_customerType}
                                                </Select>

                                                <FormLabel htmlFor='customerGenre' mt='5' mb='0'>
                                                    {T.customers.CustomerGenre}
                                                </FormLabel>
                                                <Select
                                                    isInvalid={!customerGenre}
                                                    id="customerGenre"
                                                    name="customerGenre"
                                                    value={customerGenre}
                                                    onChange={onCustomerGenreChanged}
                                                    bg='yellow.100'
                                                >
                                                    {options_customerGenre}
                                                </Select>

                                            </FormControl>
                                        </TabPanel>

                                        {/* Tab PICTURE */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <CustomerUploadForm updateCustomerPics={updateCustomerPics} deleteCustomerPics={deleteCustomerPics} customerPics={customerPics} />
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>

                                        {/* Tab SETUP */}
                                        <TabPanel minH={400}>
                                            <Grid
                                                templateRows='repeat(1, 1fr)'
                                                templateColumns='repeat(5, 1fr)'
                                                gap={5}
                                            >
                                                <GridItem colSpan={5}>
                                                    <Box>
                                                        <FormLabel htmlFor='Status' mt='5' mb='0'>
                                                            {T.customers.Status}
                                                        </FormLabel>
                                                        <Switch
                                                            id='eventsStatus'
                                                            name="status"
                                                            colorScheme='green'
                                                            isChecked={customerStatus}
                                                            onChange={onCustomerStatusChanged}
                                                        />
                                                        <br />
                                                        <br />
                                                        <HStack>
                                                            <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Created}:</Text>
                                                            <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{created}</Text>
                                                        </HStack>
                                                        <HStack>
                                                            <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Updated}:</Text>
                                                            <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{updated}</Text>
                                                        </HStack>
                                                        <br />
                                                        <br />
                                                        {deleteButton}
                                                    </Box>
                                                </GridItem>
                                            </Grid>
                                        </TabPanel>

                                    </TabPanels>
                                </Tabs>

                            </form>

                        </Box>
                    </GridItem>

                </Grid>
            </Center>

        </>
    )

    return content
}

export default EditCustomerForm