import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom"
import { useAddNewMapMutation } from "./mapsApiSlice"
import { useSearchVenuesQuery } from "../venues/venuesApiSlice"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

import {
    TransformWrapper,
    TransformComponent,
} from "react-zoom-pan-pinch";


// import `ChakraProvider` component
import { css, Box, Grid, GridItem, Center, Flex, Square, HStack, VStack, Container, Divider, Input, InputGroup, InputLeftElement, Select, Checkbox, Textarea, Highlight, ButtonGroup, Button, Icon, IconButton, Text, useToast, useDisclosure } from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon, DragHandleIcon, SearchIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDungeon } from "@fortawesome/free-solid-svg-icons"

const NewMapForm = ({ countries }) => {


    const data22 = [
        { country: "cn", value: 1389618778 }, // china
        { country: "in", value: 1311559204 }, // india
        { country: "us", value: 331883986 }, // united states
        { country: "id", value: 264935824 }, // indonesia
        { country: "pk", value: 210797836 }, // pakistan
        { country: "br", value: 210301591 }, // brazil
        { country: "ng", value: 208679114 }, // nigeria
        { country: "bd", value: 161062905 }, // bangladesh
        { country: "ru", value: 141944641 }, // russia
        { country: "mx", value: 127318112 }, // mexico
    ];

    const getStyle = ({
        countryValue,
        countryCode,
        minValue,
        maxValue,
        color,
    }) => ({
        fill: countryCode === "US" ? "blue" : color,
        fillOpacity: countryValue
            ? 0.1 + (1.5 * (countryValue - minValue)) / (maxValue - minValue)
            : 0,
        stroke: "green",
        strokeWidth: 1,
        strokeOpacity: 0.2,
        cursor: "pointer",
    });



    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [addNewMap, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewMapMutation()

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)
    let [mapHasVenue, setMapHasVenue] = useState(false)
    let [mapIsDynamic, setMapIsDynamic] = useState(false)

    const mapTypes = [{ name: 'static' }, { name: 'dynamic' }]
    const options_mapType = mapTypes.map(type => {
        return (
            <option
                key={type.name}
                value={type.name}
            >
                {type.name}
            </option>
        )
    })



    // Tab : Maps
    const [formFieldsMaps, setFormFieldsMaps] = useState([{}]);
    //--


    // Tab : Venues
    const [formFieldsVenues, setFormFieldsVenues] = useState([{}]);
    const [searchVenues, setSearchVenues] = useState([]);
    const [searchVenuesHighlight, setSearchVenuesHighlight] = useState([]);
    const [searchVenuesResults, setSearchVenuesResult] = useState([]);
    const [searchVenuesCounterKey, setSearchVenuesCounterKey] = useState(0)
    const searchBoxVenues = useDisclosure()

    const {
        data: srcVenuesData,
        isLoading: isSrcVenueLoading,
        isSuccess: isSrcVenueSuccess,
        isError: isSrcVenueError,
        error: SrcVenueError
    } = useSearchVenuesQuery({
        searchStringVenue: searchVenues,
        searchVenuesCounterKey: searchVenuesCounterKey
    })

    const onSearchVenueChanged = (event) => {
        setSearchVenuesCounterKey(searchVenuesCounterKey + 1)
        if (event.target.value.length > 3) {
            setSearchVenuesResult([])
            setSearchVenues(event.target.value)
            setSearchVenuesHighlight(event.target.value.split(' '))
            searchBoxVenues.onOpen()
        } else {
            searchBoxVenues.onClose()
        }
    }

    const selectVenue = (venueId, venueName, venueType, venueBlocs, cityName, countryName, marketName) => {
        let data = [...formFieldsVenues];
        data[0].venueId = venueId;
        data[0].venueName = venueName.en;
        data[0].venueType = venueType;
        data[0].venueBlocs = venueBlocs;
        data[0].cityName = cityName.en;
        data[0].countryName = countryName.en;
        data[0].marketName = marketName.en;
        setFormFieldsVenues(data)
        checkMapVenue()
        checkCanSave()
        searchBoxVenues.onClose()

        const venueBlocsOptions = venueBlocs.map(venueBloc => {
            return (
                <option
                    key={venueBloc.blockId}
                    value={venueBloc.blockId}
                    data-pos={venueBloc.tagPos}
                    data-section={venueBloc.tagSection}
                    data-num={venueBloc.tagNum}
                    data-level={venueBloc.tagLevel}
                >
                    {venueBloc.nameEN}
                </option>)
        })
        setVenueBlocsOptions(venueBlocsOptions)
    }

    const searchVenueFocus = useRef()
    //--


    // Tab : Pictures
    const [formFieldsPictures, setFormFieldsPictures] = useState([{}]);

    //--


    // Tab : Blocks
    const [formFieldsBlocks, setFormFieldsBlocks] = useState([{}]);
    const [svgContent, setSvgContent] = useState('');
    const [numPaths, setNumPaths] = useState(0);
    const [numText, setNumText] = useState(0);
    const [svgData, setSvgData] = useState(null);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');

    const [svgBlocs, setSvgBlocs] = useState([]);
    const [venueBlocsOptions, setVenueBlocsOptions] = useState();
    const [svgBlocsSelected, setSvgBlocsSelected] = useState([]);

    const svgFileChange = (event) => {
        const file = event.target.files[0];

        let data = [...formFieldsBlocks];
        data[0][event.target.name] = event.target.value;
        setFormFieldsBlocks(data);

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const content = e.target.result;
                setSvgContent(content);
                data[0]['svgContent'] = content;
                setFormFieldsBlocks(data);

                // Extraire le nombre de balises <path> et <text> dans le SVG
                const numPaths = (content.match(/<path/g) || []).length;
                const numText = (content.match(/<text/g) || []).length;
                setNumPaths(numPaths);
                setNumText(numText);

                // Extraire les balises <path> et <text> du SVG
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(content, 'text/xml');
                const pathElements = xmlDoc.getElementsByTagName('path');
                const textElements = xmlDoc.getElementsByTagName('text');

                // Extraire les attributs `width` et `height` du SVG d'origine
                const width = xmlDoc.documentElement.getAttribute('width');
                const height = xmlDoc.documentElement.getAttribute('height');

                // Calculer la valeur du viewBox
                const viewBoxValue = `0 0 ${width} ${height}`;

                // Créer un nouvel élément <svg> avec les attributs `width`, `height` et `viewBox`
                const svgDoc = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                svgDoc.setAttribute('width', '600');
                svgDoc.setAttribute('height', '600');
                svgDoc.setAttribute('viewBox', viewBoxValue);

                // Ajouter les balises <path> au nouveau fichier SVG
                var objectBlocks = [];
                for (let i = 0; i < pathElements.length; i++) {
                    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                    path.setAttribute('id', pathElements[i].getAttribute('id'));
                    path.setAttribute('d', pathElements[i].getAttribute('d'));

                    // Extraire l'attribut `fill` avec la valeur `#990000`
                    const fillAttribute = pathElements[i].getAttribute('fill');
                    if (fillAttribute) {
                        path.setAttribute('fill', fillAttribute);
                    } else {
                        path.setAttribute('fill', '#CCCCCC');
                    }

                    svgDoc.appendChild(path);

                    objectBlocks.push({
                        pathId: pathElements[i].getAttribute('id'),
                        blockId: ''
                    })
                }
                setSvgBlocs(objectBlocks)


                // Ajouter les balises <text> au nouveau fichier SVG
                for (let i = 0; i < textElements.length; i++) {
                    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                    text.setAttribute('id', textElements[i].getAttribute('id'));
                    text.setAttribute('x', textElements[i].getAttribute('x'));
                    text.setAttribute('y', textElements[i].getAttribute('y'));

                    // Extraire la valeur de `text-anchor`
                    const textAnchor = textElements[i].getAttribute('text-anchor');
                    if (textAnchor) {
                        text.setAttribute('text-anchor', textAnchor);
                    } else {
                        text.setAttribute('text-anchor', 'middle');
                    }

                    // Extraire la valeur de `font-size` du style
                    const style = textElements[i].getAttribute('style');
                    const fontSizeMatch = style && style.match(/font-size:\s*([^;]+)/);
                    if (fontSizeMatch && fontSizeMatch[1]) {
                        const fontSize = fontSizeMatch[1];
                        text.setAttribute('font-size', fontSize);
                    }

                    // Extraire l'attribut `fill` avec la valeur `#990000`
                    const fillAttribute = textElements[i].getAttribute('fill');
                    if (fillAttribute) {
                        text.setAttribute('fill', fillAttribute);
                    } else {
                        text.setAttribute('fill', '#000000');
                    }

                    text.textContent = textElements[i].textContent;
                    svgDoc.appendChild(text);

                }

                // Convertir le nouveau fichier SVG en texte
                const serializer = new XMLSerializer();
                const svgText = serializer.serializeToString(svgDoc);

                // Afficher le nouveau fichier SVG dans le composant
                setSvgData(svgText);

                data[0]['svgData'] = svgText;
                setFormFieldsBlocks(data);

            };

            reader.readAsText(file);
        }
    };
    //--


    // Tab : Alias
    const [formFieldsAlias, setFormFieldsAlias] = useState([{}]);
    //--


    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            formFieldsMaps.map((form) => {
                if (!isLoading && (form.lang === '' || form.mapName === '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/maps')

    const onSaveMapClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            let mapNameByLang = {}
            formFieldsMaps.forEach(field => {
                mapNameByLang = field.mapName
            })
            //output['mapName'] = mapNameByLang
            output.mapName = formFieldsMaps[0].mapName
            output.mapType = formFieldsMaps[0].mapType
            output.venueId = formFieldsVenues[0].venueId
            output.svgData = formFieldsBlocks[0].svgData
            output.svgBlocs = svgBlocs

            console.log(output)

            await addNewMap(output)
        }

    }

    const mapFormChange = (event, index) => {
        let data = [...formFieldsMaps];
        data[index][event.target.name] = event.target.value;
        setFormFieldsMaps(data);
        checkCanSave()
        checkMapType()
    }

    const venueBlocFormChange = (event, index) => {
        const svgBlocsUpdate = svgBlocs.map((svgBloc, i) => {
            if (i === index) {
                return {
                    ...svgBloc,
                    'type': 'isBloc',
                    'blockId': event.target.value,
                    'pos': event.target.selectedOptions[0].dataset.pos,
                    'section': event.target.selectedOptions[0].dataset.section,
                    'num': event.target.selectedOptions[0].dataset.num,
                    'level': event.target.selectedOptions[0].dataset.level
                };
            }
            return svgBloc;
        });
        setSvgBlocs(svgBlocsUpdate)
    }


    const addFields = () => {
        let object = {
            lang: '',
            mapName: ''
        }
        setFormFieldsMaps([...formFieldsMaps, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsMaps];
        data.splice(index, 1)
        setFormFieldsMaps(data)
    }

    const checkMapVenue = () => {
        {
            setMapHasVenue(false)
            formFieldsVenues.map((formFieldsVenue) => {
                if (formFieldsVenue.venueId != '') {
                    setMapHasVenue(true)
                }
            })
        }
    }

    const checkMapType = () => {
        {
            setMapIsDynamic(false)
            formFieldsMaps.map((formFieldsMap) => {
                if (formFieldsMap.mapType == 'dynamic') {
                    setMapIsDynamic(true)
                }
            })
        }
    }

    //var objectBlocks = [{ 'key': '1', 'name': 'aa' }, { 'key': '2', 'name': 'bb' }];
    var objectBlocks = [];
    /*
        for (var i = 1; i <= 20; i++) {
            objectBlocks.push({
                blockId: i,
                name: "block." + i
            });
        }
    */
    //alert('objectBlocks = ' + JSON.stringify(objectBlocks))

    //    return objects;

    /*
        let objectBlocks2 = [{}];
        for (var i = 1; i <= 5; i++) {
            let object = {}
            let objectBlocks2 = object({
                key: "a",
                name: "block." + i
            });
            //let var2 = ([...objectBlocks2, objectBlocks2])
            alert('objectBlocks2 = ' + JSON.stringify(objectBlocks2))
     
        }
        alert('objectBlocks2 = ' + JSON.stringify(objectBlocks2))
        */
    //setFormFieldsSources(data);
    //    checkCanSave()


    // Fonction pour accéder à un élément path par son id
    const colorPathOnSvg = (id, color) => {
        const pathElement = document.getElementById(id);
        //alert("pathElement = " + pathElement)
        if (pathElement) {
            // Faites ce que vous voulez avec le path, par exemple, changer sa couleur
            pathElement.style.fill = color;
        }
    };

    const hoverBlock = (id, color) => {
        const pathElement = document.getElementById(id);
        //alert("pathElement = " + pathElement)
        if (pathElement) {
            // Faites ce que vous voulez avec le path, par exemple, changer sa couleur
            pathElement.style.stroke = color;
            pathElement.style.strokeWidth = '5';
        }
    };

    const unselectAllBlocks = (blockId, index) => {
        for (var i = 0; i < svgBlocsSelected.length; i++) {
            colorPathOnSvg(svgBlocsSelected[i].pathId, '#CCCCCC');
        }
        setSvgBlocsSelected([])
    }

    const selectBlock = (pathId, index) => {
        let data = [...svgBlocsSelected];

        if (svgBlocsSelected.length > 0) {
            //            alert('formFieldsBlocks.length = ' + formFieldsBlocks.length)
            for (var i = 0; i < svgBlocsSelected.length; i++) {
                if (svgBlocsSelected[i].pathId == pathId) {
                    //                    alert('find')
                    data.splice(i, 1)
                    colorPathOnSvg(pathId, '#CCCCCC');
                    return setSvgBlocsSelected(data)
                }
            }
            //            alert('no find')
            //            data[index] = blockId;
            let object = {
                pathId: pathId
            }
            setSvgBlocsSelected([...svgBlocsSelected, object])


            // Exemple : Accéder à l'élément path avec l'id "monPath"
            colorPathOnSvg(pathId, 'teal');
        } else {
            let object = {
                pathId: pathId
            }
            setSvgBlocsSelected([object]);
            colorPathOnSvg(pathId, 'teal');
        }
    }


    const addTag = (tagName, tagValue) => {
        let data = [...svgBlocs];
        for (var i = 0; i < svgBlocsSelected.length; i++) {
            for (var j = 0; j < data.length; j++) {
                if (data[j].pathId == svgBlocsSelected[i].pathId) {
                    data[j] = { ...data[j], [tagName]: tagValue }
                }
            }
            setSvgBlocs(data);
        }
    }

    const blockIsSelect = (pathId) => {
        for (var i = 0; i < svgBlocsSelected.length; i++) {
            if (svgBlocsSelected[i].pathId == pathId) {
                return 'gray.400'
            } else {
                // return 'gray.100'
            }
        }


    }

    const changeColor2 = (e) => {
        const blocId = e.currentTarget.getAttribute("id")
        const color = e.currentTarget.getAttribute("fill") === '#008080' ? '#AAAAAA' : '#008080'
        document.getElementById(blocId).setAttribute("fill", color)
        //document.getElementById(seatId).setAttribute("stroke", color)
    }

    const changeColor = (event) => {
        // Vous pouvez ajouter ici la logique pour changer la couleur de l'élément <path>
        // Par exemple, vous pouvez obtenir l'élément cliqué en utilisant event.target
        // Et puis appliquer des changements à l'élément, par exemple, changer sa couleur
        event.target.setAttribute('fill', '#008080'); // Remplacez "nouvelleCouleur" par la couleur souhaitée
    };

    // La fonction pour gérer le clic sur un élément path
    const handlePathClick = (event) => {
        // Ajoutez ici la logique pour gérer le clic sur l'élément path
        // Vous pouvez accéder aux propriétés de l'élément path via event.target
        const pathElement = event.target;
        // Par exemple, vous pouvez changer la couleur de l'élément path
        pathElement.setAttribute('fill', '#008080'); // Remplacez "nouvelleCouleur" par la couleur souhaitée
    };

    const mapRef = useRef();

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'MAP : Add successfully', status: 'success' })
            navigate('/dash/maps')
        }
        if (isError) {
            toastInfo({ title: 'MAP : Add error (' + error?.data?.message + ')', status: 'error' })
        }
        if (isSrcVenueSuccess) {
            setSearchVenuesResult([])
        }
        if (isSrcVenueSuccess && srcVenuesData != []) {
            setSearchVenuesResult(srcVenuesData)
        } else {
            setSearchVenuesResult([])
        }
    }, [checkCanSave, isError, error, isSuccess, isSrcVenueSuccess, navigate])



    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faDungeon} /> {T.maps.NewMap}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveMapClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={onSaveMapClicked}>

                                <Tabs variant='soft-rounded' colorScheme='teal' isFitted>
                                    <TabList
                                        overflowX="auto"
                                        css={css({
                                            scrollbarWidth: 'none',
                                            '::-webkit-scrollbar': { display: 'none' },
                                            '-webkit-overflow-scrolling': 'touch',
                                            boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                            border: '0 none',
                                        })}
                                    >
                                        <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Venue</Tab>
                                        <Tab isDisabled={!mapHasVenue} _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Map</Tab>
                                        <Tab isDisabled={!mapIsDynamic} _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Block</Tab>
                                        <Tab isDisabled={!mapHasVenue} _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Picture</Tab>
                                        <Tab isDisabled _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Alias</Tab>
                                        <Tab isDisabled _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={18} fontWeight={700}>Other</Tab>
                                    </TabList>

                                    <TabPanels>

                                        {/* Tab VENUE */}
                                        <TabPanel minH={400}>
                                            {formFieldsVenues.map((formFieldsVenue, index) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            templateRows='repeat(1, 1fr)'
                                                            templateColumns='repeat(5, 1fr)'
                                                            gap={5}
                                                        >
                                                            <GridItem colSpan={5}>
                                                                <Box>
                                                                    <HStack>
                                                                        <FormControl>

                                                                            <Popover
                                                                                initialFocusRef={searchVenueFocus}
                                                                                returnFocusOnClose={false}
                                                                                isOpen={searchBoxVenues.isOpen}
                                                                                onClose={searchBoxVenues.onClose}
                                                                                placement='bottom'
                                                                                closeOnBlur={false}
                                                                            >
                                                                                <HStack>
                                                                                    <PopoverAnchor>
                                                                                        <InputGroup>
                                                                                            <InputLeftElement pointerEvents='none'>
                                                                                                <SearchIcon color='gray.300' />
                                                                                            </InputLeftElement>
                                                                                            <Input
                                                                                                //isInvalid={!form.searchVenueFocus ?? checkCanSave()}
                                                                                                autoFocus
                                                                                                autoComplete="off"
                                                                                                ref={searchVenueFocus}
                                                                                                name='searchVenue'
                                                                                                //value=''
                                                                                                onChange={(event) => onSearchVenueChanged(event)}
                                                                                                placeholder='search venue...'
                                                                                                focusBorderColor='blue.400'
                                                                                                errorBorderColor='red.300'
                                                                                                size='md'
                                                                                                fontWeight={500}
                                                                                                fontSize='20'
                                                                                                bg='yellow.100'
                                                                                            />
                                                                                        </InputGroup>
                                                                                    </PopoverAnchor>
                                                                                </HStack>

                                                                                <PopoverContent width="95vw" maxWidth="700px" color='black'>
                                                                                    <PopoverArrow />
                                                                                    <PopoverCloseButton />
                                                                                    <PopoverBody>
                                                                                        <Text color='black' fontSize={{ base: "16", sm: "16", md: "16", lg: "16", xl: "20" }} fontWeight={700}>Results</Text>
                                                                                        <Divider />
                                                                                        <>
                                                                                            {searchVenuesResults.map((searchVenuesResult) =>
                                                                                                searchVenuesResults === [] ? (
                                                                                                    <Flex>
                                                                                                        <Box w='50vw' maxWidth='350px'>
                                                                                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={300}>
                                                                                                                No result
                                                                                                            </Text>
                                                                                                        </Box>
                                                                                                    </Flex>
                                                                                                ) : (
                                                                                                    <Flex color='black' key={searchVenuesResult._id} onClick={() => selectVenue(searchVenuesResult._id, searchVenuesResult.venueName, searchVenuesResult.venueType, searchVenuesResult.venueBlocs, searchVenuesResult.cityName, searchVenuesResult.countryName, searchVenuesResult.marketName)} cursor={'pointer'} _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }}>
                                                                                                        <Box w='50vw' maxWidth='350px'>
                                                                                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={500}>
                                                                                                                <Highlight
                                                                                                                    key={searchVenuesResult._id}
                                                                                                                    query={searchVenuesHighlight}
                                                                                                                    styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                                                                                >
                                                                                                                    {searchVenuesResult.venueName.en}
                                                                                                                </Highlight>
                                                                                                            </Text>
                                                                                                        </Box>
                                                                                                        <Box w='50vw' maxWidth='350px'>
                                                                                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>
                                                                                                                <Highlight
                                                                                                                    key={searchVenuesResult._id}
                                                                                                                    query={searchVenuesHighlight}
                                                                                                                    styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                                                                                >
                                                                                                                    {searchVenuesResult.cityName.en}
                                                                                                                </Highlight>
                                                                                                            </Text>
                                                                                                        </Box>
                                                                                                        <Box w='50vw' maxWidth='350px'>
                                                                                                            <Text fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "22" }} fontWeight={400}>
                                                                                                                <Highlight
                                                                                                                    key={searchVenuesResult._id}
                                                                                                                    query={searchVenuesHighlight}
                                                                                                                    styles={{ px: '1', py: '0', bg: 'teal.700', color: 'white' }}
                                                                                                                >
                                                                                                                    {searchVenuesResult.countryName.en}
                                                                                                                </Highlight>
                                                                                                            </Text>
                                                                                                        </Box>
                                                                                                    </Flex>
                                                                                                ))}
                                                                                        </>
                                                                                    </PopoverBody>
                                                                                </PopoverContent>
                                                                            </Popover>

                                                                        </FormControl>
                                                                    </HStack>

                                                                    <HStack>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='VenueName' mt='5' mb='0'>
                                                                                {T.maps.VenueName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!formFieldsVenue.venueName ?? checkCanSave()}
                                                                                name='venueName'
                                                                                value={formFieldsVenue.venueName}
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='lg'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='VenueType' mt='5' mb='0'>
                                                                                {T.maps.VenueType}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!formFieldsVenue.venueType ?? checkCanSave()}
                                                                                name='venueType'
                                                                                value={formFieldsVenue.venueType}
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='lg'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>
                                                                    </HStack>

                                                                    <HStack>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='CityName' mt='5' mb='0'>
                                                                                {T.maps.VenueCityName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!formFieldsVenue.cityName ?? checkCanSave()}
                                                                                name='cityName'
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                value={formFieldsVenue.cityName}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>

                                                                        <FormControl>
                                                                            <FormLabel htmlFor='CountryName' mt='5' mb='0'>
                                                                                {T.maps.VenueCountryName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!formFieldsVenue.countryName ?? checkCanSave()}
                                                                                name='countryName'
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                value={formFieldsVenue.countryName}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>

                                                                        <FormControl>
                                                                            <FormLabel htmlFor='MarketName' mt='5' mb='0'>
                                                                                {T.maps.VenueMarketName}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!formFieldsVenue.marketName ?? checkCanSave()}
                                                                                name='marketName'
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                value={formFieldsVenue.marketName}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='md'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>
                                                                    </HStack>

                                                                    <HStack>
                                                                        <FormControl>
                                                                            <FormLabel htmlFor='VenueBlocs' mt='5' mb='0'>
                                                                                {T.maps.VenueBlocs}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!formFieldsVenue.venueBlocs ?? checkCanSave()}
                                                                                name='venueBlocs'
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                value={JSON.stringify(formFieldsVenue.venueBlocs)}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='sm'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>

                                                                        <FormControl>
                                                                            <FormLabel htmlFor='VenueId' mt='5' mb='0'>
                                                                                {T.maps.VenueId}
                                                                            </FormLabel>
                                                                            <Input
                                                                                isInvalid={!formFieldsVenue.venueId ?? checkCanSave()}
                                                                                name='venueBlocs'
                                                                                //onChange={event => handleFormChange(event, index)}
                                                                                value={formFieldsVenue.venueId}
                                                                                focusBorderColor='blue.400'
                                                                                errorBorderColor='red.300'
                                                                                size='sm'
                                                                                fontWeight={500}
                                                                                fontSize='20'
                                                                                bg='gray.100'
                                                                                isReadOnly
                                                                            />
                                                                        </FormControl>
                                                                    </HStack>

                                                                </Box>
                                                            </GridItem>
                                                        </Grid>

                                                    </>
                                                )
                                            })}
                                        </TabPanel>


                                        {/* Tab MAP */}
                                        <TabPanel minH={400}>
                                            {formFieldsMaps.map((formFieldsMap, index) => {
                                                return (
                                                    <>
                                                        <HStack>
                                                            <FormControl>
                                                                <FormLabel htmlFor='VenueName' mt='5' mb='0'>
                                                                    {T.maps.MapName}
                                                                </FormLabel>
                                                                <Input
                                                                    isInvalid={!formFieldsMap.mapName ?? checkCanSave()}
                                                                    name='mapName'
                                                                    value={formFieldsMap.mapName}
                                                                    onChange={event => mapFormChange(event, index)}
                                                                    focusBorderColor='blue.400'
                                                                    errorBorderColor='red.300'
                                                                    size='lg'
                                                                    fontWeight={500}
                                                                    fontSize='20'
                                                                    bg='yellow.100'
                                                                />
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='MapType' mt='5' mb='0'>
                                                                    {T.maps.MapType}
                                                                </FormLabel>
                                                                <Select
                                                                    isInvalid={!formFieldsMap.mapType ?? checkCanSave()}
                                                                    id="mapType"
                                                                    name="mapType"
                                                                    focusBorderColor='blue.400'
                                                                    errorBorderColor='red.300'
                                                                    size='lg'
                                                                    placeholder=' '
                                                                    //value={mapType}
                                                                    onChange={event => mapFormChange(event, index)}
                                                                    bg='yellow.100'
                                                                >
                                                                    {options_mapType}
                                                                </Select>
                                                            </FormControl>
                                                        </HStack>
                                                        <HStack mt='3'>
                                                            <Text fontSize='15'>
                                                                <Text fontSize='15' fontWeight={600}>Exemple :</Text>
                                                                FR_StadeDeFrance_Gen_GroundSeating1_SVG_v1<br />
                                                                FR_StadeDeFrance_Gen_GroundStanding1_SVG_v1<br />
                                                                FR_StadeDeFrance_Gen_GroundStanding2_SVG_v1<br />
                                                                --<br />
                                                                FR_StadeDeFrance_Spec_LadyGaga2023_SVG_v1<br />
                                                                FR_StadeDeFrance_Spec_Coldplay2024_SVG_v1<br />
                                                                --<br />
                                                                FR_StadeDeFrance_Gen_GroundSeating1_PIC_v1<br />
                                                            </Text>
                                                        </HStack>
                                                    </>
                                                )
                                            })}
                                        </TabPanel>


                                        {/* Tab BLOCK */}
                                        <TabPanel minH={400}>
                                            {formFieldsBlocks.map((formFieldsBlock, index) => {
                                                return (
                                                    <>
                                                        <Tabs variant='soft-rounded' colorScheme='teal'>
                                                            <TabList
                                                                overflowX="auto"
                                                                css={css({
                                                                    scrollbarWidth: 'none',
                                                                    '::-webkit-scrollbar': { display: 'none' },
                                                                    '-webkit-overflow-scrolling': 'touch',
                                                                    boxShadow: 'inset 0 -2px 0 rgba(0, 0, 0, 0.1)',
                                                                    border: '0 none',
                                                                })}
                                                            >
                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={600}>Code SVG</Tab>
                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={600}>Assign Block</Tab>
                                                                <Tab _selected={{ color: 'white', bg: 'teal' }} _hover={{ bg: 'gray.300', color: 'black', borderColor: 'gray.300' }} m={1} fontSize={16} fontWeight={600}>Hotmap Define</Tab>
                                                            </TabList>
                                                            <TabPanels>

                                                                {/* SubTab CODE SVG */}
                                                                <TabPanel>
                                                                    <Flex>
                                                                        <Box flex='1' mx='1'>
                                                                            <FormControl>
                                                                                <FormLabel htmlFor='LoadFileSvg' mb='0'>
                                                                                    {T.maps.ImportFileSvg}
                                                                                </FormLabel>
                                                                                <Input
                                                                                    // isInvalid={!form.eventHour ?? checkCanSave()}
                                                                                    name='file'
                                                                                    type='file'
                                                                                    accept=".svg"
                                                                                    onChange={event => svgFileChange(event, index)}
                                                                                    // value={form.eventHour}
                                                                                    focusBorderColor='blue.400'
                                                                                    errorBorderColor='red.300'
                                                                                    size='md'
                                                                                    bg='yellow.100'
                                                                                />
                                                                            </FormControl>

                                                                            <FormControl>
                                                                                <FormLabel htmlFor='LoadFileSvg' mt='5' mb='0'>
                                                                                    {T.maps.ViewFileSvg}
                                                                                </FormLabel>
                                                                                <Textarea
                                                                                    isInvalid={!formFieldsBlocks.svgContent}
                                                                                    name='svgContent'
                                                                                    onChange={event => svgFileChange(event, index)}
                                                                                    value={svgContent}
                                                                                    rows="20"
                                                                                    focusBorderColor='blue.400'
                                                                                    errorBorderColor='red.300'
                                                                                    readOnly
                                                                                />
                                                                            </FormControl>
                                                                        </Box>

                                                                        <Box flex='1' mx='1'>
                                                                            {numPaths > 0 && (
                                                                                <p><strong>path :</strong> {numPaths}</p>
                                                                            )}
                                                                            {numText > 0 && (
                                                                                <p><strong>text :</strong> {numText}</p>
                                                                            )}
                                                                            <FormControl>
                                                                                <FormLabel htmlFor='LoadFileSvg' mt='5' mb='0'>
                                                                                    {T.maps.ViewFileSvg}
                                                                                </FormLabel>
                                                                                <Textarea
                                                                                    value={svgData}
                                                                                    rows="20"
                                                                                />
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Flex>
                                                                </TabPanel>

                                                                {/* SubTab ASSIGN BLOCK */}
                                                                <TabPanel>
                                                                    <Flex>
                                                                        <Box flex='4' mx='1'>

                                                                            <HStack>
                                                                                <FormControl>
                                                                                    <FormLabel htmlFor='MapBlock' mb='0'>
                                                                                        {T.venues.TagAssign}
                                                                                    </FormLabel>
                                                                                    <ButtonGroup size='sm' isAttached variant='outline' colorScheme='teal' mr='5'>
                                                                                        <Text fontSize={15} fontWeight={600} mr="2">TYPE </Text>
                                                                                        <Button mr='-px' onClick={() => addTag('type', 'isBloc')}>isBloc</Button>
                                                                                        <Button mr='-px' onClick={() => addTag('type', 'isStage')}>isStage</Button>
                                                                                        <Button mr='-px' onClick={() => addTag('type', 'isNull')}>isNull</Button>
                                                                                        <Button mr='-px' onClick={() => addTag('type', 'isTextBlack')}>isTextBlack</Button>
                                                                                        <Button mr='-px' onClick={() => addTag('type', 'isTextWhite')}>isTextWhite</Button>
                                                                                    </ButtonGroup>
                                                                                </FormControl>
                                                                            </HStack>

                                                                            <FormControl>
                                                                                <FormLabel htmlFor='MapBlock' mt='5'>
                                                                                    {T.maps.ListBlock}
                                                                                </FormLabel>
                                                                                <Box height='480px' p='1' bg='gray.100' borderWidth='1px' borderRadius='lg' overflowY='scroll'>
                                                                                    <TableContainer>
                                                                                        <Table size='sm'>
                                                                                            <Thead>
                                                                                                <Tr>
                                                                                                    <Th w={50}>
                                                                                                        <IconButton
                                                                                                            size='xs'
                                                                                                            title={T.common.UnselectAll}
                                                                                                            onClick={unselectAllBlocks}
                                                                                                            colorScheme='gray'
                                                                                                            aria-label={T.common.Add}
                                                                                                            icon={<CloseIcon />}
                                                                                                        />
                                                                                                    </Th>

                                                                                                    <Th>ID</Th>
                                                                                                    <Th>TYPE</Th>
                                                                                                    <Th>BLOC</Th>
                                                                                                    <Th>POS</Th>
                                                                                                    <Th>SECTION</Th>
                                                                                                    <Th>NUM</Th>
                                                                                                    <Th>LEVEL</Th>
                                                                                                </Tr>
                                                                                            </Thead>
                                                                                            <Tbody>
                                                                                                {svgBlocs.map((svgBloc, index) => {
                                                                                                    return (
                                                                                                        <Tr key={index} bg={() => blockIsSelect(svgBloc.pathId)} onMouseOver={() => hoverBlock(svgBloc.pathId, "#990000")} onMouseOut={() => hoverBlock(svgBloc.pathId, "none")} _hover={{ color: 'black', border: '2px', borderColor: 'teal.600' }}>
                                                                                                            <Td>
                                                                                                                <Icon as={DragHandleIcon} w={4} h={4} cursor={'pointer'} onClick={() => selectBlock(svgBloc.pathId, index)} />
                                                                                                            </Td>
                                                                                                            <Td>{svgBloc.pathId}</Td>
                                                                                                            <Td>
                                                                                                                <Input
                                                                                                                    isInvalid={!svgBloc.type}
                                                                                                                    errorBorderColor='red.300'
                                                                                                                    name='type'
                                                                                                                    size='sm'
                                                                                                                    w='70px'
                                                                                                                    value={svgBloc.type}
                                                                                                                    isReadOnly
                                                                                                                />
                                                                                                            </Td>
                                                                                                            <Td>
                                                                                                                <Select
                                                                                                                    isInvalid={!svgBloc.blockId}
                                                                                                                    errorBorderColor='red.300'
                                                                                                                    name='blockId'
                                                                                                                    placeholder=' '
                                                                                                                    size='sm'
                                                                                                                    onChange={event => venueBlocFormChange(event, index)}
                                                                                                                    value={svgBloc.blockId}
                                                                                                                    bg='yellow.100'
                                                                                                                >
                                                                                                                    {venueBlocsOptions}
                                                                                                                </Select>
                                                                                                            </Td>
                                                                                                            <Td>
                                                                                                                <Text>{svgBloc.pos}</Text>
                                                                                                            </Td>
                                                                                                            <Td>
                                                                                                                <Text>{svgBloc.section}</Text>
                                                                                                            </Td>
                                                                                                            <Td>
                                                                                                                <Text>{svgBloc.num}</Text>
                                                                                                            </Td>
                                                                                                            <Td>
                                                                                                                <Text>{svgBloc.level}</Text>
                                                                                                            </Td>
                                                                                                        </Tr>
                                                                                                    )
                                                                                                })
                                                                                                }
                                                                                            </Tbody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Box>
                                                                            </FormControl>

                                                                        </Box>

                                                                        <Box flex='2' mx='1'>
                                                                            <FormControl>
                                                                                <FormLabel htmlFor='MapBlock' mb='0'>
                                                                                    {T.maps.MapBlock}
                                                                                </FormLabel>


                                                                                <Square height='600px' p='3' bg='gray.100' borderWidth='1px' borderRadius='lg'>

                                                                                    <React.Fragment>
                                                                                        <div>
                                                                                            {/*
<div onWheelCapture={onScroll}>
<img
src="https://source.unsplash.com/random/300x300?sky"
style={{
transformOrigin: "0 0",
transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`
}}
/>
</div>


[4591, 1182, 1278, 25], [4592, 1189, 1271, 25], [4593, 1203, 1257, 25], [4594, 1210, 1250, 25], [4595, 1217, 1243, 25], [4596, 1223, 1236, 25], [4597, 1230, 1229, 25], [4598, 1244, 1215, 25]

*/}
                                                                                            <TransformWrapper
                                                                                                initialScale={1}
                                                                                                initialPositionX={0}
                                                                                                initialPositionY={0}
                                                                                            >
                                                                                                <TransformComponent>
                                                                                                    <div dangerouslySetInnerHTML={{ __html: svgData }} />
                                                                                                    {/*
                                                                                                    <svg
                                                                                                        id="svgContainer"
                                                                                                        width="600" // Remplacez par la largeur souhaitée
                                                                                                        height="600" // Remplacez par la hauteur souhaitée
                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                        dangerouslySetInnerHTML={{ __html: svgData }} //  onClick={changeColor}
                                                                                                    >
                                                                                                    </svg>
                                                                                                */}
                                                                                                </TransformComponent>
                                                                                            </TransformWrapper>



                                                                                        </div>

                                                                                        {/*}
{seats.map((form, index) => {
return (
<div key={index}>
{index}
{form[0]}
</div>
)
})
}
*/}
                                                                                    </React.Fragment>
                                                                                </Square>


                                                                            </FormControl>

                                                                        </Box>

                                                                    </Flex>
                                                                </TabPanel>

                                                                {/* SubTab HOTMAP DEFINE */}
                                                                <TabPanel>
                                                                    Hotmap

                                                                </TabPanel>

                                                            </TabPanels>
                                                        </Tabs >

                                                    </>
                                                )
                                            })}
                                        </TabPanel>


                                        {/* Tab PICTURE */}
                                        <TabPanel minH={400}>
                                            {formFieldsPictures.map((formFieldsPicture, index) => {
                                                return (
                                                    <>
                                                    </>
                                                )
                                            })}
                                        </TabPanel>


                                        {/* Tab ALIAS */}
                                        <TabPanel minH={400}>
                                            {formFieldsAlias.map((formFieldsAlia, index) => {
                                                return (
                                                    <>
                                                        Alias
                                                    </>
                                                )
                                            })}
                                        </TabPanel>

                                    </TabPanels>
                                </Tabs>

                            </form>

                        </Box>
                    </GridItem>

                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <Box borderRadius='xl' mt={5} p={2} fontSize={14} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%" h={200} overflowY='scroll'>
                            formFieldsMaps = {JSON.stringify(formFieldsMaps)}<br /><br />
                            formFieldsVenues = {JSON.stringify(formFieldsVenues)}<br /><br />
                            formFieldsPictures = {JSON.stringify(formFieldsPictures)}<br /><br />
                            formFieldsBlocks = {JSON.stringify(formFieldsBlocks)}<br /><br />
                            svgBlocs = {JSON.stringify(svgBlocs)}<br /><br />
                            svgBlocsSelected = {JSON.stringify(svgBlocsSelected)}<br /><br />
                            formFieldsAlias = {JSON.stringify(formFieldsAlias)}<br /><br />
                        </Box>
                    </GridItem>

                </Grid>
            </Center>
        </>
    )

    return content
}

export default NewMapForm