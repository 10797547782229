import { useNavigate } from "react-router-dom";
import { useGetVenuesQuery } from "./venuesApiSlice";
import { memo } from "react";
import useLang from "../../hooks/useLang";
import useTranslate from "../../hooks/useTranslate";

// import `ChakraProvider` component
import {
	Icon,
	IconButton,
	Tr,
	Td,
	Tooltip,
	HStack,
	Image,
	Text,
} from "@chakra-ui/react";

// import `ChakraProvider` icon
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";

const Venue = ({ venueId }) => {
	const { lang } = useLang();
	const { T } = useTranslate(lang);

	const { venue } = useGetVenuesQuery("venuesList", {
		selectFromResult: ({ data }) => ({
			venue: data?.entities[venueId],
		}),
	});

	const navigate = useNavigate();

	if (venue) {
		const handleEdit = () => navigate(`/dash/venues/${venueId}`);
		return (
			<Tr _hover={{ color: "#333333", border: "2px", borderColor: "teal.600" }}>
				<Td>
					{venue.venueStatus ? (
						<Icon
							as={CheckIcon}
							w={4}
							h={4}
							color="green.500"
							title={T.common.Actived}
						/>
					) : (
						<Icon
							as={CloseIcon}
							w={4}
							h={4}
							color="red.500"
							title={T.common.Disabled}
						/>
					)}
				</Td>

				<Td>
					<HStack>
						{venue.venuePics ? (
							<Image
								src={`https://api.oracly.svppb.com/images/${venue.venuePics[0]}/display`}
								maxW="75px"
							/>
						) : (
							<Image src="" h="50" />
						)}
						<div>
							<Text fontSize={20} fontWeight={700}>
								{venue.venueName[lang]}
							</Text>
						</div>
					</HStack>
				</Td>

				<Td>{venue.cityName[lang]}</Td>

				<Td>{venue.countryName[lang]}</Td>

				<Td>{venue.marketName[lang]}</Td>

				<Td isNumeric>
					<Tooltip
						hasArrow
						label={T.common.Edit}
						bg="gray.300"
						closeOnClick={true}
						color="black"
						placement="left"
					>
						<IconButton
							colorScheme="gray"
							aria-label="New"
							icon={<EditIcon />}
							onClick={handleEdit}
							_hover={{
								bg: "teal.600",
								color: "white",
								borderColor: "teal.600",
							}}
						/>
					</Tooltip>
				</Td>
			</Tr>
		);
	} else return null;
};

const memoizedVenue = memo(Venue);

export default memoizedVenue;
