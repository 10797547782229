import { useState, useEffect } from 'react';
import { useGetCountriesQuery } from "./countriesApiSlice"
import Country from "./Country"
import { Link } from 'react-router-dom'
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

// Load screen
import { LoadingScreen } from "./../../screens";

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, Stack, HStack, Spacer, Container, Alert, AlertIcon, Badge, Icon, Image, Button, Text, useToast, useDisclosure } from '@chakra-ui/react'
import { TableContainer, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react'

// import `ChakraProvider` icon
import { AddIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons"

const CountriesList = () => {
    useTitle('ORACLY: Countries List')

    const [loading, setLoading] = useState(true);

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const { username, isEmployee, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const {
        data: countries,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetCountriesQuery('countriesList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let menuContent
    if (isManager || isAdmin) {
        menuContent = (
            <>
                <Link to="/dash/countries/new">
                    <Button
                        size='sm'
                        leftIcon={<AddIcon />}
                        colorScheme='teal'
                        variant='solid'>
                        <Text
                            color='white'
                            display={{ base: 'none', sm: 'flex' }}
                            fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
                            fontWeight={600}
                        >
                            {T.common.Add}
                        </Text>
                    </Button>
                </Link>
            </>
        )
    }
    if (isEmployee) {
    }

    let content
    if (isLoading) {
        content = (
            <>
                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                    <Stack spacing={3}>
                        <PulseLoader color={"#FFF"} />
                    </Stack>
                </Box>
            </>
        )
    }

    if (isError) {
        if (isManager || isAdmin) {
            toastInfo({ title: 'LOAD : ' + error?.data?.message, status: 'info' })
            content = (
                <>
                    <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                        <Stack spacing={3}>
                            <Alert status='error'>
                                <AlertIcon />
                                {error?.data?.message}
                            </Alert>
                        </Stack>
                    </Box>
                </>
            )
        } else {
            toastInfo({ title: 'LOAD : ' + error?.data?.message, status: 'info' })
            content = (
                <>
                    <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                        <Stack spacing={3}>
                            <Alert status='error'>
                                <AlertIcon />
                                {error?.data?.message}
                            </Alert>
                        </Stack>
                    </Box>
                </>
            )
        }
    }

    if (isSuccess) {
        const { ids, entities } = countries

        let filteredIds
        if (isEmployee || isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(countryId => entities[countryId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(countryId => <Country key={countryId} countryId={countryId} />)

        content = (
            <>
                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                    <TableContainer>
                        <Table
                            variant='striped'
                            size='sm'
                        >
                            <Thead>
                                <Tr>
                                    <Th w='30px'></Th>
                                    <Th>{T.countries.CountryName}</Th>
                                    <Th>{T.countries.MarketName}</Th>
                                    <Th isNumeric>{T.countries.Action}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {tableContent}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </>
        )
    }

    let body = (
        <>
            {!loading ? (
                <Center bg='#45413C' p='0' color='white' axis='both'>
                    <Grid w='95vw' maxW='1500' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                            <HStack color='white'>
                                <Box p='2' color='262626' textAlign={['left']} >
                                    <Text fontSize={{ base: "20", sm: "20", md: "20", lg: "24", xl: "28" }} fontWeight={700}><FontAwesomeIcon icon={faEarthAmericas} /> {T.countries.CountriesList}</Text><br />
                                </Box>
                                <Spacer />
                                <Box p='2' color='262626' textAlign={['right']}>
                                    {menuContent}
                                </Box>
                            </HStack>
                            {content}
                        </GridItem>
                    </Grid>
                </Center>
            ) : (
                <LoadingScreen section='COUNTRIES' />
            )}
        </>
    )

    return body
}
export default CountriesList