import { useState, useEffect } from "react"
import { useUpdateCityMutation, useDeleteCityMutation } from "./citiesApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, Container, Input, Select, Switch, Button, IconButton, Text, useToast } from '@chakra-ui/react'
import { FormControl, FormLabel } from "@chakra-ui/react"

// import `ChakraProvider` icon
import { ArrowBackIcon, CheckIcon, CloseIcon, AddIcon, MinusIcon, ExternalLinkIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity } from "@fortawesome/free-solid-svg-icons"

const EditCityForm = ({ city, countries }) => {

    const { isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const [updateCity, {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCityMutation()

    const [deleteCity, {
        data: delData,
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delError
    }] = useDeleteCityMutation()

    const navigate = useNavigate()

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener noreferrer nofollow').opener = null;
    };

    let [canSave, setCanSave] = useState(false)

    const [cityStatus, setCityStatus] = useState(city.cityStatus)
    const [countryId, setCountryId] = useState(city.countryId)
    const [cityTimezoneUTC, setCityTimezoneUTC] = useState(city.cityTimezoneUTC)

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    const checkCanSave = () => {
        {
            setCanSave(true)
            if ((countryId == '' || cityTimezoneUTC == '')) {
                setCanSave(false)
            }
            formFieldsCityName.map((form) => {
                if (!isLoading && (form.lang == '' || form.cityName == '')) {
                    setCanSave(false)
                }
            })
        }
    }

    const onPageBack = () => navigate('/dash/cities')

    const onCountryIdChanged = e => setCountryId(e.target.value)
    const onCityStatusChanged = e => setCityStatus(prev => !prev)
    const onCityTimezoneUTCChanged = e => setCityTimezoneUTC(e.target.value)

    const onSaveCityClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            let output = []
            let cityNameByLang = {}
            formFieldsCityName.forEach(field => {
                cityNameByLang[field.lang] = field.cityName
            })
            output['cityName'] = cityNameByLang
            console.log(output)

            await updateCity({ id: city.id, countryId: countryId, cityName: cityNameByLang, cityTimezoneUTC, cityStatus })
        }
    }

    const onDeleteCityClicked = async () => {
        await deleteCity({ id: city.id })
    }

    const created = new Date(city.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })
    const updated = new Date(city.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })

    const options = countries.map(country => {
        return (
            <option
                key={country.id}
                value={country.id}
            > {country.countryName[lang]}</option >
        )
    })

    const timeZones = [
        { name: 'UTC-12:00 : Eniwetok, Kwajalein', value: 'UTC-12:00' },
        { name: 'UTC-11:00 : Midway Island, Samoa', value: 'UTC-11:00' },
        { name: 'UTC-10:00 : Hawaii', value: 'UTC-10:00' },
        { name: 'UTC-09:50 : Taiohae', value: 'UTC-9:50' },
        { name: 'UTC-09:00 : Alaska', value: 'UTC-9:00' },
        { name: 'UTC-08:00 : Pacific Time (US & Canada)', value: 'UTC-8:00' },
        { name: 'UTC-07:00 : Mountain Time (US & Canada)', value: 'UTC-7:00' },
        { name: 'UTC-06:00 : Central Time (US & Canada), Mexico City', value: 'UTC-6:00' },
        { name: 'UTC-05:00 : Eastern Time (US & Canada), Bogota, Lima', value: 'UTC-5:00' },
        { name: 'UTC-04:50 : Caracas', value: 'UTC-4:50' },
        { name: 'UTC-04:00 : Atlantic Time (Canada), Caracas, La Paz', value: 'UTC-4:00' },
        { name: 'UTC-03:50 : Newfoundland', value: 'UTC-3:50' },
        { name: 'UTC-03:00 : Brazil, Buenos Aires, Georgetown', value: 'UTC-3:00' },
        { name: 'UTC-02:00 : Mid-Atlantic', value: 'UTC-2:00' },
        { name: 'UTC-01:00 : Azores, Cape Verde Islands', value: 'UTC-1:00' },
        { name: 'UTC+00:00 : Western Europe Time, London, Lisbon, Casablanca', value: 'UTC+0:00' },
        { name: 'UTC+01:00 : Brussels, Copenhagen, Madrid, Paris', value: 'UTC+1:00' },
        { name: 'UTC+02:00 : Kaliningrad, South Africa', value: 'UTC+2:00' },
        { name: 'UTC+03:00 : Baghdad, Riyadh, Moscow, St. Petersburg', value: 'UTC+3:00' },
        { name: 'UTC+03:50 : Tehran', value: 'UTC+3:50' },
        { name: 'UTC+04:00 : Abu Dhabi, Muscat, Baku, Tbilisi', value: 'UTC+4:00' },
        { name: 'UTC+04:50 : Kabul', value: 'UTC+4:50' },
        { name: 'UTC+05:00 : Ekaterinburg, Islamabad, Karachi, Tashkent', value: 'UTC+5:00' },
        { name: 'UTC+05:50 : Bombay, Calcutta, Madras, New Delhi', value: 'UTC+5:50' },
        { name: 'UTC+05:75 : (Kathmandu, Pokhara', value: 'UTC+5:75' },
        { name: 'UTC+06:00 : Almaty, Dhaka, Colombo', value: 'UTC+6:00' },
        { name: 'UTC+06:50 : Yangon, Mandalay', value: 'UTC+6:50' },
        { name: 'UTC+07:00 : Bangkok, Hanoi, Jakarta', value: 'UTC+7:00' },
        { name: 'UTC+08:00 : Beijing, Perth, Singapore, Hong Kong', value: 'UTC+8:00' },
        { name: 'UTC+08:75 : Eucla', value: 'UTC+8:75' },
        { name: 'UTC+09:00 : Tokyo, Seoul, Osaka, Sapporo, Yakutsk', value: 'UTC+9:00' },
        { name: 'UTC+09:50 : Adelaide, Darwin', value: 'UTC+9:50' },
        { name: 'UTC+10:00 : Eastern Australia, Guam, Vladivostok', value: 'UTC+10:00' },
        { name: 'UTC+10:50 : Lord Howe Island', value: 'UTC+10:50' },
        { name: 'UTC+11:00 : Magadan, Solomon Islands, New Caledonia', value: 'UTC+11:00' },
        { name: 'UTC+11:50 : Norfolk Island', value: 'UTC+11:50' },
        { name: 'UTC+12:00 : Auckland, Wellington, Fiji, Kamchatka', value: 'UTC+12:00' },
        { name: 'UTC+12:75 : Chatham Islands', value: 'UTC+12:75' },
        { name: 'UTC+13:00 : Apia, Nukualofa', value: 'UTC+13:00' },
        { name: 'UTC+14:00 : Line Islands, Tokelau', value: 'UTC+14:00' },
    ]
    const options_timeZones = timeZones.map((timeZone, index) => {
        return (
            <option
                key={index}
                value={timeZone.value}
            > {timeZone.name}</option >
        )
    })

    let deleteButton = null
    if (isManager || isAdmin) {
        deleteButton = (
            <Button size='xs' onClick={onDeleteCityClicked} name="Delete" title={T.common.Delete} leftIcon={<CloseIcon />} colorScheme='red' variant='outline'>{T.cities.DeleteThisCity}</Button>
        )
    }

    const initCityNamesForm = () => {
        let cityNameByLang = []
        Object.entries(city.cityName).map(item => {
            cityNameByLang.push({ lang: item[0], cityName: item[1] })
        })
        return cityNameByLang
    }

    const [formFieldsCityName, setFormFields] = useState(initCityNamesForm)

    const handleFormChange = (event, index) => {
        let data = [...formFieldsCityName];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
        checkCanSave()
    }

    const addFields = () => {
        let object = {
            lang: '',
            cityName: ''
        }
        setFormFields([...formFieldsCityName, object])
        setCanSave(false)
    }

    const removeFields = (index) => {
        let data = [...formFieldsCityName];
        data.splice(index, 1)
        setFormFields(data)
        checkCanSave()
    }

    useEffect(() => {
        checkCanSave()
        if (isSuccess) {
            toastInfo({ title: 'CITY : ' + data?.message + ' successfully', status: 'success' })
            initCityNamesForm()
            setCountryId('')
            navigate('/dash/cities')
        }
        if (isError) {
            toastInfo({ title: 'CITY : Update error (' + error?.data?.message + ')', status: 'error' })
        }
        if (isDelSuccess) {
            toastInfo({ title: 'CITY : ' + delData?.message + ' successfully', status: 'success' })
            initCityNamesForm()
            navigate('/dash/cities')
        }
        if (isDelError) {
            toastInfo({ title: 'CITY : Update error (' + delError?.data?.message + ')', status: 'error' })
        }
    }, [checkCanSave, initCityNamesForm, toastInfo, isError, error, isDelError, delError, isSuccess, isDelSuccess, navigate])

    const content = (
        <>
            <Center bg='#45413C' p='0' color='white' axis='both'>
                <Grid w='95vw' maxW='1500' minH='100vh' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                    <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                        <HStack color='white'>
                            <Container color='262626' textAlign={['left']} >
                                <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faCity} /> {T.cities.EditCity}</Text><br />
                            </Container>
                            <Container color='262626' textAlign={['right']}>
                                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                                </Button>
                                <Button
                                    size='sm'
                                    onClick={onSaveCityClicked}
                                    disabled={!canSave}
                                    leftIcon={<CheckIcon />}
                                    colorScheme={canSave ? "teal" : "gray"}
                                >
                                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Save}</Text>
                                </Button>
                            </Container>
                        </HStack>
                        <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' borderColor='black' bg='white' w="100%">

                            <form onSubmit={e => e.preventDefault()}>
                                <FormControl>

                                    <FormLabel htmlFor='countryTranslate' mt='5' mb='0'>
                                        {T.cities.CityName}
                                    </FormLabel>
                                    {formFieldsCityName.map((form, index) => {
                                        return (
                                            <HStack key={index}>
                                                <Input
                                                    isInvalid={!form.lang}
                                                    name='lang'
                                                    placeholder={T.cities.IsoCode}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.lang}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    minLength="2"
                                                    maxLength="2"
                                                    bg='yellow.100'
                                                    w='10vw'
                                                />
                                                <Input
                                                    isInvalid={!form.cityName}
                                                    name='cityName'
                                                    placeholder={T.cities.TranslatedName}
                                                    onChange={event => handleFormChange(event, index)}
                                                    value={form.cityName}
                                                    focusBorderColor='blue.400'
                                                    errorBorderColor='red.300'
                                                    bg='yellow.100'
                                                    w='50vw'
                                                />
                                                {index !== 0 &&
                                                    <IconButton
                                                        size='xs'
                                                        name="Remove"
                                                        title={T.common.Delete}
                                                        colorScheme='gray'
                                                        aria-label={T.common.Delete}
                                                        icon={<MinusIcon />}
                                                        onClick={() => removeFields(index)}
                                                    />
                                                }
                                            </HStack>
                                        )
                                    })
                                    }
                                    <IconButton
                                        size='xs'
                                        title={T.common.Add}
                                        onClick={addFields}
                                        colorScheme='gray'
                                        aria-label={T.common.Add}
                                        icon={<AddIcon />}
                                    />

                                    <HStack>
                                        <FormControl>
                                            <FormLabel htmlFor='countryId' mt='5' mb='0'>
                                                {T.cities.CountryArea}
                                            </FormLabel>
                                            <Select
                                                id="countryId"
                                                name="countryId"
                                                className="form__select"
                                                value={countryId}
                                                onChange={onCountryIdChanged}
                                                bg='yellow.100'
                                            >
                                                {options}
                                            </Select>
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel htmlFor='cityTimezoneUTC' mt='5' mb='0'>
                                                {T.cities.TimezoneUTC}
                                                <IconButton
                                                    size='xs'
                                                    colorScheme='gray'
                                                    aria-label='info timezoneUTC'
                                                    icon={<ExternalLinkIcon />}
                                                    onClick={() => openInNewTab('https://time.is/fr/time_zones')}
                                                    ml='2'
                                                />
                                            </FormLabel>
                                            <Select
                                                isInvalid={!cityTimezoneUTC ?? checkCanSave()}
                                                id="cityTimezoneUTC"
                                                name="cityTimezoneUTC"
                                                placeholder=" "
                                                value={cityTimezoneUTC}
                                                onChange={onCityTimezoneUTCChanged}
                                                bg='yellow.100'
                                            >
                                                {options_timeZones}
                                            </Select>
                                        </FormControl>
                                    </HStack>



                                    <FormLabel htmlFor='cityStatus' mt='5' mb='0'>
                                        {T.cities.Status}
                                    </FormLabel>
                                    <Switch
                                        id='cityStatus'
                                        name="status"
                                        colorScheme='green'
                                        isChecked={cityStatus}
                                        onChange={onCityStatusChanged}
                                    />

                                </FormControl>
                                <br />

                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Created}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{created}</Text>
                                </HStack>
                                <HStack>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }} fontWeight={600}>{T.common.Updated}:</Text>
                                    <Text fontSize={{ base: "10", sm: "10", md: "10", lg: "15", xl: "15" }}>{updated}</Text>
                                </HStack>
                            </form>

                            {/*}
            <form onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h2>{T.cities.EditCity}</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title={T.common.Save}
                            name="Save"
                            onClick={onSaveCityClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                        <button
                            className="icon-button"
                            title={T.common.Back}
                            name="Back"
                            onClick={onPageBack}
                        >
                            <FontAwesomeIcon icon={faCircleXmark} />
                        </button>
                    </div>
                </div>
                <label className="form__label" htmlFor="cityName">
                    {T.cities.CityName}:</label>

                {formFieldsCityName.map((form, index) => {
                    return (
                        <div key={index}>
                            <input
                                className={form.lang == '' ? 'form__input form__input--incomplete' : 'form__input'}
                                name='lang'
                                placeholder={T.cities.IsoCode}
                                onChange={event => handleFormChange(event, index)}
                                value={form.lang}
                            />
                            <input
                                className={form.cityName == '' ? 'form__input form__input--incomplete' : 'form__input'}
                                name='cityName'
                                placeholder={T.cities.TranslatedName}
                                onChange={event => handleFormChange(event, index)}
                                value={form.cityName}
                            />
                            {index != 0 &&
                                <button
                                    className="icon-button"
                                    name="Remove"
                                    title={T.common.Delete}
                                    onClick={() => removeFields(index)}
                                >
                                    <FontAwesomeIcon icon={faSquareMinus} />
                                </button>
                            }

                        </div>
                    )
                })
                }
                <button
                    className="icon-button"
                    title={T.common.Add}
                    onClick={addFields}
                >
                    <FontAwesomeIcon icon={faSquarePlus} />
                </button>

                <label className="form__label form__checkbox-container" htmlFor="city-cityname">
                    {T.cities.CountryArea}:</label>
                <select
                    id="countryId"
                    name="countryId"
                    className="form__select"
                    value={countryId}
                    onChange={onCountryIdChanged}
                >
                    {options}
                </select>

                <div className="form__row">
                    <div className="form__divider">
                        <label className="form__label form__checkbox-container" htmlFor="city-status">
                            {T.cities.Status}:
                            <input
                                className="form__checkbox"
                                id="city-status"
                                name="cityStatus"
                                type="checkbox"
                                checked={cityStatus}
                                onChange={onCityStatusChanged}
                            />
                        </label>
                    </div>
                    <div className="form__divider">
                        <p className="form__created">{T.common.Created}:<br />{created}</p>
                        <p className="form__updated">{T.common.Updated}:<br />{updated}</p>
                    </div>
                </div>
            </form>

*/}


                            <br />
                            <br />
                            {deleteButton}

                        </Box>
                    </GridItem>

                </Grid>
            </Center>

        </>
    )

    return content
}

export default EditCityForm