import React, { useState, useEffect } from "react";
import {
	useUpdateVenueMutation,
	useDeleteVenueMutation,
} from "./venuesApiSlice";
import { useNavigate } from "react-router-dom";
import VenueUploadForm from "./VenueUploadForm";
import useAuth from "../../hooks/useAuth";
import useLang from "../../hooks/useLang";
import useTranslate from "../../hooks/useTranslate";

// import `ChakraProvider` component
import {
	css,
	Box,
	Grid,
	GridItem,
	Center,
	HStack,
	Container,
	Tag,
	Input,
	Textarea,
	Select,
	Spacer,
	Switch,
	ButtonGroup,
	Button,
	IconButton,
	Icon,
	Text,
	useToast,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import {
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
} from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/react";

// import `ChakraProvider` icon
import {
	ArrowBackIcon,
	CheckIcon,
	CloseIcon,
	AddIcon,
	MinusIcon,
	DragHandleIcon,
	DownloadIcon,
} from "@chakra-ui/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRing } from "@fortawesome/free-solid-svg-icons";

const EditVenueForm = ({ venue, cities, sources }) => {
	const { isManager, isAdmin } = useAuth();

	const { lang } = useLang();
	const { T } = useTranslate(lang);

	const [updateVenue, { data, isLoading, isSuccess, isError, error }] =
		useUpdateVenueMutation();

	const [
		deleteVenue,
		{
			data: delData,
			isSuccess: isDelSuccess,
			isError: isDelError,
			error: delError,
		},
	] = useDeleteVenueMutation();

	const navigate = useNavigate();

	const tagsPoss = [{ name: "isSeating" }, { name: "isStanding" }];
	const tagsSections = [
		{ name: "isGround" },
		{ name: "isLower" },
		{ name: "isMiddle" },
		{ name: "isUpper" },
	];
	const tagsNums = [
		{ name: "isFree" },
		{ name: "isMix" },
		{ name: "isPair" },
		{ name: "isOdd" },
	];
	const tagsLevels = [
		{ name: "isL00" },
		{ name: "isL01" },
		{ name: "isL02" },
		{ name: "isL03" },
		{ name: "isL04" },
		{ name: "isL05" },
		{ name: "isL06" },
		{ name: "isL07" },
		{ name: "isL08" },
		{ name: "isL09" },
		{ name: "isL10" },
	];

	const [venueCapacity, setVenueCapacity] = useState(venue.venueCapacity);
	const [venueLevel, setVenueLevel] = useState(venue.venueLevel);
	const [venueType, setVenueType] = useState(venue.venueType);
	const [cityId, setCityId] = useState(venue.cityId);
	const [venuePics, setVenuePics] = useState(venue.venuePics || []);
	const [venueStatus, setVenueStatus] = useState(venue.venueStatus);

	const updateVenuePics = (newValue) => {
		setVenuePics([...venuePics, ...newValue]);
	};

	const deleteVenuePics = (e) => {
		const t = venuePics.filter((item, index) => index !== e);
		setVenuePics(t);
	};

	const toastInfo = useToast({
		position: "bottom-left",
		variant: "left-accent",
	});

	let [canSave, setCanSave] = useState(false);
	const checkCanSave = () => {
		setCanSave(true);

		if (
			venueCapacity === "" ||
			venueLevel === "" ||
			venueType === "" ||
			cityId === ""
		) {
			setCanSave(false);
		}

		formFieldsVenueNames.forEach((form) => {
			if (!isLoading && (form.lang === "" || form.venueName === "")) {
				setCanSave(false);
			}
		});

		formFieldsVenueAliasName.forEach((form) => {
			if (!isLoading && form.venueNameAlias === "") {
				setCanSave(false);
			}
		});

		formFieldsVenueBlocs.forEach((form) => {
			if (!isLoading && (form.nameEN === "" || form.nameLocal === "")) {
				setCanSave(false);
			}
		});
	};

	let [canImportBlock, setCanImportBlock] = useState(false);
	const checkCanImportBlock = () => {
		setCanImportBlock(true);
		if (
			formFieldsVenueBlocsImports[0].blockCodeSourceType === "" ||
			formFieldsVenueBlocsImports[0].blockCodeImportPreview === ""
		) {
			setCanImportBlock(false);
		} else {
			setCanImportBlock(true);
		}
	};

	let [canImportBlockAlias, setCanImportBlockAlias] = useState(false);
	const checkCanImportBlockAlias = () => {
		/*
        if (formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceType == '') {
            alert('blockAliasCodeSourceType null')
        } else {
            alert('blockAliasCodeSourceType not null')
        }

        if (formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceId == '') {
            alert('blockAliasCodeSourceId null')
        } else {
            alert('blockAliasCodeSourceId not null')
        }

        if (formFieldsVenueBlocsAliasImports[0].blockAliasCodeImportPreview == '') {
            alert('blockAliasCodeImportPreview null')
        } else {
            alert('blockAliasCodeImportPreview not null')
        }
        */

		setCanImportBlockAlias(true);
		if (
			formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceType === "" ||
			formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceId === "" ||
			formFieldsVenueBlocsAliasImports[0].blockAliasCodeImportPreview === ""
		) {
			setCanImportBlockAlias(false);
		} else {
			setCanImportBlockAlias(true);
			//alert('canImport')

			/*
            let str = formFieldsVenueBlocsAliasImports[0].blockAliasCodeImport
            //let str = formFieldsVenueBlocsAliasImports
            if (str != '') {
                alert('str = ' + JSON.stringify(str))

                const regex = /<option value="\"([^"]+)\"([^<>]+)">([^<>]+)<\/option>/gmi;
                let m
                let preview = ''
                while ((m = regex.exec(str)) !== null) {
                    // This is necessary to avoid infinite loops with zero-width matches
                    if (m.index === regex.lastIndex) {
                        regex.lastIndex++;
                    }
                    preview = preview + m[1] + " = " + m[3] + "\n"

                    // alert(m[1] + " " + m[3]);
                    // The result can be accessed through the `m`-variable.

                    //alert('m = ' + m)
                    m.forEach((match, groupIndex) => {
                        console.log(`Found match, group ${groupIndex}: ${match}`);
                    });
                }
                //alert('m.length = ' + m.length)
                //alert('preview = ' + preview);

                if (preview != '') {
                    alert('preview = ' + preview)
                    let data = [...formFieldsVenueBlocsAliasImports];
                    alert('data0 = ' + JSON.stringify(data));
                    Object.keys(data[0]).forEach(key => {
                        if (key === 'blockAliasCodeImportPreview') {
                            alert('key = ' + key);
                            alert('preview maj');
                            alert('preview =' + preview);
                            data = { ...data[0], 'blockAliasCodeImport': str, 'blockAliasCodeImportPreview': preview }
                            //alert('data[0] = ' + JSON.stringify(data[0]));
                            alert('data = ' + JSON.stringify(data));
                            //alert('[data[0]] = ' + JSON.stringify(data));
                            setFormFieldsVenueAliasBlocsImports([data]);
                        }
                    });
                }

            }
            */
		}
	};

	const onPageBack = () => navigate("/dash/venues");

	const onVenueCapacityChanged = (e) => setVenueCapacity(e.target.value);
	const onVenueLevelChanged = (e) => setVenueLevel(e.target.value);
	const onVenueTypeChanged = (e) => setVenueType(e.target.value);
	const onCityIdChanged = (e) => setCityId(e.target.value);
	const onVenueStatusChanged = (e) => setVenueStatus((prev) => !prev);

	const onSaveVenueClicked = async (e) => {
		e.preventDefault();
		if (canSave) {
			let output = [];
			let venueNameByLang = {};

			formFieldsVenueNames.forEach((formFieldsVenueName) => {
				venueNameByLang[formFieldsVenueName.lang] =
					formFieldsVenueName.venueName;
			});
			output["name"] = venueNameByLang;

			let venueNameAlias = {};
			formFieldsVenueAliasName.forEach((field, index) => {
				venueNameAlias[index] = field.venueNameAlias;
			});
			output["aliasName"] = venueNameAlias;

			let venueBlocs = [];
			formFieldsVenueBlocs.forEach((formFieldsVenueBloc, index) => {
				venueBlocs.push({
					blockId: formFieldsVenueBloc.blockId,
					nameEN: formFieldsVenueBloc.nameEN,
					nameLocal: formFieldsVenueBloc.nameLocal,
					tagPos: formFieldsVenueBloc.tagPos,
					tagSection: formFieldsVenueBloc.tagSection,
					tagNum: formFieldsVenueBloc.tagNum,
					tagLevel: formFieldsVenueBloc.tagLevel,
				});
			});
			output["blocs"] = venueBlocs;

			let venueBlocsAlias = [];
			formFieldsVenueBlocsAlias.forEach((formFieldsVenueBlocsAlia, index) => {
				venueBlocsAlias.push({
					blockAliasId: formFieldsVenueBlocsAlia.blockAliasId,
					blockId: formFieldsVenueBlocsAlia.blockId,
					sourceId: formFieldsVenueBlocsAlia.sourceId,
					blockAliasLabel: formFieldsVenueBlocsAlia.blockAliasLabel,
					blockAliasCode: formFieldsVenueBlocsAlia.blockAliasCode,
				});
			});
			output["blocsAlias"] = venueBlocsAlias;

			console.log(output);

			await updateVenue({
				id: venue.id,
				cityId: cityId,
				venueName: venueNameByLang,
				venueNameAlias: venueNameAlias,
				venueLevel,
				venueType,
				venueCapacity,
				venueBlocs,
				venueBlocsAlias: venueBlocsAlias,
				venueStatus,
				venuePics: venuePics,
			});
		}
	};

	const onImportBlockSaveVenueClicked = async (e) => {
		e.preventDefault();
		if (canImportBlock) {
			if (
				formFieldsVenueBlocsImports[0].blockCodeSourceType !== "" &&
				formFieldsVenueBlocsImports[0].blockCodeImport !== ""
			) {
				switch (formFieldsVenueBlocsImports[0].blockCodeSourceType) {
					// Extract from JSON
					case "isJSON":
						// code
						break;

					// Extract from CSV
					case "isCSV":
						// code
						break;

					// Extract from OPTION
					case "isOPTION":
						let str = formFieldsVenueBlocsImports[0].blockCodeImport;
						const regex =
							/<option value=""([^"]+)"([^<>]+)">([^<>]+)<\/option>/gim;
						let m;
						let data = [...formFieldsVenueBlocs];
						while ((m = regex.exec(str)) !== null) {
							// This is necessary to avoid infinite loops with zero-width matches
							if (m.index === regex.lastIndex) {
								regex.lastIndex++;
							}
							data.push({
								blockId: getNewIdBlock(),
								nameEN: m[3],
								nameLocal: m[3],
							});
						}
						setFormFieldsVenueBlocs(data);
						break;

					default:
					// code
				}
				checkCanImportBlock();
			}
		}
	};

	const onImportBlockAliasSaveVenueClicked = async (e) => {
		e.preventDefault();
		if (canImportBlockAlias) {
			if (
				formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceType !== "" &&
				formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceId !== "" &&
				formFieldsVenueBlocsAliasImports[0].blockAliasCodeImport !== ""
			) {
				switch (formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceType) {
					// Extract from JSON
					case "isJSON":
						// code
						break;

					// Extract from CSV
					case "isCSV":
						// code
						break;

					// Extract from OPTION
					case "isOPTION":
						let str = formFieldsVenueBlocsAliasImports[0].blockAliasCodeImport;
						const regex =
							/<option value=""([^"]+)"([^<>]+)">([^<>]+)<\/option>/gim;
						let m;
						let data = [...formFieldsVenueBlocsAlias];
						while ((m = regex.exec(str)) !== null) {
							// This is necessary to avoid infinite loops with zero-width matches
							if (m.index === regex.lastIndex) {
								regex.lastIndex++;
							}
							data.push({
								blockAliasId: getNewIdBlock(),
								blockId: "",
								sourceId:
									formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceId,
								blockAliasLabel: m[3],
								blockAliasCode: m[1],
							});
						}
						setFormFieldsVenueAliasBlocs(data);
						break;

					default:
					// code
				}
				checkCanImportBlockAlias();
			}
		}
	};

	const onDeleteVenueClicked = async () => {
		await deleteVenue({ id: venue.id });
	};

	const created = new Date(venue.createdAt).toLocaleString("en-US", {
		day: "numeric",
		month: "long",
		year: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	});
	const updated = new Date(venue.updatedAt).toLocaleString("en-US", {
		day: "numeric",
		month: "long",
		year: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	});

	const venueLevels = [
		{ name: "1 level", value: "1" },
		{ name: "2 levels", value: "2" },
		{ name: "3 levels", value: "3" },
		{ name: "4 levels", value: "4" },
		{ name: "5 levels", value: "5" },
		{ name: "6 levels", value: "6" },
		{ name: "7 levels", value: "7" },
		{ name: "8 levels", value: "8" },
		{ name: "9 levels", value: "9" },
		{ name: "10 levels", value: "10" },
	];
	const options_venueLevel = venueLevels.map((venueLevel) => {
		return (
			<option key={venueLevel.value} value={venueLevel.value}>
				{venueLevel.name}
			</option>
		);
	});

	const venueTypes = [
		{ name: "stadium" },
		{ name: "arena" },
		{ name: "theater" },
		{ name: "area" },
	];
	const options_venueType = venueTypes.map((type) => {
		return (
			<option key={type.name} value={type.name}>
				{" "}
				{type.name}
			</option>
		);
	});

	const options_cityArea = cities.map((city) => {
		return (
			<option key={city.id} value={city.id}>
				{" "}
				{city.cityName[lang]}
			</option>
		);
	});

	let deleteButton = null;
	if (isManager || isAdmin) {
		deleteButton = (
			<Button
				size="xs"
				onClick={onDeleteVenueClicked}
				name="Delete"
				title={T.common.Delete}
				leftIcon={<CloseIcon />}
				colorScheme="red"
				variant="outline"
			>
				{T.venues.DeleteThisVenue}
			</Button>
		);
	}

	const initVenueNamesForm = () => {
		let venueNameByLang = [];
		Object.entries(venue.venueName).forEach((item) => {
			venueNameByLang.push({ lang: item[0], venueName: item[1] });
		});
		console.log(JSON.stringify(venueNameByLang));
		return venueNameByLang;
	};
	const [formFieldsVenueNames, setFormFields] = useState(initVenueNamesForm);
	const handleFormChange = (event, index) => {
		let data = [...formFieldsVenueNames];
		data[index][event.target.name] = event.target.value;
		setFormFields(data);
		checkCanSave();
	};
	const addFields = () => {
		let object = {
			lang: "",
			venueName: "",
		};
		setFormFields([...formFieldsVenueNames, object]);
		setCanSave(false);
	};
	const removeFields = (index) => {
		let data = [...formFieldsVenueNames];
		data.splice(index, 1);
		setFormFields(data);
		checkCanSave();
	};

	const initVenueBlocsForm = () => {
		let venueBlocs = [];
		if (venue.venueBlocs !== undefined) {
			//            alert('venue.venueBlocs = ' + JSON.stringify(venue.venueBlocs))
			//alert('venue.venueBlocs = ' + JSON.stringify(venue.venueBlocs))
			//            Object.entries(venue.venueBlocs).map(venueBloc => {
			//                alert('venueBloc = ' + JSON.stringify(venueBloc))
			//                venueBlocs.push(venueBloc)
			//            })
			//            console.log(JSON.stringify(venueBlocs))
			venueBlocs = venue.venueBlocs;
		} else {
			//            venueBloc.push({ lang: venueBloc })
		}
		return venueBlocs;
	};
	const [formFieldsVenueBlocs, setFormFieldsVenueBlocs] =
		useState(initVenueBlocsForm);
	const [formFieldsBlocks, setFormFieldsBlocks] = useState([]);
	const blockFormChange = (event, index) => {
		let data = [...formFieldsVenueBlocs];
		Object.keys(data[index]).forEach((key) => {
			if (key === event.target.name) {
				data[index] = { ...data[index], [key]: event.target.value };
			}
			if (event.target.name === "nameEN") {
				data[index] = {
					...data[index],
					nameEN: event.target.value,
					nameLocal: event.target.value,
				};
			}
		});
		setFormFieldsVenueBlocs(data);
		checkCanSave();
	};
	const addFields_bloc = () => {
		let object = {
			blockId: getNewIdBlock(),
			nameEN: "",
			nameLocal: "",
		};
		setFormFieldsVenueBlocs([...formFieldsVenueBlocs, object]);
		setCanSave(false);
	};
	const removeFields_bloc = (index) => {
		let data = [...formFieldsVenueBlocs];
		data.splice(index, 1);
		setFormFieldsVenueBlocs(data);
		checkCanSave();
	};
	const getNewIdBlock = () => {
		return Date.now() + Math.floor(Math.random() * 1001);
	};

	const unselectAllBlocks = (blockId, index) => {
		setFormFieldsBlocks([]);
	};

	const selectBlock = (blockId, index) => {
		//        alert('index  = ' + index)
		//        alert('blockId  = ' + blockId)
		let data = [...formFieldsBlocks];

		//alert('formFieldsBlocks = ' + JSON.stringify(formFieldsBlocks))
		//alert('formFieldsBlocks[index] = ' + JSON.stringify(formFieldsBlocks[index].key))
		if (formFieldsBlocks.length > 0) {
			//            alert('formFieldsBlocks.length = ' + formFieldsBlocks.length)
			for (var i = 0; i < formFieldsBlocks.length; i++) {
				if (formFieldsBlocks[i].blockId === blockId) {
					//                    alert('find')
					data.splice(i, 1);
					return setFormFieldsBlocks(data);
				}
			}
			//            alert('no find')
			//            data[index] = blockId;
			let object = {
				blockId: blockId,
			};
			setFormFieldsBlocks([...formFieldsBlocks, object]);
		}

		if (formFieldsBlocks.length === 0) {
			//            alert('no find')
			//            data[index] = blockId;
			let object = {
				blockId: blockId,
			};
			setFormFieldsBlocks([...formFieldsBlocks, object]);
			//setFormFieldsBlocks(data)
			//            alert('formFieldsBlocks = ' + JSON.stringify(formFieldsBlocks))
		}
	};
	const addTag = (tagName, tagValue) => {
		let data = [...formFieldsVenueBlocs];
		for (var i = 0; i < formFieldsBlocks.length; i++) {
			//            if (formFieldsBlocks[i].blockId == blockId) {
			//alert('formFieldsBlocks[i].blockId = ' + formFieldsBlocks[i].blockId)

			//            formFieldsVenueBlocs[i] = { ...formFieldsVenueBlocs[i], [tagName]: tagValue }

			for (var j = 0; j < formFieldsVenueBlocs.length; j++) {
				if (data[j].blockId === formFieldsBlocks[i].blockId) {
					//alert('Find formFieldsVenueBlocs[j].blockId = ' + formFieldsVenueBlocs[j].blockId)
					data[j] = { ...data[j], [tagName]: tagValue };
				}
			}
			setFormFieldsVenueBlocs(data);

			/*
            Object.keys(formFieldsVenueBlocs).forEach((value, key) => {
                alert('key = ' + key)
                if (key === tagName) {
                    formFieldsVenueBlocs[i] = { ...data[i], [key]: tagValue }
                }
            });
            */

			//return 'gray.300'
			//            } else {
			//                return 'gray.100'
			//            }
		}
		/*
        formFieldsBlocks.map((formFieldsBlocks, index) => {

            
        })

        Object.keys(data[index]).forEach(key => {
            if (key === event.target.name) {
                data[index] = { ...data[index], [key]: event.target.value }
            }
        });
        */
	};

	const blockIsSelect = (blockId) => {
		//        alert('> blockId = ' + blockId)

		for (var i = 0; i < formFieldsBlocks.length; i++) {
			if (formFieldsBlocks[i].blockId === blockId) {
				//alert('formFieldsBlocks = ' + formFieldsBlocks)
				return "gray.300";
			} else {
				//                return 'gray.100'
			}
		}
	};

	const options_blocksId = formFieldsVenueBlocs.map((formFieldsVenueBloc) => {
		//alert('formFieldsVenueBloc.nameEN = ' + formFieldsVenueBloc.nameEN)
		return (
			<option
				key={formFieldsVenueBloc.blockId}
				value={formFieldsVenueBloc.blockId}
			>
				{formFieldsVenueBloc.nameEN}
			</option>
		);
	});

	const options_sources = sources.map((source) => {
		return (
			<option key={source.id} value={source.id}>
				{source.sourceName}
			</option>
		);
	});

	const initVenueBlocsAliasForm = () => {
		let venueBlocsAlias = [];
		if (venue.venueBlocsAlias !== undefined) {
			venueBlocsAlias = venue.venueBlocsAlias;
		} else {
			//            venueBloc.push({ lang: venueBloc })
		}
		return venueBlocsAlias;
	};
	const [formFieldsVenueBlocsAlias, setFormFieldsVenueAliasBlocs] = useState(
		initVenueBlocsAliasForm
	);
	const [
		formFieldsVenueBlocsAliasFiltered,
		setFormFieldsVenueBlocsAliasFiltered,
	] = useState(initVenueBlocsAliasForm);

	const [blockAliasFilter, setBlockAliasFilter] = useState("");
	const blockAliasFormChange = (event, index) => {
		let data = [...formFieldsVenueBlocsAlias];
		Object.keys(data[index]).forEach((key) => {
			if (key === event.target.name) {
				data[index] = { ...data[index], [key]: event.target.value };
			}
		});
		setFormFieldsVenueAliasBlocs(data);
		checkCanSave();
		setBlockAliasFilterOn(blockAliasFilter);
	};
	const addAliasFields_bloc = () => {
		let object = {
			blockAliasId: getNewIdBlockAlias(),
			blockId: "",
			sourceId: blockAliasFilter,
			blockAliasLabel: "",
			blockAliasCode: "",
		};
		setFormFieldsVenueAliasBlocs([...formFieldsVenueBlocsAlias, object]);
		setCanSave(false);
	};
	const removeAliasFields_bloc = (index) => {
		let data = [...formFieldsVenueBlocsAlias];
		data.splice(index, 1);
		setFormFieldsVenueAliasBlocs(data);
		checkCanSave();
	};
	const getNewIdBlockAlias = () => {
		return Date.now() + Math.floor(Math.random() * 1001);
	};
	const setBlockAliasFilterOn = (filter) => {
		setBlockAliasFilter(filter);

		if (filter !== "") {
			setFormFieldsVenueBlocsAliasFiltered([]);
			const filtered2 = formFieldsVenueBlocsAlias.filter((row) => {
				return row.sourceId === filter;
			});
			console.log("filtered2: ", filtered2);
			setFormFieldsVenueBlocsAliasFiltered(filtered2);
		} else {
			setFormFieldsVenueBlocsAliasFiltered(formFieldsVenueBlocsAlias);
		}
	};

	const initVenueAliasNamesForm = () => {
		let venueNameAlias = [];
		Object.entries(venue.venueNameAlias).map((item) => {
			venueNameAlias.push({ venueNameAlias: item[1] });
		});
		return venueNameAlias;
	};
	const [formFieldsVenueAliasName, setFormAliasFields] = useState(
		initVenueAliasNamesForm
	);
	const handleFormAliasChange = (event, index) => {
		let data = [...formFieldsVenueAliasName];
		data[index][event.target.name] = event.target.value;
		setFormAliasFields(data);
		checkCanSave();
	};
	const addAliasFields = () => {
		let object = {
			venueNameAlias: "",
		};
		setFormAliasFields([...formFieldsVenueAliasName, object]);
		setCanSave(false);
	};
	const removeAliasFields = (index) => {
		let data = [...formFieldsVenueAliasName];
		data.splice(index, 1);
		setFormAliasFields(data);
		checkCanSave();
	};

	const [formFieldsVenueBlocsImports, setFormFieldsVenueBlocsImports] =
		useState([
			{
				blockCodeSourceType: "",
				blockCodeImport: "",
				blockCodeImportPreview: "",
			},
		]);
	const blockImportFormChange = (event, index) => {
		let data = [...formFieldsVenueBlocsImports];
		Object.keys(data[index]).forEach((key) => {
			if (key === event.target.name) {
				data = { ...data[index], [key]: event.target.value };
				setFormFieldsVenueBlocsImports([data]);

				if (event.target.name === "blockCodeImport") {
					if (
						formFieldsVenueBlocsImports[0].blockCodeSourceType !== "" &&
						formFieldsVenueBlocsImports[0].blockCodeImport !== ""
					) {
						switch (formFieldsVenueBlocsImports[0].blockCodeSourceType) {
							// Extract from JSON
							case "isJSON":
								// code
								break;

							// Extract from CSV
							case "isCSV":
								// code
								break;

							// Extract from OPTION
							case "isOPTION":
								let str = event.target.value;
								const regex =
									/<option value=""([^"]+)"([^<>]+)">([^<>]+)<\/option>/gim;
								let m;
								let preview = "";
								while ((m = regex.exec(str)) !== null) {
									// This is necessary to avoid infinite loops with zero-width matches
									if (m.index === regex.lastIndex) {
										regex.lastIndex++;
									}
									preview = preview + m[3] + "\n";
									m.forEach((match, groupIndex) => {
										console.log(`Found match, group ${groupIndex}: ${match}`);
									});
								}
								let data = [...formFieldsVenueBlocsImports];
								Object.keys(data[0]).forEach((key) => {
									if (key === "blockCodeImportPreview") {
										data = {
											...data[0],
											blockCodeImport: str,
											blockCodeImportPreview: preview,
										};
										setFormFieldsVenueBlocsImports([data]);
									}
								});
								break;

							default:
							// code
						}
						checkCanImportBlock();
					}
					//                }
				}
				if (event.target.name === "blockCodeSourceType") {
					let data = [...formFieldsVenueBlocsImports];
					Object.keys(data[0]).forEach((key) => {
						if (key === "blockCodeSourceType") {
							data = {
								...data[0],
								[key]: event.target.value,
								blockCodeImport: "",
								blockCodeImportPreview: "",
							};
							setFormFieldsVenueBlocsImports([data]);
						}
					});
					checkCanImportBlock();
				}
			}
			checkCanImportBlock();
		});
	};

	const [
		formFieldsVenueBlocsAliasImports,
		setFormFieldsVenueAliasBlocsImports,
	] = useState([
		{
			blockAliasCodeSourceType: "",
			blockAliasCodeSourceId: "",
			blockAliasCodeImport: "",
			blockAliasCodeImportPreview: "",
		},
	]);
	const blockAliasImportFormChange = (event, index) => {
		let data = [...formFieldsVenueBlocsAliasImports];
		Object.keys(data[index]).forEach((key) => {
			if (key === event.target.name) {
				data = { ...data[index], [key]: event.target.value };
				setFormFieldsVenueAliasBlocsImports([data]);

				if (event.target.name === "blockAliasCodeImport") {
					if (
						formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceType !==
							"" &&
						formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceId !== "" &&
						formFieldsVenueBlocsAliasImports[0].blockAliasCodeImport !== ""
					) {
						switch (
							formFieldsVenueBlocsAliasImports[0].blockAliasCodeSourceType
						) {
							// Extract from JSON
							case "isJSON":
								// code
								break;

							// Extract from CSV
							case "isCSV":
								// code
								break;

							// Extract from OPTION
							case "isOPTION":
								let str = event.target.value;
								const regex =
									/<option value=""([^"]+)"([^<>]+)">([^<>]+)<\/option>/gim;
								let m;
								let preview = "";
								while ((m = regex.exec(str)) !== null) {
									// This is necessary to avoid infinite loops with zero-width matches
									if (m.index === regex.lastIndex) {
										regex.lastIndex++;
									}
									preview = preview + m[1] + " = " + m[3] + "\n";
									m.forEach((match, groupIndex) => {
										console.log(`Found match, group ${groupIndex}: ${match}`);
									});
								}
								let data = [...formFieldsVenueBlocsAliasImports];
								Object.keys(data[0]).forEach((key) => {
									if (key === "blockAliasCodeImportPreview") {
										data = {
											...data[0],
											blockAliasCodeImport: str,
											blockAliasCodeImportPreview: preview,
										};
										setFormFieldsVenueAliasBlocsImports([data]);
									}
								});
								break;

							default:
							// code
						}
						checkCanImportBlockAlias();
					}
					//                }
				}
				if (event.target.name === "blockAliasCodeSourceType") {
					let data = [...formFieldsVenueBlocsAliasImports];
					Object.keys(data[0]).forEach((key) => {
						if (key === "blockAliasCodeSourceType") {
							data = {
								...data[0],
								[key]: event.target.value,
								blockAliasCodeImport: "",
								blockAliasCodeImportPreview: "",
							};
							setFormFieldsVenueAliasBlocsImports([data]);
						}
					});
					checkCanImportBlockAlias();
				}
			}
			checkCanImportBlockAlias();
		});
	};

	useEffect(() => {
		checkCanSave();

		checkCanImportBlock();
		checkCanImportBlockAlias();
		setBlockAliasFilterOn(blockAliasFilter);
		if (isSuccess) {
			toastInfo({
				title: "VENUE : " + data?.message + " successfully",
				status: "success",
			});
			initVenueNamesForm();
			setVenueCapacity("");
			setVenueType("");
			setCityId("");
			navigate("/dash/venues");
		}
		if (isError) {
			toastInfo({
				title: "VENUE : Update error (" + error?.data?.message + ")",
				status: "error",
			});
		}
		if (isDelSuccess) {
			toastInfo({
				title: "VENUE : Delete " + delData?.message + " successfully",
				status: "success",
			});
			initVenueNamesForm();
			navigate("/dash/venues");
		}
		if (isDelError) {
			toastInfo({
				title: "VENUE : Delete error (" + delError?.data?.message + ")",
				status: "error",
			});
		}
	}, [
		checkCanSave,
		checkCanImportBlock,
		checkCanImportBlockAlias,
		setBlockAliasFilterOn,
		blockAliasFilter,
		initVenueNamesForm,
		toastInfo,
		isError,
		error,
		isDelError,
		delError,
		isSuccess,
		isDelSuccess,
		navigate,
	]);

	const content = (
		<>
			<Center bg="#45413C" p="0" color="white" axis="both">
				<Grid
					w="95vw"
					maxW="1500"
					minH="100vh"
					gap={5}
					p="5"
					templateColumns="repeat(12, 1fr)"
				>
					<GridItem
						color="black"
						colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}
					>
						<HStack color="white" mb="5">
							<Box color="262626" textAlign={["left"]}>
								<HStack>
									<Text
										fontSize={{
											base: "20",
											sm: "20",
											md: "20",
											lg: "24",
											xl: "28",
										}}
										fontWeight={700}
									>
										<FontAwesomeIcon icon={faRing} /> {T.venues.EditVenue}
									</Text>
									<Tag
										ml="5"
										fontSize={{
											base: "19",
											sm: "19",
											md: "19",
											lg: "19",
											xl: "23",
										}}
										fontWeight={600}
									>
										{formFieldsVenueNames[0].venueName}
									</Tag>
								</HStack>
							</Box>
							<Spacer />
							<Box p="2" color="262626" textAlign={["right"]}>
								<Button
									size="sm"
									onClick={onPageBack}
									leftIcon={<ArrowBackIcon />}
									colorScheme="gray"
									variant="solid"
									mr="2"
								>
									<Text
										display={{ base: "none", sm: "flex" }}
										fontSize={{
											base: "18",
											sm: "18",
											md: "18",
											lg: "18",
											xl: "20",
										}}
										fontWeight={600}
									>
										{T.common.Back}
									</Text>
								</Button>
								<Button
									size="sm"
									onClick={onSaveVenueClicked}
									disabled={!canSave}
									leftIcon={<CheckIcon />}
									colorScheme={canSave ? "teal" : "gray"}
								>
									<Text
										display={{ base: "none", sm: "flex" }}
										fontSize={{
											base: "18",
											sm: "18",
											md: "18",
											lg: "18",
											xl: "20",
										}}
										fontWeight={600}
									>
										{T.common.Save}
									</Text>
								</Button>
							</Box>
						</HStack>
						<Box
							borderRadius="xl"
							p={2}
							fontSize={20}
							fontWeight={400}
							color="black"
							border="10px"
							borderColor="black"
							bg="white"
							w="100%"
						>
							<form id="form" name="form" onSubmit={onSaveVenueClicked}>
								<Tabs variant="soft-rounded" colorScheme="teal" isFitted>
									<TabList
										overflowX="auto"
										css={css({
											scrollbarWidth: "none",
											"::-webkit-scrollbar": { display: "none" },
											"-webkit-overflow-scrolling": "touch",
											boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
											border: "0 none",
										})}
									>
										<Tab
											_selected={{ color: "white", bg: "teal" }}
											_hover={{
												bg: "gray.300",
												color: "black",
												borderColor: "gray.300",
											}}
											m={1}
											fontSize={18}
											fontWeight={700}
										>
											Venue
										</Tab>
										<Tab
											_selected={{ color: "white", bg: "teal" }}
											_hover={{
												bg: "gray.300",
												color: "black",
												borderColor: "gray.300",
											}}
											m={1}
											fontSize={18}
											fontWeight={700}
										>
											Picture
										</Tab>
										<Tab
											_selected={{ color: "white", bg: "teal" }}
											_hover={{
												bg: "gray.300",
												color: "black",
												borderColor: "gray.300",
											}}
											m={1}
											fontSize={18}
											fontWeight={700}
										>
											Block
										</Tab>
										<Tab
											isDisabled
											_selected={{ color: "white", bg: "teal" }}
											_hover={{
												bg: "gray.300",
												color: "black",
												borderColor: "gray.300",
											}}
											m={1}
											fontSize={18}
											fontWeight={700}
										>
											Picture
										</Tab>
									</TabList>

									<TabPanels>
										{/* Tab VENUE */}
										<TabPanel minH={400}>
											<Tabs variant="soft-rounded" colorScheme="teal">
												<TabList
													overflowX="auto"
													css={css({
														scrollbarWidth: "none",
														"::-webkit-scrollbar": { display: "none" },
														"-webkit-overflow-scrolling": "touch",
														boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
														border: "0 none",
													})}
												>
													<Tab
														_selected={{ color: "white", bg: "teal" }}
														_hover={{
															bg: "gray.300",
															color: "black",
															borderColor: "gray.300",
														}}
														m={1}
														fontSize={16}
														fontWeight={600}
													>
														Info
													</Tab>
													<Tab
														_selected={{ color: "white", bg: "teal" }}
														_hover={{
															bg: "gray.300",
															color: "black",
															borderColor: "gray.300",
														}}
														m={1}
														fontSize={16}
														fontWeight={600}
													>
														Alias
													</Tab>
												</TabList>
												<TabPanels>
													{/* SubTab INFO */}
													<TabPanel>
														<FormLabel htmlFor="venueName" mb="0">
															{T.venues.VenueName}
														</FormLabel>
														{formFieldsVenueNames.map((form, index) => {
															return (
																<HStack key={index}>
																	<Input
																		isInvalid={!form.lang}
																		name="lang"
																		placeholder={T.venues.IsoCode}
																		onChange={(event) =>
																			handleFormChange(event, index)
																		}
																		value={form.lang}
																		focusBorderColor="blue.400"
																		errorBorderColor="red.300"
																		minLength="2"
																		maxLength="2"
																		bg="yellow.100"
																		w="10vw"
																	/>
																	<Input
																		isInvalid={!form.venueName}
																		name="venueName"
																		placeholder={T.venues.TranslatedName}
																		onChange={(event) =>
																			handleFormChange(event, index)
																		}
																		value={form.venueName}
																		focusBorderColor="blue.400"
																		errorBorderColor="red.300"
																		bg="yellow.100"
																		w="50vw"
																	/>
																	{index !== 0 && (
																		<IconButton
																			size="xs"
																			name="Remove"
																			title={T.common.Delete}
																			colorScheme="gray"
																			aria-label={T.common.Delete}
																			icon={<MinusIcon />}
																			onClick={() => removeFields(index)}
																		/>
																	)}
																</HStack>
															);
														})}
														<IconButton
															size="xs"
															title={T.common.Add}
															onClick={addFields}
															colorScheme="gray"
															aria-label={T.common.Add}
															icon={<AddIcon />}
														/>

														<HStack>
															<FormControl>
																<FormLabel
																	htmlFor="venueCapacity"
																	mt="5"
																	mb="0"
																>
																	{T.venues.VenueCapacity}
																</FormLabel>
																<Input
																	isInvalid={!venueCapacity}
																	id="venueCapacity"
																	name="venueCapacity"
																	placeholder={T.venues.Capacity}
																	onChange={onVenueCapacityChanged}
																	value={venueCapacity}
																	focusBorderColor="blue.400"
																	errorBorderColor="red.300"
																	bg="yellow.100"
																/>
															</FormControl>

															<FormControl>
																<FormLabel htmlFor="venueLevel" mt="5" mb="0">
																	{T.venues.VenueLevel}
																</FormLabel>
																<Select
																	isInvalid={!venueLevel}
																	id="venueLevel"
																	name="venueLevel"
																	placeholder=" "
																	value={venueLevel}
																	onChange={onVenueLevelChanged}
																	bg="yellow.100"
																>
																	{options_venueLevel}
																</Select>
															</FormControl>

															<FormControl>
																<FormLabel htmlFor="venueType" mt="5" mb="0">
																	{T.venues.VenueType}
																</FormLabel>
																<Select
																	isInvalid={!venueType}
																	id="venueType"
																	name="venueType"
																	value={venueType}
																	onChange={onVenueTypeChanged}
																	bg="yellow.100"
																>
																	{options_venueType}
																</Select>
															</FormControl>

															<FormControl>
																<FormLabel htmlFor="cityId" mt="5" mb="0">
																	{T.venues.CityArea}
																</FormLabel>
																<Select
																	isInvalid={!cityId}
																	id="cityId"
																	name="cityId"
																	value={cityId}
																	onChange={onCityIdChanged}
																	bg="yellow.100"
																>
																	{options_cityArea}
																</Select>
															</FormControl>
														</HStack>

														<FormLabel htmlFor="Status" mt="5" mb="0">
															{T.venues.Status}
														</FormLabel>
														<Switch
															id="venuesStatus"
															name="status"
															colorScheme="green"
															isChecked={venueStatus}
															onChange={onVenueStatusChanged}
														/>

														<br />
														<br />
														<HStack>
															<Text
																fontSize={{
																	base: "10",
																	sm: "10",
																	md: "10",
																	lg: "15",
																	xl: "15",
																}}
																fontWeight={600}
															>
																{T.common.Created}:
															</Text>
															<Text
																fontSize={{
																	base: "10",
																	sm: "10",
																	md: "10",
																	lg: "15",
																	xl: "15",
																}}
															>
																{created}
															</Text>
														</HStack>
														<HStack>
															<Text
																fontSize={{
																	base: "10",
																	sm: "10",
																	md: "10",
																	lg: "15",
																	xl: "15",
																}}
																fontWeight={600}
															>
																{T.common.Updated}:
															</Text>
															<Text
																fontSize={{
																	base: "10",
																	sm: "10",
																	md: "10",
																	lg: "15",
																	xl: "15",
																}}
															>
																{updated}
															</Text>
														</HStack>
														<br />
														<br />
														{deleteButton}
													</TabPanel>

													{/* SubTab Alias */}
													<TabPanel>
														<FormLabel htmlFor="venueName" mb="0">
															{T.venues.VenueAliasName}
														</FormLabel>
														{formFieldsVenueAliasName.map((form, index) => {
															return (
																<HStack key={index}>
																	<Input
																		isInvalid={!form.venueNameAlias}
																		name="venueNameAlias"
																		placeholder={T.venues.AliasName}
																		onChange={(event) =>
																			handleFormAliasChange(event, index)
																		}
																		value={form.venueNameAlias}
																		focusBorderColor="blue.400"
																		errorBorderColor="red.300"
																		bg="yellow.100"
																		w="100vw"
																		maxW="250"
																	/>
																	<IconButton
																		size="xs"
																		name="Remove"
																		title={T.common.Delete}
																		colorScheme="gray"
																		aria-label={T.common.Delete}
																		icon={<MinusIcon />}
																		onClick={() => removeAliasFields(index)}
																	/>
																</HStack>
															);
														})}
														<IconButton
															size="xs"
															title={T.common.Add}
															onClick={addAliasFields}
															colorScheme="gray"
															aria-label={T.common.Add}
															icon={<AddIcon />}
														/>
													</TabPanel>
												</TabPanels>
											</Tabs>
										</TabPanel>

										{/* Tab PICTURE */}
										<TabPanel minH={400}>
											<Grid
												templateRows="repeat(1, 1fr)"
												templateColumns="repeat(5, 1fr)"
												gap={5}
											>
												<GridItem colSpan={5}>
													<VenueUploadForm
														updateVenuePics={updateVenuePics}
														deleteVenuePics={deleteVenuePics}
														venuePics={venuePics}
													/>
												</GridItem>
											</Grid>
										</TabPanel>

										{/* Tab BLOCK */}
										<TabPanel minH={400}>
											<Tabs variant="soft-rounded" colorScheme="teal">
												<TabList
													overflowX="auto"
													css={css({
														scrollbarWidth: "none",
														"::-webkit-scrollbar": { display: "none" },
														"-webkit-overflow-scrolling": "touch",
														boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
														border: "0 none",
													})}
												>
													<Tab
														_selected={{ color: "white", bg: "teal" }}
														_hover={{
															bg: "gray.300",
															color: "black",
															borderColor: "gray.300",
														}}
														m={1}
														fontSize={16}
														fontWeight={600}
													>
														Blocks List
													</Tab>
													<Tab
														_selected={{ color: "white", bg: "teal" }}
														_hover={{
															bg: "gray.300",
															color: "black",
															borderColor: "gray.300",
														}}
														m={1}
														fontSize={16}
														fontWeight={600}
													>
														Blocks Alias
													</Tab>
												</TabList>
												<TabPanels>
													{/* SubTab BLOCKLIST */}
													<TabPanel>
														<HStack>
															<FormControl>
																<FormLabel htmlFor="TagAssign" mb="0">
																	{T.venues.TagAssign}
																</FormLabel>
																<ButtonGroup
																	size="sm"
																	isAttached
																	variant="outline"
																	colorScheme="teal"
																	mr="5"
																>
																	<Text fontSize={15} fontWeight={600} mr="2">
																		POS{" "}
																	</Text>
																	<IconButton
																		onClick={() => addTag("tagPos", "")}
																		aria-label=""
																		icon={<CloseIcon />}
																	/>
																	{tagsPoss.map((tagsPos) => {
																		return (
																			<Button
																				mr="-px"
																				onClick={() =>
																					addTag("tagPos", tagsPos.name)
																				}
																			>
																				{tagsPos.name}
																			</Button>
																		);
																	})}
																</ButtonGroup>
																<ButtonGroup
																	size="sm"
																	isAttached
																	variant="outline"
																	colorScheme="teal"
																	mr="5"
																>
																	<Text fontSize={15} fontWeight={600} mr="2">
																		SECTION{" "}
																	</Text>
																	<IconButton
																		onClick={() => addTag("tagSection", "")}
																		aria-label=""
																		icon={<CloseIcon />}
																	/>
																	{tagsSections.map((tagsSection) => {
																		return (
																			<Button
																				mr="-px"
																				onClick={() =>
																					addTag("tagSection", tagsSection.name)
																				}
																			>
																				{tagsSection.name}
																			</Button>
																		);
																	})}
																</ButtonGroup>
																<ButtonGroup
																	size="sm"
																	isAttached
																	variant="outline"
																	colorScheme="teal"
																	mr="5"
																>
																	<Text fontSize={15} fontWeight={600} mr="2">
																		NUM{" "}
																	</Text>
																	<IconButton
																		onClick={() => addTag("tagNum", "")}
																		aria-label=""
																		icon={<CloseIcon />}
																	/>
																	{tagsNums.map((tagsNum) => {
																		return (
																			<Button
																				mr="-px"
																				onClick={() =>
																					addTag("tagNum", tagsNum.name)
																				}
																			>
																				{tagsNum.name}
																			</Button>
																		);
																	})}
																</ButtonGroup>
																<ButtonGroup
																	size="sm"
																	isAttached
																	variant="outline"
																	colorScheme="teal"
																	mr="5"
																>
																	<Text fontSize={15} fontWeight={600} mr="2">
																		LEVEL{" "}
																	</Text>
																	<IconButton
																		onClick={() => addTag("tagLevel", "")}
																		aria-label=""
																		icon={<CloseIcon />}
																	/>
																	{tagsLevels.map((tagsLevel) => {
																		return (
																			<Button
																				mr="-px"
																				onClick={() =>
																					addTag("tagLevel", tagsLevel.name)
																				}
																			>
																				{tagsLevel.name}
																			</Button>
																		);
																	})}
																</ButtonGroup>
															</FormControl>
														</HStack>

														<FormControl>
															<FormLabel htmlFor="BlockList" mt="5">
																{T.venues.BlockList}
															</FormLabel>
															<Box
																height="480px"
																p="1"
																bg="gray.100"
																borderWidth="1px"
																borderRadius="lg"
																overflowY="scroll"
															>
																<TableContainer>
																	<Table size="sm">
																		<Thead>
																			<Tr>
																				<Th w={50}>
																					<IconButton
																						size="xs"
																						title={T.common.UnselectAll}
																						onClick={unselectAllBlocks}
																						colorScheme="gray"
																						aria-label={T.common.Add}
																						icon={<CloseIcon />}
																					/>
																				</Th>
																				<Th>NAME EN</Th>
																				<Th>NAME LOCAL</Th>
																				<Th w={120}>POS</Th>
																				<Th w={120}>SECTION</Th>
																				<Th w={120}>NUM</Th>
																				<Th w={120}>LEVEL</Th>
																				<Th w={50}>
																					<IconButton
																						size="xs"
																						title={T.common.Add}
																						onClick={addFields_bloc}
																						colorScheme="gray"
																						aria-label={T.common.Add}
																						icon={<AddIcon />}
																					/>
																				</Th>
																			</Tr>
																		</Thead>
																		<Tbody>
																			{formFieldsVenueBlocs.length > 0 &&
																				formFieldsVenueBlocs.map(
																					(formFieldsVenueBloc, index) => {
																						return (
																							<Tr
																								key={index}
																								onClick={() =>
																									selectBlock(
																										formFieldsVenueBloc.blockId,
																										index
																									)
																								}
																								bg={() =>
																									blockIsSelect(
																										formFieldsVenueBloc.blockId
																									)
																								}
																								_hover={{
																									color: "black",
																									border: "2px",
																									borderColor: "teal.600",
																								}}
																							>
																								<Td>
																									<Icon
																										as={DragHandleIcon}
																										w={4}
																										h={4}
																										cursor={"move"}
																									/>
																									<Input
																										isInvalid={
																											!formFieldsVenueBloc.blockId
																										}
																										errorBorderColor="red.300"
																										name="blockId"
																										value={
																											formFieldsVenueBloc.blockId
																										}
																										size="sm"
																										isReadOnly
																										type="hidden"
																									/>
																								</Td>
																								<Td>
																									<Input
																										isInvalid={
																											!formFieldsVenueBloc.nameEN
																										}
																										name="nameEN"
																										onChange={(event) =>
																											blockFormChange(
																												event,
																												index
																											)
																										}
																										value={
																											formFieldsVenueBloc.nameEN
																										}
																										focusBorderColor="blue.400"
																										errorBorderColor="red.300"
																										bg="yellow.100"
																										size="sm"
																									/>
																								</Td>
																								<Td>
																									<Input
																										isInvalid={
																											!formFieldsVenueBloc.nameLocal
																										}
																										name="nameLocal"
																										onChange={(event) =>
																											blockFormChange(
																												event,
																												index
																											)
																										}
																										value={
																											formFieldsVenueBloc.nameLocal
																										}
																										focusBorderColor="blue.400"
																										errorBorderColor="red.300"
																										bg="yellow.100"
																										size="sm"
																									/>
																								</Td>
																								<Td>
																									<Input
																										isInvalid={
																											!formFieldsVenueBloc.tagPos
																										}
																										errorBorderColor="red.300"
																										name="tagPos"
																										size="sm"
																										value={
																											formFieldsVenueBloc.tagPos
																										}
																										isReadOnly
																									/>
																								</Td>
																								<Td>
																									<Input
																										isInvalid={
																											!formFieldsVenueBloc.tagSection
																										}
																										errorBorderColor="red.300"
																										name="tagSection"
																										size="sm"
																										value={
																											formFieldsVenueBloc.tagSection
																										}
																										isReadOnly
																									/>
																								</Td>
																								<Td>
																									<Input
																										isInvalid={
																											!formFieldsVenueBloc.tagNum
																										}
																										errorBorderColor="red.300"
																										name="tagNum"
																										size="sm"
																										value={
																											formFieldsVenueBloc.tagNum
																										}
																										isReadOnly
																									/>
																								</Td>
																								<Td>
																									<Input
																										isInvalid={
																											!formFieldsVenueBloc.tagLevel
																										}
																										errorBorderColor="red.300"
																										name="tagLevel"
																										size="sm"
																										value={
																											formFieldsVenueBloc.tagLevel
																										}
																										isReadOnly
																									/>{" "}
																								</Td>
																								<Td>
																									<IconButton
																										size="xs"
																										name="Remove"
																										title={T.common.Delete}
																										colorScheme="gray"
																										aria-label={T.common.Delete}
																										icon={<MinusIcon />}
																										onClick={() =>
																											removeFields_bloc(index)
																										}
																									/>
																								</Td>
																							</Tr>
																						);
																					}
																				)}
																		</Tbody>
																	</Table>
																</TableContainer>
															</Box>
														</FormControl>

														<FormControl>
															<FormLabel htmlFor="BlockAliasImport" mt="5">
																{T.venues.BlockListImport}
															</FormLabel>
															<Box
																height="260x"
																p="1"
																bg="gray.100"
																borderWidth="1px"
																borderRadius="lg"
																overflowY="scroll"
															>
																{formFieldsVenueBlocsImports.length > 0 &&
																	formFieldsVenueBlocsImports.map(
																		(formFieldsVenueBlocsImport, index) => {
																			return (
																				<>
																					<HStack>
																						<FormControl>
																							<FormLabel
																								htmlFor="sourceType"
																								mt="5"
																							>
																								{T.venues.SourceType}
																							</FormLabel>
																							<Select
																								isInvalid={
																									!formFieldsVenueBlocsImport.blockCodeSourceType
																								}
																								name="blockCodeSourceType"
																								placeholder=" "
																								value={
																									formFieldsVenueBlocsImport.blockCodeSourceType
																								}
																								onChange={(event) =>
																									blockImportFormChange(
																										event,
																										index
																									)
																								}
																								bg="yellow.100"
																								size="sm"
																							>
																								<option value="isCSV">
																									isCSV
																									(nameEN;nameLocal;pos;section;num;level)
																								</option>
																								<option value="isJSON">
																									isJSON [("nameEN":"",
																									"nameLocal":"", "pos":"",
																									"section":"", "num":"",
																									"level":"")]
																								</option>
																								<option value="isOPTION">
																									isOPTION (extract label only)
																								</option>
																							</Select>

																							<FormLabel
																								htmlFor="PastCode"
																								mt="5"
																							>
																								{T.venues.PastCode}{" "}
																								(csv/json/option)
																							</FormLabel>
																							<Textarea
																								isInvalid={
																									!formFieldsVenueBlocsImport.blockCodeImport
																								}
																								name="blockCodeImport"
																								value={
																									formFieldsVenueBlocsImport.blockCodeImport
																								}
																								onChange={(event) =>
																									blockImportFormChange(
																										event,
																										index
																									)
																								}
																								bg="yellow.100"
																								size="sm"
																								rows="3"
																							></Textarea>
																						</FormControl>

																						<FormControl>
																							<FormLabel
																								htmlFor="PreviewImport"
																								mt="5"
																							>
																								{T.venues.PreviewImport}
																							</FormLabel>
																							<Textarea
																								isInvalid={
																									!formFieldsVenueBlocsImport.blockCodeImportPreview
																								}
																								name="blockCodeImportPreview"
																								value={
																									formFieldsVenueBlocsImport.blockCodeImportPreview
																								}
																								onChange={(event) =>
																									blockImportFormChange(
																										event,
																										index
																									)
																								}
																								bg="gray.100"
																								size="sm"
																								rows="7"
																								readOnly
																							></Textarea>
																						</FormControl>
																					</HStack>
																					<Button
																						size="sm"
																						w="100%"
																						mt="2"
																						onClick={
																							onImportBlockSaveVenueClicked
																						}
																						disabled={!canImportBlock}
																						leftIcon={<DownloadIcon />}
																						colorScheme={
																							canImportBlock ? "teal" : "gray"
																						}
																					>
																						<Text
																							display={{
																								base: "none",
																								sm: "flex",
																							}}
																						>
																							{T.common.Import}
																						</Text>
																					</Button>
																				</>
																			);
																		}
																	)}
															</Box>
														</FormControl>
													</TabPanel>

													{/* SubTab BLOCKALIAS */}
													<TabPanel>
														<FormControl>
															<HStack>
																<FormControl>
																	<FormLabel htmlFor="TagAssign" mb="0">
																		{T.common.Filters}
																	</FormLabel>
																	<ButtonGroup
																		size="sm"
																		isAttached
																		variant="outline"
																		colorScheme="teal"
																		mr="5"
																	>
																		<Text fontSize={15} fontWeight={600} mr="2">
																			SourceName{" "}
																		</Text>
																		<IconButton
																			onClick={() => setBlockAliasFilterOn("")}
																			aria-label=""
																			icon={<CloseIcon />}
																			isActive={
																				blockAliasFilter === "" ? true : false
																			}
																			_selected={{ color: "white", bg: "gray" }}
																		/>
																		{sources.map((source) => {
																			return (
																				<Button
																					mr="-px"
																					onClick={() =>
																						setBlockAliasFilterOn(source.id)
																					}
																					isActive={
																						blockAliasFilter === source.id
																							? true
																							: false
																					}
																					_selected={{
																						color: "white",
																						bg: "gray",
																					}}
																				>
																					{source.sourceName}
																				</Button>
																			);
																		})}
																	</ButtonGroup>
																</FormControl>
															</HStack>

															<FormLabel htmlFor="MapBlock" mt="5">
																{T.venues.BlockAlias}
															</FormLabel>
															<Box
																height="600px"
																p="1"
																bg="gray.100"
																borderWidth="1px"
																borderRadius="lg"
																overflowY="scroll"
															>
																<TableContainer>
																	<Table size="sm">
																		<Thead>
																			<Tr>
																				<Th>BLOCK NAME / ID</Th>
																				<Th>SOURCE NAME</Th>
																				<Th>BLOCK LABEL</Th>
																				<Th>BLOCK CODE</Th>
																				<Th w={50}>
																					<IconButton
																						size="xs"
																						title={T.common.Add}
																						onClick={addAliasFields_bloc}
																						colorScheme="gray"
																						aria-label={T.common.Add}
																						icon={<AddIcon />}
																					/>
																				</Th>
																			</Tr>
																		</Thead>
																		<Tbody>
																			{formFieldsVenueBlocsAliasFiltered.length >
																				0 &&
																				formFieldsVenueBlocsAliasFiltered.map(
																					(formFieldsVenueBlocAlias, index) => {
																						return (
																							<Tr
																								key={index}
																								_hover={{
																									color: "black",
																									bg: "gray.200",
																									border: "2px",
																									borderColor: "teal.600",
																								}}
																							>
																								<Td>
																									<Select
																										isInvalid={
																											!formFieldsVenueBlocAlias.blockId
																										}
																										name="blockId"
																										placeholder=" "
																										value={
																											formFieldsVenueBlocAlias.blockId
																										}
																										onChange={(event) =>
																											blockAliasFormChange(
																												event,
																												index
																											)
																										}
																										bg="yellow.100"
																										size="sm"
																									>
																										{options_blocksId}
																									</Select>
																								</Td>
																								<Td>
																									<Select
																										isInvalid={
																											!formFieldsVenueBlocAlias.sourceId
																										}
																										name="sourceId"
																										placeholder=" "
																										value={
																											formFieldsVenueBlocAlias.sourceId
																										}
																										onChange={(event) =>
																											blockAliasFormChange(
																												event,
																												index
																											)
																										}
																										bg="yellow.100"
																										size="sm"
																									>
																										{options_sources}
																									</Select>
																								</Td>
																								<Td>
																									<Input
																										isInvalid={
																											!formFieldsVenueBlocAlias.blockAliasLabel
																										}
																										name="blockAliasLabel"
																										onChange={(event) =>
																											blockAliasFormChange(
																												event,
																												index
																											)
																										}
																										value={
																											formFieldsVenueBlocAlias.blockAliasLabel
																										}
																										focusBorderColor="blue.400"
																										errorBorderColor="red.300"
																										bg="yellow.100"
																										size="sm"
																									/>
																								</Td>
																								<Td>
																									<Input
																										isInvalid={
																											!formFieldsVenueBlocAlias.blockAliasCode
																										}
																										name="blockAliasCode"
																										onChange={(event) =>
																											blockAliasFormChange(
																												event,
																												index
																											)
																										}
																										value={
																											formFieldsVenueBlocAlias.blockAliasCode
																										}
																										focusBorderColor="blue.400"
																										errorBorderColor="red.300"
																										bg="yellow.100"
																										size="sm"
																									/>
																								</Td>
																								<Td>
																									<IconButton
																										size="xs"
																										name="Remove"
																										title={T.common.Delete}
																										colorScheme="gray"
																										aria-label={T.common.Delete}
																										icon={<MinusIcon />}
																										onClick={() =>
																											removeAliasFields_bloc(
																												index
																											)
																										}
																									/>
																								</Td>
																							</Tr>
																						);
																					}
																				)}
																		</Tbody>
																	</Table>
																</TableContainer>
															</Box>
														</FormControl>

														<FormControl>
															<FormLabel htmlFor="BlockAliasImport" mt="5">
																{T.venues.BlockAliasImport}
															</FormLabel>
															<Box
																height="260x"
																p="1"
																bg="gray.100"
																borderWidth="1px"
																borderRadius="lg"
																overflowY="scroll"
															>
																{formFieldsVenueBlocsAliasImports.length > 0 &&
																	formFieldsVenueBlocsAliasImports.map(
																		(
																			formFieldsVenueBlocsAliasImport,
																			index
																		) => {
																			return (
																				<>
																					<HStack>
																						<FormControl>
																							<FormLabel
																								htmlFor="sourceType"
																								mt="5"
																							>
																								{T.venues.SourceType}
																							</FormLabel>
																							<Select
																								isInvalid={
																									!formFieldsVenueBlocsAliasImport.blockAliasCodeSourceType
																								}
																								name="blockAliasCodeSourceType"
																								placeholder=" "
																								value={
																									formFieldsVenueBlocsAliasImport.blockAliasCodeSourceType
																								}
																								onChange={(event) =>
																									blockAliasImportFormChange(
																										event,
																										index
																									)
																								}
																								bg="yellow.100"
																								size="sm"
																							>
																								<option value="isCSV">
																									isCSV (blockLabel;blockCode)
																								</option>
																								<option value="isJSON">
																									isJSON [("blockLabel":"A",
																									"blockValue":"123")]
																								</option>
																								<option value="isOPTION">
																									isOPTION (extract value/label)
																								</option>
																							</Select>
																							<FormLabel
																								htmlFor="SourceName"
																								mt="5"
																							>
																								{T.venues.SourceName}
																							</FormLabel>
																							<Select
																								isInvalid={
																									!formFieldsVenueBlocsAliasImport.blockAliasCodeSourceId
																								}
																								name="blockAliasCodeSourceId"
																								placeholder=" "
																								value={
																									formFieldsVenueBlocsAliasImport.blockAliasCodeSourceId
																								}
																								onChange={(event) =>
																									blockAliasImportFormChange(
																										event,
																										index
																									)
																								}
																								bg="yellow.100"
																								size="sm"
																							>
																								{options_sources}
																							</Select>
																						</FormControl>

																						<FormControl>
																							<FormLabel
																								htmlFor="PastCode"
																								mt="5"
																							>
																								{T.venues.PastCode}{" "}
																								(csv/json/option)
																							</FormLabel>
																							<Textarea
																								isInvalid={
																									!formFieldsVenueBlocsAliasImport.blockAliasCodeImport
																								}
																								name="blockAliasCodeImport"
																								value={
																									formFieldsVenueBlocsAliasImport.blockAliasCodeImport
																								}
																								onChange={(event) =>
																									blockAliasImportFormChange(
																										event,
																										index
																									)
																								}
																								bg="yellow.100"
																								size="sm"
																								rows="7"
																							></Textarea>
																						</FormControl>
																						<FormControl>
																							<FormLabel
																								htmlFor="PreviewImport"
																								mt="5"
																							>
																								{T.venues.PreviewImport}
																							</FormLabel>
																							<Textarea
																								isInvalid={
																									!formFieldsVenueBlocsAliasImport.blockAliasCodeImportPreview
																								}
																								name="blockAliasCodeImportPreview"
																								value={
																									formFieldsVenueBlocsAliasImport.blockAliasCodeImportPreview
																								}
																								onChange={(event) =>
																									blockAliasImportFormChange(
																										event,
																										index
																									)
																								}
																								bg="gray.100"
																								size="sm"
																								rows="7"
																								readOnly
																							></Textarea>
																						</FormControl>
																					</HStack>
																					<Button
																						size="sm"
																						w="100%"
																						mt="2"
																						onClick={
																							onImportBlockAliasSaveVenueClicked
																						}
																						disabled={!canImportBlockAlias}
																						leftIcon={<DownloadIcon />}
																						colorScheme={
																							canImportBlockAlias
																								? "teal"
																								: "gray"
																						}
																					>
																						<Text
																							display={{
																								base: "none",
																								sm: "flex",
																							}}
																						>
																							{T.common.Import}
																						</Text>
																					</Button>
																				</>
																			);
																		}
																	)}
															</Box>
														</FormControl>
													</TabPanel>
												</TabPanels>
											</Tabs>
										</TabPanel>

										{/* Tab PICTURE */}
										<TabPanel minH={400}>Picture</TabPanel>

										<TabPanel minH={400}></TabPanel>
									</TabPanels>
								</Tabs>
							</form>
						</Box>

						<Box
							borderRadius="xl"
							mt={5}
							p={2}
							fontSize={14}
							fontWeight={400}
							color="black"
							border="10px"
							borderColor="black"
							bg="white"
							w="100%"
							h={200}
							overflowY="scroll"
						>
							<br />
							<br />
							formFieldsVenueNames = {JSON.stringify(formFieldsVenueNames)}
							<br />
							<br />
							formFieldsVenueBlocs = {JSON.stringify(formFieldsVenueBlocs)}
							<br />
							<br />
							formFieldsBlocks = {JSON.stringify(formFieldsBlocks)}
							<br />
							<br />
							formFieldsVenueBlocsImports ={" "}
							{JSON.stringify(formFieldsVenueBlocsImports)}
							<br />
							<br />
							formFieldsVenueBlocsAliasImports ={" "}
							{JSON.stringify(formFieldsVenueBlocsAliasImports)}
							<br />
							<br />
							formFieldsVenueBlocsAlias ={" "}
							{JSON.stringify(formFieldsVenueBlocsAlias)}
							<br />
							<br />
							formFieldsVenueBlocsAliasFiltered ={" "}
							{JSON.stringify(formFieldsVenueBlocsAliasFiltered)}
						</Box>
					</GridItem>
				</Grid>
			</Center>
		</>
	);

	return content;
};

export default EditVenueForm;
