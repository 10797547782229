import NewMarketForm from './NewMarketForm'
import useTitle from '../../hooks/useTitle'

const NewMarket = () => {
    useTitle('ORACLY: New Market')

    const content = <NewMarketForm />

    return content
}
export default NewMarket