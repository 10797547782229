import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const citiesAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = citiesAdapter.getInitialState()

export const citiesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCities: builder.query({
            query: () => ({
                url: '/cities',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedCities = responseData.map(city => {
                    city.id = city._id
                    return city
                });
                return citiesAdapter.setAll(initialState, loadedCities)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'City', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'City', id }))
                    ]
                } else return [{ type: 'City', id: 'LIST' }]
            }
        }),
        addNewCity: builder.mutation({
            query: initialCity => ({
                url: '/cities',
                method: 'POST',
                body: {
                    ...initialCity,
                }
            }),
            invalidatesTags: [
                { type: 'City', id: "LIST" }
            ]
        }),
        updateCity: builder.mutation({
            query: initialCity => ({
                url: '/cities',
                method: 'PATCH',
                body: {
                    ...initialCity,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'City', id: arg.id }
            ]
        }),
        deleteCity: builder.mutation({
            query: ({ id }) => ({
                url: `/cities`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'City', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetCitiesQuery,
    useAddNewCityMutation,
    useUpdateCityMutation,
    useDeleteCityMutation,
} = citiesApiSlice

// returns the query result object
export const selectCitiesResult = citiesApiSlice.endpoints.getCities.select()

// creates memoized selector
const selectCitiesData = createSelector(
    selectCitiesResult,
    citiesResult => citiesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCities,
    selectById: selectCityById,
    selectIds: selectCityIds
    // Pass in a selector that returns the cities slice of state
} = citiesAdapter.getSelectors(state => selectCitiesData(state) ?? initialState)