import { store } from '../../app/store'
import { usersApiSlice } from '../users/usersApiSlice';
import { notesApiSlice } from '../notes/notesApiSlice'
import { marketsApiSlice } from '../markets/marketsApiSlice'
import { countriesApiSlice } from '../countries/countriesApiSlice'
import { citiesApiSlice } from '../cities/citiesApiSlice'
import { venuesApiSlice } from '../venues/venuesApiSlice'
import { mapsApiSlice } from '../maps/mapsApiSlice'
import { sourcesApiSlice } from '../sources/sourcesApiSlice'
import { categoriesApiSlice } from '../categories/categoriesApiSlice'
import { ticketsApiSlice } from '../tickets/ticketsApiSlice'

import { eventsApiSlice } from '../events/eventsApiSlice'

import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {

    useEffect(() => {
        store.dispatch(eventsApiSlice.util.prefetch('getEventsWait', 'eventsWaitList', { force: true }))

        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
        store.dispatch(notesApiSlice.util.prefetch('getNotes', 'notesList', { force: true }))
        store.dispatch(marketsApiSlice.util.prefetch('getMarkets', 'marketsList', { force: true }))
        store.dispatch(countriesApiSlice.util.prefetch('getCountries', 'countriesList', { force: true }))
        store.dispatch(citiesApiSlice.util.prefetch('getCities', 'citiesList', { force: true }))
        store.dispatch(venuesApiSlice.util.prefetch('getVenues', 'venuesList', { force: true }))
        store.dispatch(mapsApiSlice.util.prefetch('getMaps', 'mapsList', { force: true }))
        store.dispatch(sourcesApiSlice.util.prefetch('getSources', 'sourcesList', { force: true }))
        store.dispatch(categoriesApiSlice.util.prefetch('getCategories', 'categoriesList', { force: true }))
        store.dispatch(ticketsApiSlice.util.prefetch('getTickets', 'ticketsList', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch