import { useState, useEffect } from "react"
import { useGetEventsWaitQuery, useUpdateEventWaitMutation } from "./eventsApiSlice"
import EventFind from "./EventFind"
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import useLang from '../../hooks/useLang'
import useTranslate from '../../hooks/useTranslate'
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

// import `ChakraProvider` component
import { Box, Grid, GridItem, Center, HStack, VStack, Container, Flex, FormControl, FormLabel, Textarea, Input, Switch, Badge, Tag, TagLabel, TagCloseButton, Avatar, Toast, Icon, IconButton, Image, Stack, Alert, AlertIcon, Select, Button, useRadioGroup, useRadio, RadioGroup, Radio, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { TableContainer, Table, Thead, Tbody, Tfoot, Tr, Th, Td } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

// import `ChakraProvider` icon
import { ArrowBackIcon, AddIcon, CheckIcon, ExternalLinkIcon } from '@chakra-ui/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationCrosshairs, faList } from "@fortawesome/free-solid-svg-icons"


// import components
//import ModalEventsFinder from './components/ModalEventsFinder'

const EventsList = () => {
    useTitle('ORACLY: Events Finder')

    const { username, isManager, isAdmin } = useAuth()

    const { lang } = useLang()
    const { T } = useTranslate(lang)

    const navigate = useNavigate()

    let [canSave, setCanSave] = useState(false)

    const onPageBack = () => navigate('/dash/events/manager')

    const [updateEventWait, {
        isLoading: isLoadingEventWait,
        isSuccess: isSuccessEventWait,
        isError: isErrorEventWait,
        error: errorEventWait
    }] = useUpdateEventWaitMutation()

    let [eventId, setEventId] = useState()
    let [eventDataScraped, setEventDataScraped] = useState({})
    let [eventDataForm, setEventDataForm] = useState([])
    let [eventDataImport, setEventDataImport] = useState([])
    let [eventAction, setEventAction] = useState()

    let [timerModalClose, setTimerModalClose] = useState()


    const modalNewOptions = useDisclosure()
    const modalNewAdd = useDisclosure()
    const modalNewUpdate = useDisclosure()
    const modalNewNop = useDisclosure()
    const modalAddOptions = useDisclosure()
    const drawerAddOptions = useDisclosure()

    const {
        data: events,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetEventsWaitQuery('eventsWaitList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const toastInfo = useToast({
        position: 'bottom-left',
        variant: 'left-accent',
    })

    useEffect(() => {


    }, [])
    /*
    useEffect(() => {
        //            checkCanSave()
        drawerAddOptions.isOpen()

                    if (isSuccess) {
                        toastInfo({ title: 'CITY : Add successfully', status: 'success' })
                        setCountryId('')
                        navigate('/dash/cities')
                    }
                    if (isError) {
                        toastInfo({ title: 'CITY : Add error (' + error?.data?.message + ')', status: 'error' })
                    }
    }, [checkCanSave, isError, error, isSuccess])
*/

    let content

    if (isLoading) {
        content = (
            <>
                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                    <Stack spacing={3}>
                        <PulseLoader color={"#FFF"} />
                    </Stack>
                </Box>
            </>
        )
    }

    let menuContent
    if (isManager || isAdmin) {
        menuContent = (
            <>
                <Button size='sm' onClick={onPageBack} leftIcon={<ArrowBackIcon />} colorScheme='gray' variant='solid' mr='2'>
                    <Text display={{ base: 'none', sm: 'flex' }} fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }} fontWeight={600}>{T.common.Back}</Text>
                </Button>
                <Link to="/dash/events/new">
                    <Button
                        size='sm'
                        leftIcon={<AddIcon />}
                        colorScheme='teal'
                        variant='solid'>
                        <Text
                            color='white'
                            display={{ base: 'none', sm: 'flex' }}
                            fontSize={{ base: "18", sm: "18", md: "18", lg: "18", xl: "20" }}
                            fontWeight={600}
                        >
                            {T.common.Add}
                        </Text>
                    </Button>
                </Link>
            </>
        )
    }

    if (isError) {
        if (isManager || isAdmin) {
            content = (
                <>
                    <Center
                        bg='#45413C'
                        p='0'
                        color='white'
                        axis='both'
                    >
                        <Grid
                            w='95vw'
                            h='100vh'
                            maxW='1500'
                            gap={5}
                            p='5'
                            templateColumns='repeat(12, 1fr)'
                        >
                            <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                                <HStack color='white'>
                                    <Container color='262626' textAlign={['left']} >
                                        <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faLocationCrosshairs} /> {T.events.EventsWait}</Text><br />
                                    </Container>
                                    <Container color='262626' textAlign={['right']}>

                                    </Container>
                                </HStack>
                                <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                                    <Stack spacing={3}>
                                        <Alert status='error'>
                                            <AlertIcon />
                                            {error?.data?.message}
                                        </Alert>
                                    </Stack>
                                </Box>
                            </GridItem>
                        </Grid>
                    </Center>
                </>
            )
        } else {
            content = (
                <>
                    <Stack spacing={3}>
                        <Alert status='error'>
                            <AlertIcon />
                            {error?.data?.message}
                        </Alert>
                    </Stack>
                </>
            )
        }
    }

    if (isSuccess) {
        const { ids, entities } = events

        let filteredIds
        if (isManager || isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(eventId => entities[eventId].username === username)
        }

        const btnOpenNewModal = (id) => {
            setEventId(id)
            setEventDataScraped(entities[id])
            modalNewOptions.onOpen()
        }

        const btnOpenAddModal = (id) => {
            setEventId(id)
            setEventDataScraped(entities[id])
            modalAddOptions.onOpen()
        }
        const btnOpenAddDrawer = (id) => {
            setEventId(id)
            setEventDataScraped(entities[id])
            //            alert('id = ' + id)
            setEventDataForm(eventDataImport[id])

            //            alert('eventDataImport = ' + JSON.stringify(eventDataImport))
            //            alert('eventDataImport[id] = ' + JSON.stringify(eventDataImport[id]))

            if (eventDataImport[id] == undefined) {
                //                alert('undef')
                setEventDataForm([{}]);
            }
            else {
                setEventDataForm(eventDataImport[id]);

            }
            //                (eventDataImport[id] == undefined) ? alert('null') : setEventDataForm(eventDataImport[id])


            //            alert('eventDataForm = ' + JSON.stringify(eventDataForm))
            drawerAddOptions.onOpen()

            setTimeout(function () {
                checkCanSave()
            }, 2000);
            //            drawerAddOptions.isOpen().checkCanSave()
        }


        const onEventAdd = async (e) => {
            e.preventDefault()
            modalNewOptions.onClose()
            modalNewAdd.onOpen()
            setTimerModalClose(5)
            setTimeout(function () {
                setTimerModalClose(4)
            }, 1000);
            setTimeout(function () {
                setTimerModalClose(3)
            }, 2000);
            setTimeout(function () {
                setTimerModalClose(2)
            }, 3000);
            setTimeout(function () {
                setTimerModalClose(1)
            }, 4000);
            setTimeout(function () {
                modalNewAdd.onClose()
            }, 5000);

            /*
                        while (timerModalClose >= 4) {
                            alert(timerModalClose)
                            setTimerModalClose(4);
                        }
                        
                        //            while (timerModalClose > 0) {
            
                        setTimeout(function () {
                            setTimerModalClose(function (timerModalClose) {
                                return (timerModalClose -= 1);
                            });
            
                        }, 1000);
            */
            //            }

            /*
                        let i = 5
                        function doSetTimeout(i) {
                            setTimeout(function () {
                                if (i != 0) {
                                    doSetTimeout()
                                }
                                setTimerModalClose(i)
                            }, 1000);
                            i--;
                        }
                        */
            /*
                        for (var i = 1; i <= 5; ++i) {
                            doSetTimeout(i);
                            setTimerModalClose(i)
                            if (i == 5) {
                                //                    modalNewAdd.onClose()
                            }
                        }
            */

            await updateEventWait({ id: eventDataScraped._id, oraclyState: 'scrap' }).then(() => toastInfo({ title: 'SCRAP : ' + eventDataScraped.eventName, status: 'info' }))
        }

        const onEventUpdate = async (e) => {
            e.preventDefault()
            modalNewOptions.onClose()
            modalNewUpdate.onOpen()
            await updateEventWait({ id: eventDataScraped._id, oraclyState: 'update' }).then(() => toastInfo({ title: 'UPDATE : ' + eventDataScraped.eventName, status: 'info' }))
        }

        const onEventNop = async (e) => {
            e.preventDefault()
            modalNewOptions.onClose()
            // modalNewNop.onOpen()
            await updateEventWait({ id: eventDataScraped._id, oraclyState: 'nop' }).then(() => toastInfo({ title: 'NOP : ' + eventDataScraped.eventName, status: 'error' }))
        }


        const onSaveMarketClicked = async (e) => {
            e.preventDefault()

            if (canSave) {
                alert("Save !")
            }
            /*
            e.preventDefault()
            if (canSave) {
                let output = []
                let marketNameByLang = {}
                eventDataForm.forEach(field => {
                    marketNameByLang[field.lang] = field.marketName
                })
                output['name'] = marketNameByLang
                console.log(output)

                await addNewMarket(output)
            }
            */
        }

        const checkCanSave = () => {
            {
                //                alert('check')
                //                alert('eventDataForm = ' + JSON.stringify(eventDataForm))
                setCanSave(true)

                eventDataForm.map((form) => {
                    //                    alert(form.eventDateStart)
                    if (!isLoading && (form.eventName === '' || form.eventDateStart === '' || form.eventHour === '' || form.eventGenre === '' || form.eventTourID === '' || form.eventUrlPicture === '' || form.eventName == undefined || form.eventDateStart == undefined || form.eventHour == undefined || form.eventGenre == undefined || form.eventTourID == undefined || form.eventUrlPicture == undefined)) {
                        //                    if (!isLoading && (form.eventName === '' || form.eventDateStart === '' || form.eventDateStart == undefined)) {
                        //                    if (!isLoading && (form.eventDateStart == undefined)) {
                        //alert('false')
                        setCanSave(false)
                    }
                })
            }
        }


        const handleFormChange = (event, index, id) => {

            //alert('id =' + id)
            let dataForm = [...eventDataForm];
            dataForm[index][event.target.name] = event.target.value;


            //            setCanSave(true)
            //            if (event.target.value === '' || event.target.value == undefined) {
            //                setCanSave(false)
            //            }

            setEventDataForm(dataForm);

            //            alert('dataForm = ' + JSON.stringify(dataForm))

            // eventDataImport[id]
            //            alert('eventDataImport = ' + JSON.stringify(eventDataImport))
            let dataImport = { ...eventDataImport };
            //            alert('dataImport = ' + JSON.stringify(dataImport))
            dataImport[id] = dataForm;
            setEventDataImport(dataImport);

            //            alert('eventDataImport = ' + JSON.stringify(eventDataImport))
            //            alert('eventDataForm = ' + JSON.stringify(eventDataForm))


            checkCanSave()
        }


        const tableContent = ids?.length && filteredIds.map(eventId => <EventFind key={eventId} eventId={eventId} popupNewModal={btnOpenNewModal} popupAddModal={btnOpenAddModal} popupAddDrawer={btnOpenAddDrawer} />)

        const openInNewTab = (url) => {
            window.open(url, '_blank', 'noopener noreferrer nofollow').opener = null;
            //    https://www.whatismybrowser.com/detect/what-is-my-referrer
        };


        let content_modals = (
            <>
                {/* Modal NEW event */}
                <Modal onClose={modalNewOptions.onClose} size='lg' isOpen={modalNewOptions.isOpen}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <strong>{eventDataScraped.eventName}</strong><br />
                            {eventDataScraped.locationVenueName}<br />
                            {eventDataScraped.eventDateStart}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <HStack>
                                <Box onClick={onEventAdd} cursor={'pointer'} borderRadius='lg' p={2} fontSize={20} color='black' border='5px' textAlign={['center']} borderColor='black' bg='#F5F5F5' w="100%" _hover={{ bg: 'blue.600', color: 'white', borderColor: 'teal.600' }}>
                                    <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}>SCRAP</Text>
                                    <Text fontSize={{ base: "14", sm: "14", md: "14", lg: "14", xl: "18" }} fontWeight={500}>scrap an event</Text>
                                </Box>
                                <Box onClick={onEventUpdate} cursor={'pointer'} borderRadius='lg' p={2} fontSize={20} color='black' border='5px' textAlign={['center']} borderColor='black' bg='#F5F5F5' w="100%" _hover={{ bg: 'purple.600', color: 'white', borderColor: 'teal.600' }}>
                                    <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}>UPDATE</Text>
                                    <Text fontSize={{ base: "14", sm: "14", md: "14", lg: "14", xl: "18" }} fontWeight={500}>update an event</Text>
                                </Box>
                                <Box onClick={onEventNop} cursor={'pointer'} borderRadius='lg' p={2} fontSize={20} color='black' border='5px' textAlign={['center']} borderColor='black' bg='#F5F5F5' w="100%" _hover={{ bg: 'red.600', color: 'white', borderColor: 'teal.600' }}>
                                    <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}>NOP</Text>
                                    <Text fontSize={{ base: "14", sm: "14", md: "14", lg: "14", xl: "18" }} fontWeight={500}>no operation</Text>
                                </Box>
                            </HStack>
                        </ModalBody>
                    </ModalContent>
                </Modal>

                {/* Modal SCRAP event */}
                <Modal onClose={modalNewAdd.onClose} size='lg' isOpen={modalNewAdd.isOpen}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <Badge colorScheme='blue' size='xl'>SCRAP</Badge><br />
                            <strong>{eventDataScraped.eventName}</strong><br />
                            {eventDataScraped.locationVenueName}<br />
                            {eventDataScraped.eventDateStart}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack>
                                <Box cursor={'pointer'} borderRadius='lg' p={5} fontSize={20} color='black' border='5px' textAlign={['left']} borderColor='black' bg='#F5F5F5' w="100%">
                                    <HStack>
                                        <IconButton
                                            isLoading={true}
                                            isRound={true}
                                            variant='solid'
                                            colorScheme='teal'
                                            aria-label='Done'
                                            fontSize='20px'
                                            icon={<CheckIcon />}
                                        />
                                        <div>
                                            <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}>Get event data</Text>
                                            <Text fontSize={{ base: "14", sm: "14", md: "14", lg: "14", xl: "18" }} fontWeight={500}>Is scraping...</Text>
                                        </div>
                                    </HStack>
                                </Box>
                                <Box cursor={'pointer'} borderRadius='lg' p={5} fontSize={20} color='black' border='5px' textAlign={['left']} borderColor='black' bg='#F5F5F5' w="100%">
                                    <HStack>
                                        <IconButton
                                            isLoading={true}
                                            isRound={true}
                                            variant='solid'
                                            colorScheme='teal'
                                            aria-label='Done'
                                            fontSize='20px'
                                            icon={<CheckIcon />}
                                        />
                                        <div>
                                            <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}>Get ticket info</Text>
                                            <Text fontSize={{ base: "14", sm: "14", md: "14", lg: "14", xl: "18" }} fontWeight={500}>Is scraping...</Text>
                                        </div>
                                    </HStack>
                                </Box>
                                <Box cursor={'pointer'} borderRadius='lg' p={5} fontSize={20} color='black' border='5px' textAlign={['left']} borderColor='black' bg='#F5F5F5' w="100%">
                                    <HStack>
                                        <IconButton
                                            isLoading={true}
                                            isRound={true}
                                            variant='solid'
                                            colorScheme='teal'
                                            aria-label='Done'
                                            fontSize='20px'
                                            icon={<CheckIcon />}
                                        />
                                        <div>
                                            <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}>Get seating map</Text>
                                            <Text fontSize={{ base: "14", sm: "14", md: "14", lg: "14", xl: "18" }} fontWeight={500}>Is downloading...</Text>
                                        </div>
                                    </HStack>
                                </Box>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={modalNewAdd.onClose}>Close ({timerModalClose})</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Modal UPDATE event */}
                <Modal onClose={modalNewUpdate.onClose} size='lg' isOpen={modalNewUpdate.isOpen}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <Badge colorScheme='purple' size='xl'>UPDATE</Badge><br />
                            <strong>{eventDataScraped.eventName}</strong><br />
                            {eventDataScraped.locationVenueName}<br />
                            {eventDataScraped.eventDateStart}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormLabel htmlFor='events' mt='5' mb='0'>
                                Select an event
                            </FormLabel>
                            <Select placeholder='large size' size='lg' />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={modalNewUpdate.onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Modal NOP event */}
                <Modal onClose={modalNewNop.onClose} size='lg' isOpen={modalNewNop.isOpen}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            NOP
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>

                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={modalNewNop.onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Modal ADD event */}
                <Modal onClose={modalAddOptions.onClose} size='xl' isOpen={modalAddOptions.isOpen}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <HStack>
                                <Image src={eventDataScraped.eventUrlPicture} h='120' />
                                <div>
                                    <Badge colorScheme='green' size='xl'>ADD</Badge><br />
                                    <strong>{eventDataScraped.eventName}</strong><br />
                                    {eventDataScraped.locationVenueName}<br />
                                    {eventDataScraped.eventDateStart}<br />
                                </div>
                            </HStack>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            ADD
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        )

        let content_dawer = (
            <>
                {/* Drawer ADD event */}
                <Drawer onClose={drawerAddOptions.onClose} size='xl' isOpen={drawerAddOptions.isOpen}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>
                            <HStack>
                                <Container textAlign='left'>
                                    <HStack>
                                        <Image src={eventDataScraped.eventUrlPicture} h='150' />
                                        <div>
                                            <Tag colorScheme='green' size='xl' p='1'> ADD </Tag><br />
                                            <strong>{eventDataScraped.eventName}</strong><br />
                                            {eventDataScraped.locationVenueName}<br />
                                            {eventDataScraped.eventDateStart}<br />
                                            <IconButton
                                                size='xs'
                                                colorScheme='gray'
                                                aria-label='Visit'
                                                icon={<ExternalLinkIcon />}
                                                onClick={() => openInNewTab(`${eventDataScraped.sourceUrl}`)}
                                            />
                                        </div>
                                    </HStack>
                                </Container>
                                <Container textAlign='right'>
                                    <Button
                                        leftIcon={<AddIcon />}
                                        size='lg'
                                        disabled={!canSave}
                                        colorScheme={canSave ? "teal" : "gray"}
                                        onClick={onSaveMarketClicked}
                                    >
                                        ADD
                                    </Button>
                                </Container>
                            </HStack>
                        </DrawerHeader>
                        <DrawerBody>
                            <form onSubmit={onSaveMarketClicked}>
                                <FormControl>

                                    {eventDataForm.map((form, index) => {
                                        return (
                                            <>

                                                <Accordion key={index} defaultIndex={[0]} allowMultiple>

                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} bg='gray.300'>
                                                                <Box as="span" flex='1' textAlign='left'>
                                                                    <HStack spacing={4}>
                                                                        <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faList} /> Event </Text>
                                                                    </HStack>
                                                                </Box>
                                                                <Tag size='md' key='md' variant='solid' mr='2' colorScheme='red'>0</Tag>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>
                                                            <HStack>
                                                                <FormControl>
                                                                    <FormLabel htmlFor='eventName' mt='5' mb='0'>
                                                                        Name
                                                                    </FormLabel>
                                                                    <HStack>
                                                                        <Input
                                                                            isInvalid={!form.eventName ?? checkCanSave()}
                                                                            name='eventName'
                                                                            //placeholder='txt'
                                                                            onChange={event => handleFormChange(event, index, eventDataScraped._id)}
                                                                            value={form.eventName}
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size="lg"
                                                                        />
                                                                        <Input value={eventDataScraped.eventName} size='lg' disabled />
                                                                    </HStack>
                                                                </FormControl>
                                                            </HStack>

                                                            <HStack>
                                                                <FormControl>
                                                                    <FormLabel htmlFor='eventDateStart' mt='5' mb='0'>
                                                                        DateStart
                                                                    </FormLabel>
                                                                    <HStack>
                                                                        <Input
                                                                            isInvalid={!form.eventDateStart ?? checkCanSave()}
                                                                            name='eventDateStart'
                                                                            id='eventDateStart'
                                                                            onChange={event => handleFormChange(event, index, eventDataScraped._id)}
                                                                            value={form.eventDateStart}
                                                                            type="date"
                                                                            min="1997-01-01"
                                                                            max="2030-12-31"
                                                                            focusBorderColor='blue.400'
                                                                            errorBorderColor='red.300'
                                                                            size='lg'
                                                                        />
                                                                        <Input value={eventDataScraped.eventDateStart} size='lg' disabled />
                                                                    </HStack>
                                                                </FormControl>
                                                            </HStack>


                                                            <FormControl>
                                                                <FormLabel htmlFor='eventHour' mt='5' mb='0'>
                                                                    Hour
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Input
                                                                        isInvalid={!form.eventHour ?? checkCanSave()}
                                                                        name='eventHour'
                                                                        onChange={event => handleFormChange(event, index, eventDataScraped._id)}
                                                                        value={form.eventHour}
                                                                        type="time"
                                                                        focusBorderColor='blue.400'
                                                                        errorBorderColor='red.300'
                                                                        size='lg'
                                                                    />
                                                                    <Input value={eventDataScraped.eventHour} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                            <FormControl>
                                                                <FormLabel htmlFor='eventGenreName' mt='5' mb='0'>
                                                                    Genre
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Select
                                                                        isInvalid={!form.eventGenre ?? checkCanSave()}
                                                                        name='eventGenre'
                                                                        onChange={event => handleFormChange(event, index, eventDataScraped._id)}
                                                                        value={form.eventGenre}
                                                                        focusBorderColor='blue.400'
                                                                        errorBorderColor='red.300'
                                                                        size='lg'
                                                                    >
                                                                        <option value=''></option>
                                                                        <option value='option1'>Option 1</option>
                                                                        <option value='option2'>Option 2</option>
                                                                        <option value='option3'>Option 3</option>
                                                                    </Select>
                                                                    <Input value={eventDataScraped.eventGenreName} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                            <FormControl>
                                                                <FormLabel htmlFor='eventGenre' mt='5' mb='0'>
                                                                    Tour
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Input
                                                                        isInvalid={!form.eventTourID ?? checkCanSave()}
                                                                        name='eventTourID'
                                                                        onChange={event => handleFormChange(event, index, eventDataScraped._id)}
                                                                        value={form.eventTourID}
                                                                        focusBorderColor='blue.400'
                                                                        errorBorderColor='red.300'
                                                                        size='lg'
                                                                    />
                                                                    <Input value={eventDataScraped.eventTourID} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>
                                                            <FormControl>
                                                                <FormLabel htmlFor='eventHasMultiDates' mt='5' mb='0'>
                                                                    HasMultiDates
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Flex><Switch size='lg' /></Flex>
                                                                    <Input value={eventDataScraped.eventHasMultiDates} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                            <FormControl>
                                                                <FormLabel htmlFor='eventUrlPicture' mt='5' mb='0'>
                                                                    UrlPicture
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Input
                                                                        isInvalid={!form.eventUrlPicture ?? checkCanSave()}
                                                                        name='eventUrlPicture'
                                                                        onChange={event => handleFormChange(event, index, eventDataScraped._id)}
                                                                        value={form.eventUrlPicture}
                                                                        focusBorderColor='blue.400'
                                                                        errorBorderColor='red.300'
                                                                        size='lg'
                                                                    />
                                                                    <Input value={eventDataScraped.eventUrlPicture} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                        </AccordionPanel>
                                                    </AccordionItem>

                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} bg='gray.300'>
                                                                <Box as="span" flex='1' textAlign='left'>
                                                                    <HStack spacing={4}>
                                                                        <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faList} /> Location </Text>
                                                                    </HStack>
                                                                </Box>
                                                                <Tag size='md' key='md' variant='solid' mr='2' colorScheme='red'>0</Tag>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>

                                                            <FormControl>
                                                                <FormLabel htmlFor='locationVenue' mt='5' mb='0'>
                                                                    Venue
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Select size='lg' placeholder='Select option'>
                                                                        <option value='option1'>Option 1</option>
                                                                        <option value='option2'>Option 2</option>
                                                                        <option value='option3'>Option 3</option>
                                                                    </Select>
                                                                    <Input value={eventDataScraped.locationVenueName} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                            <FormControl>
                                                                <FormLabel htmlFor='locationCity' mt='5' mb='0'>
                                                                    City
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Input size='lg' disabled />
                                                                    <Input value={eventDataScraped.locationCityName} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                            <FormControl>
                                                                <FormLabel htmlFor='locationCountry' mt='5' mb='0'>
                                                                    Country
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Input size='lg' disabled />
                                                                    <Input value={eventDataScraped.locationCountryName} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                            <FormControl>
                                                                <FormLabel htmlFor='locationMarket' mt='5' mb='0'>
                                                                    Market
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Input size='lg' disabled />
                                                                    <Input value={eventDataScraped.locationMarketName} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                        </AccordionPanel>
                                                    </AccordionItem>

                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} bg='gray.300'>
                                                                <Box as="span" flex='1' textAlign='left'>
                                                                    <HStack spacing={4}>
                                                                        <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faList} /> Customer </Text>
                                                                    </HStack>
                                                                </Box>
                                                                <Tag size='md' key='md' variant='solid' mr='2' colorScheme='red'>0</Tag>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>

                                                            <FormControl>
                                                                <FormLabel htmlFor='customerName' mt='5' mb='0'>
                                                                    Name
                                                                </FormLabel>
                                                                <Tag size='lg' colorScheme='gray' borderRadius='full'>
                                                                    <Avatar
                                                                        src='https://bit.ly/sage-adebayo'
                                                                        size='xs'
                                                                        name={eventDataScraped.customerName}
                                                                        ml={-1}
                                                                        mr={2}
                                                                    />
                                                                    <TagLabel>{eventDataScraped.customerName}</TagLabel>
                                                                    <TagCloseButton />
                                                                </Tag>
                                                                <Tag size='lg' colorScheme='gray' borderRadius='full'>
                                                                    <Avatar
                                                                        src='https://bit.ly/sage-adebayo'
                                                                        size='xs'
                                                                        name={eventDataScraped.customerName}
                                                                        ml={-1}
                                                                        mr={2}
                                                                    />
                                                                    <TagLabel>Céline Dion</TagLabel>
                                                                    <TagCloseButton />
                                                                </Tag><br />
                                                                <HStack>
                                                                    <Input size='lg' />
                                                                    <Input value={eventDataScraped.customerName} size='lg' disabled />
                                                                </HStack>
                                                            </FormControl>

                                                        </AccordionPanel>
                                                    </AccordionItem>

                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} bg='gray.300'>
                                                                <Box as="span" flex='1' textAlign='left'>
                                                                    <HStack spacing={4}>
                                                                        <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faList} /> Ticket </Text>
                                                                    </HStack>
                                                                </Box>
                                                                <Tag size='md' key='md' variant='solid' mr='2' colorScheme='red'>0</Tag>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>

                                                            <TableContainer>
                                                                <Table size='sm'>
                                                                    <Thead>
                                                                        <Tr>
                                                                            <Th>Name</Th>
                                                                            <Th>Price</Th>
                                                                            <Th>Device</Th>
                                                                            <Th>Type</Th>
                                                                            <Th>Option</Th>
                                                                            <Th>C.Min</Th>
                                                                            <Th>C.Max</Th>
                                                                            <Th>C.Step</Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody>
                                                                        <Tr>
                                                                            <Td>Catégorie 1</Td>
                                                                            <Td>100,50</Td>
                                                                            <Td>EUR</Td>
                                                                            <Td>seat</Td>
                                                                            <Td>standard</Td>
                                                                            <Td>1</Td>
                                                                            <Td>6</Td>
                                                                            <Td>1</Td>
                                                                        </Tr>
                                                                        <Tr>
                                                                            <Td>Catégorie 2</Td>
                                                                            <Td>91,30</Td>
                                                                            <Td>EUR</Td>
                                                                            <Td>seating</Td>
                                                                            <Td>standard</Td>
                                                                            <Td>1</Td>
                                                                            <Td>6</Td>
                                                                            <Td>1</Td>
                                                                        </Tr>
                                                                        <Tr>
                                                                            <Td>Fosse</Td>
                                                                            <Td>85,20</Td>
                                                                            <Td>EUR</Td>
                                                                            <Td>standing</Td>
                                                                            <Td>standard</Td>
                                                                            <Td>1</Td>
                                                                            <Td>6</Td>
                                                                            <Td>1</Td>
                                                                        </Tr>
                                                                    </Tbody>
                                                                </Table>
                                                            </TableContainer>
                                                            <FormControl>
                                                                <FormLabel htmlFor='ticketName' mt='5' mb='0'>
                                                                    TicketName
                                                                </FormLabel>
                                                                <HStack>
                                                                    <Select size='lg' placeholder='Select option'>
                                                                        <option value='option1'>Option 1</option>
                                                                        <option value='option2'>Option 2</option>
                                                                        <option value='option3'>Option 3</option>
                                                                    </Select>
                                                                    <Input value={eventDataScraped.locationVenueName} size='lg' disabled />
                                                                </HStack>
                                                                <FormLabel htmlFor='ticketName' mt='5' mb='0'>
                                                                    Placement
                                                                </FormLabel>
                                                                <Textarea value={eventDataScraped.ticketPlacement} size='lg' disabled />
                                                                <FormLabel htmlFor='ticketName' mt='5' mb='0'>
                                                                    Attention
                                                                </FormLabel>
                                                                <Textarea value={eventDataScraped.ticketAttention} size='lg' disabled />
                                                            </FormControl>

                                                        </AccordionPanel>
                                                    </AccordionItem>

                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} bg='gray.300'>
                                                                <Box as="span" flex='1' textAlign='left'>
                                                                    <HStack spacing={4}>
                                                                        <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faList} /> Map </Text>
                                                                    </HStack>
                                                                </Box>
                                                                <Tag size='md' key='md' variant='solid' mr='2' colorScheme='red'>0</Tag>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>
                                                            <Image src={eventDataScraped.mapUrlPicture} w='100vw' />
                                                        </AccordionPanel>
                                                    </AccordionItem>


                                                    <AccordionItem>
                                                        <h2>
                                                            <AccordionButton _hover={{ bg: 'teal.600', color: 'white', borderColor: 'teal.600' }} bg='gray.300'>
                                                                <Box as="span" flex='1' textAlign='left'>
                                                                    <HStack spacing={4}>
                                                                        <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faList} /> Source </Text>
                                                                    </HStack>
                                                                </Box>
                                                                <Tag size='md' key='md' variant='solid' mr='2' colorScheme='red'>0</Tag>
                                                                <AccordionIcon />
                                                            </AccordionButton>
                                                        </h2>
                                                        <AccordionPanel pb={4}>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                            veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                                            commodo consequat.
                                                        </AccordionPanel>
                                                    </AccordionItem>

                                                </Accordion>

                                            </>
                                        )
                                    })}

                                </FormControl>
                            </form>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </>
        )

        content = (
            <>
                <Center bg='#45413C' p='0' color='white' axis='both'>
                    <Grid w='95vw' h='100vh' maxW='1500' gap={5} p='5' templateColumns='repeat(12, 1fr)'>
                        <GridItem color='black' colSpan={{ base: "12", sm: "12", md: "12", lg: "12", xl: "12" }}>
                            <HStack color='white'>
                                <Container color='262626' textAlign={['left']} >
                                    <Text fontSize={{ base: "24", sm: "24", md: "24", lg: "24", xl: "28" }} fontWeight={600}><FontAwesomeIcon icon={faLocationCrosshairs} /> {T.events.EventsFinder}</Text><br />
                                </Container>
                                <Container color='262626' textAlign={['right']}>
                                    {menuContent}
                                </Container>
                            </HStack>
                            <Box borderRadius='xl' p={2} fontSize={20} fontWeight={400} color='black' border='10px' textAlign={['center']} borderColor='black' bg='white' w="100%">
                                <TableContainer>
                                    <Table
                                        variant='striped'
                                        size='sm'
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>{T.events.Status}</Th>
                                                <Th></Th>
                                                <Th>{T.events.Event}</Th>
                                                <Th></Th>
                                                <Th isNumeric>{T.events.Edit}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {tableContent}
                                        </Tbody>
                                    </Table>
                                </TableContainer>

                                {content_modals}

                                {content_dawer}

                            </Box>
                        </GridItem>
                    </Grid>
                </Center>
            </>
        )
    }

    return content
}
export default EventsList