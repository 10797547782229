import NewTicketForm from './NewTicketForm'
import useTitle from '../../hooks/useTitle'

const NewTicket = () => {
    useTitle('ORACLY: New Ticket')

    const content = <NewTicketForm />

    return content
}
export default NewTicket