import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const eventsAdapter = createEntityAdapter({
    sortComparer: (a, b) => (a.completed === b.completed) ? 0 : a.completed ? 1 : -1
})

const initialState = eventsAdapter.getInitialState()

export const eventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEventsWait: builder.query({
            query: () => ({
                url: '/events/wait',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedEvents = responseData.map(event => {
                    event.id = event._id
                    return event
                });
                return eventsAdapter.setAll(initialState, loadedEvents)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Event', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Event', id }))
                    ]
                } else return [{ type: 'Event', id: 'LIST' }]
            }
        }),
        updateEventWait: builder.mutation({
            query: initialEvent => ({
                url: '/events/wait',
                method: 'PATCH',
                body: {
                    ...initialEvent,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.id }
            ]
        }),
        getInfoEvents: builder.query({
            query: () => ({
                url: '/events/info',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                }
            })
        }),
        getEvent: builder.query({
            query: (args) => {
                const { id } = args;
                console.log('id2 = ' + id)

                if (id !== undefined) {
                    return {
                        // Returns url with multiple args
                        url: `/events/${id}`
                    }
                }
            },
        }),
        getEvents: builder.query({
            query: (args) => {
                console.log('args = ' + JSON.stringify(args))
                // Destructuring Object
                const { Pagination, Page } = args;
                console.log('Pagination = ' + Pagination)
                console.log('Page = ' + Page)
                //                if (Page && Pagination) {
                if (Page !== undefined && Pagination !== undefined) {
                    return {
                        // Returns url with multiple args
                        url: `/events/${Pagination}/${Page}`
                    }
                }
            },
            transformResponse: responseData => {
                const loadedEvents = responseData.map(event => {
                    event.id = event._id
                    return event
                });
                return eventsAdapter.setAll(initialState, loadedEvents)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Event', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Event', id }))
                    ]
                } else return [{ type: 'Event', id: 'LIST' }]
            }
        }),
        addNewEvent: builder.mutation({
            query: initialEvent => ({
                url: '/events',
                method: 'POST',
                body: {
                    ...initialEvent,
                }
            }),
            invalidatesTags: [
                { type: 'Event', id: "LIST" }
            ]
        }),
        updateEvent: builder.mutation({
            query: initialEvent => ({
                url: '/events',
                method: 'PATCH',
                body: {
                    ...initialEvent,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.id }
            ]
        }),
        deleteEvent: builder.mutation({
            query: ({ id }) => ({
                url: `/events`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Event', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetEventsWaitQuery,
    useUpdateEventWaitMutation,
    useGetEventQuery,
    useGetEventsQuery,
    useGetInfoEventsQuery,
    useAddNewEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
} = eventsApiSlice

// returns the query result object
export const selectEventsResult = eventsApiSlice.endpoints.getEvents.select()

// creates memoized selector
const selectEventsData = createSelector(
    selectEventsResult,
    eventsResult => eventsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllEvents,
    selectById: selectEventById,
    selectIds: selectEventIds
    // Pass in a selector that returns the events slice of state
} = eventsAdapter.getSelectors(state => selectEventsData(state) ?? initialState)